import React, { useContext, useEffect, useState } from 'react';
import { AnnotationClient, AnnotationViewModel, SetListViewModel, SongClient } from '../../types/auto/types';
import { SongIdToPageNumbers } from '../../types/SongIdToPageNumbers';
import PdfDisplayController from '../components/elements/pdf-display/PdfDisplayController';
import { PdfDisplayType } from '../components/elements/pdf-display/transpose/PdfTransposeModal';
import { SetSnackbarErrorContext } from '../components/elements/snackbar/SnackbarContext';
import { Configuration } from '../Constants';
import { FetchOverride } from '../utils/Requests';
import { GetPdfPreferences, PdfPreferences } from '../utils/PDFReadingPreferences';

const PerformSong = (): JSX.Element => {
  // Create the necessary state to display the song pdf
  const [setList, setSetList] = useState<SetListViewModel>();
  const [songIdToPageNumbers, setSongIdToPageNumbers] = useState<SongIdToPageNumbers>({} as SongIdToPageNumbers);
  const [pdfData, setPdfData] = useState<ArrayBuffer>();
  const [annotations, setAnnotations] = useState<AnnotationViewModel[]>([]);
  const [pdfPreferences, setPdfPreferences] = useState<PdfPreferences>();
  const [pdfPreferencesUpdated, setPdfPreferencesUpdated] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const [refreshPdf, setRefreshPdf] = useState(false);

  // Load the pdf data on page load
  useEffect(() => {
    const songId = new URLSearchParams(window.location.search).get('id');
    if (songId === undefined || songId === null || songId === '') {
      window.location.href = '';
      return;
    }

    const songClient = new SongClient(Configuration.SERVER_ROOT, FetchOverride);
    const preferences = GetPdfPreferences();
    setPdfPreferences(preferences);
    setPdfPreferencesUpdated(x => !x);

    songClient
      .getSongPdf(parseInt(songId), preferences.fontSize)
      .then(songPdf => {
        if (songPdf === undefined) {
          return;
        }

        songClient
          .getSongById(parseInt(songId))
          .then(song => {
            new AnnotationClient(Configuration.SERVER_ROOT, FetchOverride)
              .getAnnotationsForSong(parseInt(songId))
              .then(annotations => {
                songPdf.data
                  .arrayBuffer()
                  .then(songPdfData => {
                    // Set the pdf data
                    setPdfData(songPdfData);

                    // Create a fake set list containing only the single song
                    const setList = new SetListViewModel();
                    setList.songModels = [song];
                    setSetList(setList);

                    // Create a fake 100 page mapping of the song
                    const sId: { [name: number]: number[] } = {};
                    sId[parseInt(songId)] = Array.from(Array(100).keys()).map(x => x + 1);
                    setSongIdToPageNumbers(sId);

                    // Set the annotations
                    setAnnotations(annotations);
                  })
                  .catch(() => setSnackbarError('Could not load song pdf. Please refresh the page'));
              })
              .catch(() => setSnackbarError('Could not get annotations for song. Please refresh the page'));
          })
          .catch(() => setSnackbarError('Could not get the song. Please refresh the page'));
      })
      .catch(() => setSnackbarError('Could not get the song pdf. Please refresh the page'));
  }, [setSetList, refreshPdf]);

  return (
    <div style={{ overflowX: 'hidden', overflowY: 'hidden', width: '100%', height: '100%' }}>
      <PdfDisplayController
        setList={setList}
        setSetList={setSetList}
        songIdToPageNumbers={songIdToPageNumbers}
        pdfData={pdfData}
        annotations={annotations}
        setAnnotations={setAnnotations}
        pdfDisplayType={PdfDisplayType.Song}
        isSharedSetListLink={false}
        pdfDisplayPreferences={pdfPreferences}
        pdfPreferencesUpdated={pdfPreferencesUpdated}
        refreshPdf={() => {
          setRefreshPdf(x => !x);
        }}
      />
    </div>
  );
};

export default PerformSong;
