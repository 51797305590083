import React from 'react';
import { Container, Popper, Slider, Stack, Typography } from '@mui/material';
import { zIndex } from '../../../../../Theme';

interface Props {
  open: boolean;
  anchor: React.MutableRefObject<Element | undefined>;
  playbackSpeed: number;
  setPlaybackSpeed: (speed: number) => void;
}

const PlaybackSpeedModal = (props: Props): JSX.Element => (
  <Popper
    open={props.open}
    anchorEl={props.anchor?.current}
    style={{
      zIndex: zIndex.secondModal,
      backgroundColor: 'white',
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      width: '200px',
    }}
    placement={'top-end'}>
    <Container style={{ padding: '10px' }}>
      <Typography>Playback Speed: {props.playbackSpeed}x</Typography>
      <Stack spacing={2} direction='row' sx={{ width: '100%' }} alignItems='center'>
        <Typography aria-label='minPlaybackSpeed'>0.1</Typography>
        <Slider
          aria-label='playbackSpeedSlider'
          value={props.playbackSpeed}
          onChange={(_, val) => {
            props.setPlaybackSpeed(val as number);
          }}
          min={0.1}
          max={2}
          step={0.05}
          style={{ width: '100%' }}
        />
        <Typography aria-label='maxPlaybackSpeed'>2</Typography>
      </Stack>
    </Container>
  </Popper>
);

export default PlaybackSpeedModal;
