import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  SetListClient,
  SetListViewModel,
  SongTransposeInSetListViewModel,
  SongViewModel,
} from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import SetListSongSelect from '../set-list-song-select/SetListSongSelect';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SetSnackbarErrorContext } from '../elements/snackbar/SnackbarContext';
import { zIndex } from '../../Theme';

interface Props {
  open: boolean;
  onClose: () => void;
  setListToEdit?: SetListViewModel;
}

const SetListModal = (props: Props): JSX.Element => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [selectedSongs, setSelectedSongs] = useState<SongViewModel[]>([]);
  const [songError, setSongError] = useState(false);
  const [loading, setLoading] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  // Set the default values
  useEffect(() => {
    if (!props.open || props.setListToEdit === undefined) {
      setName('');
      setSelectedSongs([]);
      setNameError(false);
      setSongError(false);
    } else if (props.setListToEdit) {
      setName(props.setListToEdit.name || '');
      const songModels = props.setListToEdit.songModels || [];
      setSelectedSongs(songModels);
      setNameError(false);
      setSongError(false);
    }
  }, [props, setName, setSelectedSongs]);

  return (
    <Dialog open={props.open} fullWidth maxWidth='xl' style={{ zIndex: zIndex.secondModal }}>
      <DialogContent>
        <Typography variant='h4'>{props.setListToEdit ? 'Edit' : 'Create'} Set List</Typography>
        <TextField
          margin='dense'
          error={nameError}
          helperText={nameError ? 'Please add a set list name' : undefined}
          id='name'
          label='Set List Name'
          type='text'
          fullWidth
          variant='standard'
          value={name}
          onChange={event => setName(event.target.value)}
          onBlur={event => setNameError(event.target.value === '')}
        />
        <div style={{ marginTop: 15 }}>
          <SetListSongSelect
            selectedSongs={selectedSongs}
            setSelectedSongs={setSelectedSongs}
            songError={songError}
            setSongError={setSongError}
            maxHeight='500px'
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={props.onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            // Perform validation
            const isNameError = name === '';
            const isSongError = selectedSongs.length === 0;
            if (isNameError || isSongError) {
              setNameError(isNameError);
              setSongError(isSongError);
              return;
            }

            setLoading(true);
            if (props.setListToEdit) {
              const updatedSetList = props.setListToEdit;
              updatedSetList.name = name;
              updatedSetList.songModels = selectedSongs;
              new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                .updateSetList(
                  updatedSetList,
                  updatedSetList.songModels.map(
                    x =>
                      ({ songId: x.id, transposeAmount: x.storedTransposition || 0 } as SongTransposeInSetListViewModel)
                  )
                )
                .then(() => {
                  setLoading(false);
                  props.onClose();
                })
                .catch(() => {
                  setSnackbarError('Could not update set list. Please try again later');
                  setLoading(false);
                });
            } else {
              new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                .create(
                  {
                    name: name,
                    songModels: selectedSongs,
                  } as SetListViewModel,
                  selectedSongs.map(
                    x =>
                      ({ songId: x.id, transposeAmount: x.storedTransposition || 0 } as SongTransposeInSetListViewModel)
                  )
                )
                .then(() => {
                  setLoading(false);
                  props.onClose();
                })
                .catch(() => {
                  setSnackbarError('Could not create set list. Please try again later');
                  setLoading(false);
                });
            }
          }}>
          {props.setListToEdit ? 'Update Set List' : 'Create Set List'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default SetListModal;
