import { isMobile } from 'react-device-detect';

export const GetAdjustableMobileEditButtonBottom = (type: 'video' | 'transpose' | 'capo' | 'annotate'): number => {
  // Everything should be flat along the bottom on mobile view
  if (!isMobile) {
    return 10;
  }

  const initialBottomPadding = 10;
  const paddingGap = 40;

  switch (type) {
    case 'annotate':
      return initialBottomPadding;
    case 'transpose':
      return initialBottomPadding + paddingGap;
    case 'capo':
      return initialBottomPadding + paddingGap * 2;
    case 'video':
      return initialBottomPadding + paddingGap * 3;
    // If anything else is added, hide it
    default:
      return -100;
  }
};
export const GetAdjustableMobileEditButtonRight = (type: 'video' | 'transpose' | 'capo' | 'annotate'): number => {
  // Everything should be flat along the side on mobile view
  if (isMobile) {
    return 10;
  }

  const initialRightPadding = 20;
  const paddingGap = 40;

  switch (type) {
    case 'annotate':
      return initialRightPadding;
    case 'transpose':
      return initialRightPadding + paddingGap;
    case 'capo':
      return initialRightPadding + paddingGap * 2;
    case 'video':
      return initialRightPadding + paddingGap * 3;
    // If anything else is added, hide it
    default:
      return -100;
  }
};
