import React from 'react';
import {
  Box,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { Identifier, XYCoord } from 'dnd-core';
import { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { SongViewModel } from '../../../../types/auto/types';
import { Reorder, Delete, Edit } from '@mui/icons-material';
import { colours, zIndex } from '../../../Theme';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { isMobile } from 'react-device-detect';
import {
  GetKeyForTransposition,
  GetTranspositionForKeyDifference,
  GetValidKeysForTransposition,
} from '../../../utils/KeyChart';

export interface CardProps {
  id: number;
  song: SongViewModel;
  index: number;
  totalIndex: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  deleteCard: () => void;
  editCard?: (song: SongViewModel) => void;
  jumpToCard?: (song: SongViewModel) => void;
  isSetListView: boolean;
  forceMobileView?: boolean;
  viewOnly?: boolean;
  setSongTransposition: (song: SongViewModel, newTransposition: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DraggableItem: FC<CardProps> = ({
  id,
  song,
  index,
  totalIndex,
  moveCard,
  deleteCard,
  editCard,
  jumpToCard,
  isSetListView,
  forceMobileView,
  viewOnly,
  setSongTransposition,
}) => {
  const isMobileView = isMobile || forceMobileView || false;

  const ref = useRef<HTMLDivElement>(null);
  const [{}, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'draggableitem',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'draggableitem',
    item: () => ({ id, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (viewOnly !== true) {
    drag(drop(ref));
  }
  return (
    <div ref={ref}>
      <ListItem
        style={{
          background: isDragging ? 'rgb(235,235,235)' : index % 2 === 0 ? 'rgb(250,250,250)' : 'rgb(255,255,255)',
          cursor: viewOnly ? undefined : 'move',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px',
        }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0 }}>
          {!isMobileView && viewOnly !== true && (
            <ListItemAvatar style={{ marginTop: 5, color: colours.textGray, marginRight: 0 }}>
              <Reorder />
            </ListItemAvatar>
          )}

          {viewOnly !== true && !isMobile && (
            <>
              {song.text ? (
                <Tooltip title='Song Has Uploaded Chords'>
                  <TextFieldsIcon
                    sx={{ color: colours.textGray, fontSize: 20, marginRight: '5px', display: 'inline-block' }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Song Has Uploaded PDF'>
                  <PictureAsPdfIcon
                    sx={{ color: colours.textGray, fontSize: 20, marginRight: '5px', display: 'inline-block' }}
                  />
                </Tooltip>
              )}
            </>
          )}

          <ListItemText
            sx={{
              float: 'left',
              display: 'flex',
              flexDirection: 'row',
              flexFlow: 'row wrap',
              overflowWrap: 'anywhere',
            }}
            primary={song.name + (song.artist ? ' - ' + song.artist : '')}
            secondary=''
          />
        </div>
        <Box style={{ display: 'flex', flexDirection: 'row', justifySelf: 'flex-end' }}>
          {isMobileView && viewOnly !== true && (
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton onClick={() => moveCard(index, index - 1)} disabled={index === 0}>
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton onClick={() => moveCard(index, index + 1)} disabled={index === totalIndex - 1}>
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          )}
          {editCard && (
            <>
              <div style={{ width: '50px' }}>
                <Tooltip title={song.key ? 'Select the key to play the song in' : 'Song does not have a key'}>
                  <span>
                    <InputLabel id='key-label'>Key</InputLabel>
                    <Select
                      disabled={song.key === null}
                      labelId='key-label'
                      label='Key'
                      value={song.key ? GetKeyForTransposition(song.key, song.storedTransposition || 0) : ''}
                      style={{ width: '100%' }}
                      variant='standard'
                      MenuProps={{ style: { zIndex: zIndex.topMost } }}
                      onChange={event =>
                        setSongTransposition(
                          song,
                          GetTranspositionForKeyDifference(song.key || 'G', event.target.value)
                        )
                      }>
                      <MenuItem key='empty' value=''>
                        Please Select a Key
                      </MenuItem>
                      {GetValidKeysForTransposition(song.key || 'G').map(x => (
                        <MenuItem key={x} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </Select>
                  </span>
                </Tooltip>
              </div>
              <IconButton
                onClick={() => editCard(song)}
                sx={{ marginRight: isSetListView || isMobileView ? 0 : 5, marginTop: 0, justifySelf: 'flex-end' }}>
                <Edit sx={{ fontSize: 25 }} />
              </IconButton>
            </>
          )}
          {jumpToCard && (
            <IconButton
              onClick={() => jumpToCard(song)}
              sx={{ marginRight: isSetListView || isMobileView ? 0 : 5, marginTop: 0, justifySelf: 'flex-end' }}>
              <VisibilityIcon sx={{ fontSize: 25 }} />
            </IconButton>
          )}
          {viewOnly !== true && (
            <IconButton
              onClick={() => deleteCard()}
              sx={{ marginRight: isSetListView || isMobileView ? 0 : 5, marginTop: 0, justifySelf: 'flex-end' }}>
              <Delete sx={{ fontSize: 25 }} />
            </IconButton>
          )}
        </Box>
      </ListItem>
    </div>
  );
};

export default DraggableItem;
