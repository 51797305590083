import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { AnnotationSettings } from '../../../../types/AnnotationSettings';
import { TextAnnotationViewModel } from '../../../../types/auto/types';
import AnnotationCanvas from '../annotation-canvas/AnnotationCanvas';
import PdfChordText from './PdfChordText';
import PdfDisplayPagePreRenderedPage from './PdfDisplayPagePreRenderedPage';
import { EveryChordRegex } from './transpose/PdfTransposableLayer';
import { isMobile } from 'react-device-detect';

export interface PdfDisplayPageProps {
  currentPageNumber: number;
  maximise: boolean;
  rerender?: boolean;
  annotationSettings?: AnnotationSettings;
  annotations?: string;
  textAnnotations?: TextAnnotationViewModel[];
  showTranspositions: boolean;
  showAnnotations: boolean;
  songId: number;
  pageNumber: number;
  songPageNumber: number;
  setAnnotationsForSongPageNumber: (
    annotations: string,
    textAnnotations: TextAnnotationViewModel[],
    songId: number,
    pageNumber: number
  ) => void;
  transposeAmount: number;
  showTwoPages?: boolean;
  zoomLevel: number;
  maxPageNumber: number;
}

const defaultHeight = 1000;

const PdfDisplayPage = (props: PdfDisplayPageProps): JSX.Element => {
  // Setup the pdf worker
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const [pageHeight, setPageHeight] = useState<number | undefined>(defaultHeight);
  const [pageWidth, setPageWidth] = useState<number | undefined>(undefined);
  const [individualPageWidth, setIndividualPageWidth] = useState<number>();
  const [individualPageHeight, setIndividualPageHeight] = useState<number>();
  const [, setRerenderTransposable] = useState(false);

  const calculatePageDimensions = (pageWidth: number, pageHeight: number, isMaximised: boolean): void => {
    const heightDifference = pageHeight === undefined ? 0 : pageHeight - window.innerHeight;
    const widthDifference = pageWidth === undefined ? 0 : pageWidth - window.innerWidth;

    if (heightDifference > widthDifference && heightDifference >= 0) {
      setPageHeight((window.innerHeight / 100) * (isMaximised ? 80 : 85));
      setPageWidth(undefined);
    } else if (widthDifference > heightDifference && widthDifference >= 0) {
      setPageHeight(undefined);
      setPageWidth(
        (window.innerWidth / 100) * (props.showTwoPages === true ? (isMaximised ? 40 : 45) : isMaximised ? 80 : 85)
      );
    }
  };

  // Scale the size of the pdf to the window (including full screen options)
  useEffect(() => {
    if (document && !isMobile) {
      if (props.maximise) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      }
    }
  }, [props.maximise]);

  return (
    <div
      className='pageContainer'
      style={{
        display: 'inline-block',
        height: '100vh',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        userSelect: 'none',
      }}>
      <PdfDisplayPagePreRenderedPage
        pageNumber={props.currentPageNumber}
        maxPageNumber={props.maxPageNumber}
        loading={<></>}
        height={pageHeight && pageHeight * props.zoomLevel}
        width={pageWidth && pageWidth * props.zoomLevel}
        onLoadSuccess={page => {
          setIndividualPageHeight(page.height);
          setIndividualPageWidth(page.width);
          calculatePageDimensions(page.width, page.height, props.maximise);
          setRerenderTransposable(x => !x);
        }}
        customTextRenderer={layer => {
          const chordMatches = layer.str.replace(/\[|\]|\,/g, '').match(EveryChordRegex);

          // If we are transposing, there is a chord match, and there are as many chord matches as there are words (see #79)
          return props.showTranspositions &&
            props.transposeAmount !== 0 &&
            chordMatches !== null &&
            layer.str.split(/\s+/).filter(x => x !== '').length === chordMatches?.length ? (
            <PdfChordText layer={layer} transposeAmount={props.transposeAmount} />
          ) : (
            <>{layer.str}</>
          );
        }}
        onRenderSuccess={page => {
          setIndividualPageHeight(page.height);
          setIndividualPageWidth(page.width);
          setRerenderTransposable(x => !x);
        }}
        onGetTextSuccess={() => setRerenderTransposable(x => !x)}
        onGetAnnotationsSuccess={() => setRerenderTransposable(x => !x)}
        renderMode={props.rerender ? 'canvas' : undefined} // Force the page to be reloaded on a useless prop to update the canvas size
      />
      <AnnotationCanvas
        showAnnotations={props.showAnnotations}
        canvasHeight={individualPageHeight || 0}
        canvasWidth={individualPageWidth || 0}
        annotationSettings={props.annotationSettings}
        annotations={props.annotations}
        textAnnotations={props.textAnnotations}
        songId={props.songId}
        songPageNumber={props.songPageNumber}
        setAnnotationsForSongPageNumber={props.setAnnotationsForSongPageNumber}
        zoomLevel={props.zoomLevel}
      />
    </div>
  );
};

export default PdfDisplayPage;
