import React, { useRef } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PdfTransposeModal, { PdfDisplayType } from './PdfTransposeModal';
import { SetListViewModel, SongViewModel } from '../../../../../types/auto/types';
import {
  GetAdjustableMobileEditButtonBottom,
  GetAdjustableMobileEditButtonRight,
} from '../../../../utils/MobileViewChanges';
import { CalculateTransposition } from '../../../../utils/CalculateTransposition';
import { colours, zIndex } from '../../../../Theme';

interface Props {
  transposeAmount: number;
  setTransposeAmount: (transposition: number) => void;
  setList?: SetListViewModel;
  song?: SongViewModel;
  pdfDisplayType: PdfDisplayType;
  open: boolean;
  setOpen: (o: boolean) => void;
  isCapoRemoved: boolean;
  masterTranspose: number;
}

const PdfTransposeButton = (props: Props): JSX.Element => {
  const buttonRef = useRef<Element>();

  const transposeAmount = CalculateTransposition(props.song, props.isCapoRemoved, props.masterTranspose);

  return (
    <>
      <PdfTransposeModal
        open={props.open}
        setOpen={props.setOpen}
        transposeAmount={props.transposeAmount}
        setTransposeAmount={props.setTransposeAmount}
        setList={props.setList}
        song={props.song}
        pdfDisplayType={props.pdfDisplayType}
        anchor={buttonRef}
      />
      <Tooltip
        title='Transpose'
        style={{
          zIndex: zIndex.tooltip,
        }}
        ref={buttonRef}>
        <IconButton
          aria-label='Transpose'
          onClick={() => props.setOpen(!props.open)}
          style={{
            zIndex: zIndex.pdfIconOverlay,
            position: 'absolute',
            bottom: GetAdjustableMobileEditButtonBottom('transpose'),
            right: GetAdjustableMobileEditButtonRight('transpose'),
            backgroundColor: colours.backgroundWhite,
          }}>
          <ImportExportIcon style={{ transform: 'scaleX(-1)' }} />
          <Typography style={{ position: 'absolute', marginTop: 10, marginLeft: 18, fontSize: 10 }}>
            {transposeAmount > 0 ? '+' + transposeAmount : transposeAmount}
          </Typography>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PdfTransposeButton;
