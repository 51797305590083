import React, { useRef, useState } from 'react';
import { Pause, PlayArrow, Error, SlowMotionVideo, KeyboardArrowUp } from '@mui/icons-material';
import { CircularProgress, Container, IconButton, Tooltip } from '@mui/material';
import YouTube, { YouTubeProps, YouTubePlayer as Player } from 'react-youtube';
import YouTubeSlider from './YouTubeSlider';
import PlaybackSpeedModal from './PlaybackSpeedModal';
import { isMobile } from 'react-device-detect';
import { zIndex } from '../../../../../Theme';

interface Props {
  url: string;
  open: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

const youtubeIdMatchRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
const GetIdFromURL = (url: string): string => {
  const match = url.match(youtubeIdMatchRegex);
  return match && match.length > 1 ? match[1] : '';
};

const YouTubePlayer = (props: Props): JSX.Element => {
  const [target, setTarget] = useState<Player>();
  const [showVideo, setShowVideo] = useState(isMobile); // Show only the youtube player on mobile
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [playbackRateModalOpen, setPlaybackRateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [playingError, setPlayingError] = useState<string>();

  const onPlayerReady: YouTubeProps['onReady'] = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
    setTarget(event.target);
    setLoading(false);
  };

  const onStateChange: YouTubeProps['onStateChange'] = event => {
    setIsPlaying(event.data === 1); // State of 1 = playing - https://developers.google.com/youtube/iframe_api_reference#Events
    props.setIsPlaying(event.data === 1);
    setLoading(event.data === 3); // If buffering display loading
  };

  const onError: YouTubeProps['onError'] = () => {
    setIsError(true);
    setPlayingError('Audio Could Not Be Loaded. Please try again later');
    setLoading(false);
  };
  const buttonRef = useRef<Element>();

  return (
    <>
      <YouTube
        videoId={GetIdFromURL(props.url)}
        opts={{
          width: '350',
          height: '196',
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            fs: 0, // Disable fullscreen
            disablekb: 1, // Disable keyboard controls
            controls: isMobile ? 1 : 0, // Don't show controls
            modestbranding: 1, // Hide the YouTube logo
          },
        }}
        onReady={onPlayerReady}
        onStateChange={onStateChange}
        onError={onError}
        onPlaybackRateChange={e => setPlaybackRate(e.data)}
        style={{ display: showVideo ? undefined : 'none', zIndex: zIndex.firstModal }}
      />
      {!isMobile && (
        <>
          <Container style={{ display: 'flex', flexDirection: 'row', width: '100%', margin: '3px', padding: 0 }}>
            <Tooltip
              title={playingError ? playingError : isPlaying ? 'Pause' : 'Play'}
              placement='top'
              style={{ zIndex: zIndex.tooltip }}>
              <IconButton
                aria-label='Play / Pause'
                onClick={() => (isPlaying ? target.pauseVideo() : target.playVideo())}>
                {isPlaying ? <Pause /> : loading ? <CircularProgress size={25} /> : isError ? <Error /> : <PlayArrow />}
              </IconButton>
            </Tooltip>
            <YouTubeSlider target={target} />
            <Tooltip title='Playback Speed' placement='top' style={{ zIndex: zIndex.tooltip }} ref={buttonRef}>
              <IconButton aria-label='SlowMo' onClick={() => setPlaybackRateModalOpen(x => !x)}>
                <SlowMotionVideo />
              </IconButton>
            </Tooltip>
            <IconButton aria-label='Show / Hide Video' onClick={() => setShowVideo(x => !x)}>
              <Tooltip
                title={showVideo ? 'Hide Video' : 'Show Video'}
                placement='top'
                style={{ zIndex: zIndex.tooltip }}>
                <KeyboardArrowUp
                  style={{ transform: showVideo ? 'rotate(180deg)' : undefined, transition: 'transform .5s' }}
                />
              </Tooltip>
            </IconButton>
          </Container>

          <PlaybackSpeedModal
            open={playbackRateModalOpen && props.open}
            anchor={buttonRef}
            playbackSpeed={playbackRate}
            setPlaybackSpeed={value => {
              setPlaybackRate(value);
              target?.setPlaybackRate(playbackRate);
            }}
          />
        </>
      )}
    </>
  );
};

export default YouTubePlayer;
