import React, { useEffect, useState } from 'react';
import { Slider, Stack, Typography } from '@mui/material';
import { YouTubePlayer as Player } from 'react-youtube';

interface Props {
  target: Player | undefined;
}

const ToFormattedTime = (secs: number): string => {
  secs = Math.round(secs);
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs - hours * 3600) / 60);
  const seconds = secs - hours * 3600 - minutes * 60;

  return (
    (hours !== 0 ? (hours < 10 ? '0' : '') + hours + ':' : '') +
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  );
};

const YouTubeSlider = (props: Props): JSX.Element => {
  const [currentTime, setCurrentTime] = useState(0);
  const [editingTime, setEditingTime] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => !editingTime && setCurrentTime(props.target?.getCurrentTime() || 0), 1000);

    return () => clearInterval(intervalId);
  }, [props.target, editingTime]);

  return (
    <Stack spacing={2} direction='row' sx={{ width: '100%' }} alignItems='center'>
      <Typography aria-label='currentTime'>{ToFormattedTime(currentTime || 0)}</Typography>
      <Slider
        aria-label='time'
        value={currentTime}
        onChange={(_, val) => {
          setEditingTime(true);
          setCurrentTime(val as number);
        }}
        onChangeCommitted={(_, val) => {
          props.target?.seekTo(currentTime);
          setCurrentTime(val as number);
        }}
        min={0}
        max={props.target?.getDuration()}
        style={{ width: '100%' }}
      />
      <Typography aria-label='totalTime'>{ToFormattedTime(props.target?.getDuration() || 0)}</Typography>
    </Stack>
  );
};

export default YouTubeSlider;
