import React, { useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';
import {
  SetSnackbarErrorContext,
  SetSnackbarSuccessContext,
  SnackbarErrorContext,
  SnackbarSuccessContext,
} from './SnackbarContext';

const CustomSnackbar = (): JSX.Element => {
  const snackbarError = useContext(SnackbarErrorContext);
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const snackbarSuccess = useContext(SnackbarSuccessContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  return (
    <Snackbar
      open={snackbarError !== undefined || snackbarSuccess !== undefined}
      autoHideDuration={6000}
      onClose={() => {
        setSnackbarError(undefined);
        setSnackbarSuccess(undefined);
      }}>
      <Alert severity={snackbarSuccess === undefined ? 'error' : 'success'}>
        {snackbarSuccess === undefined ? snackbarError : snackbarSuccess}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
