export const TermsOfUse = `<div class="document-previewer-wrapper-a717db">
<style>
    [data-custom-class='body'],
    [data-custom-class='body'] * {
        background: transparent !important;
    }

    [data-custom-class='title'],
    [data-custom-class='title'] * {
        font-family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
    }

    [data-custom-class='subtitle'],
    [data-custom-class='subtitle'] * {
        font-family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
    }

    [data-custom-class='heading_1'],
    [data-custom-class='heading_1'] * {
        font-family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
    }

    [data-custom-class='heading_2'],
    [data-custom-class='heading_2'] * {
        font-family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
    }

    [data-custom-class='body_text'],
    [data-custom-class='body_text'] * {
        color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
    }

    [data-custom-class='link'],
    [data-custom-class='link'] * {
        color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
    }
</style>
<div data-custom-class="body">
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div align="center" class="MsoNormal" data-custom-class="title" style="text-align: left; line-height: 1.5;">
            <a name="_gm5sejt4p02f"></a><strong><span
                    style="line-height: 150%; font-family: Arial; font-size: 26px;">
                    <bdt class="block-component"></bdt>TERMS OF USE<bdt class="else-block"></bdt>
                </span></strong>
        </div>
        <div align="center" class="MsoNormal" style="text-align: center; line-height: 1.5;"><a
                name="_7m5b3xg56u7y"></a></div>
        <div align="center" class="MsoNormal" data-custom-class="subtitle"
            style="text-align: left; line-height: 1.5;"><br></div>
        <div align="center" class="MsoNormal" data-custom-class="subtitle"
            style="text-align: left; line-height: 1.5;"><span style="font-size:11.0pt;line-height:150%;font-family:Arial;color:#A6A6A6;
mso-themecolor:background1;mso-themeshade:166;"><span
                    style="color: rgb(127, 127, 127); font-size: 15px; text-align: justify;"><strong>Last
                        updated</strong></span><strong><span
                        style="color: rgb(127,127,127); font-size: 14.6667px; text-align: justify;">&nbsp;</span></strong><span
                    style="color: rgb(127, 127, 127); font-size: 15px; text-align: justify;">
                    <bdt class="block-container question question-in-editor"
                        data-id="0d5ae8ae-7749-9afb-1fed-6556cb563dc0" data-type="question">
                        <strong>21st September 2022</strong>
                    </bdt>
                </span></span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><strong><span
                data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link"
            href="#agreement"><span style="font-size: 15px;"><span data-custom-class="body_text">1. AGREEMENT TO
                    TERMS</span></span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#ip"><span
                data-custom-class="body_text">2. INTELLECTUAL PROPERTY RIGHTS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link"
            href="#userreps"><span data-custom-class="body_text">3. USER REPRESENTATIONS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link"
            href="#payment"><span data-custom-class="body_text">4. FEES AND PAYMENT</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#cancel"><span
                data-custom-class="body_text">5. CANCELLATION</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link"
            href="#prohibited"><span data-custom-class="body_text">6. PROHIBITED ACTIVITIES</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#ugc"><span
                data-custom-class="body_text">7. USER GENERATED CONTRIBUTIONS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link"
            href="#license"><span data-custom-class="body_text">8. CONTRIBUTION LICENSE</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#submissions"><span
                data-custom-class="body_text">9. SUBMISSIONS</span></a></div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#sitemanage"><span
                data-custom-class="body_text">10. SITE MANAGEMENT</span></a></div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#terms"><span data-custom-class="body_text">11.
                TERM AND TERMINATION</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#modifications"><span
                data-custom-class="body_text">12. MODIFICATIONS AND INTERRUPTIONS</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#law"><span data-custom-class="body_text">13.
                GOVERNING LAW</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#disputes"><span
                data-custom-class="body_text">14. DISPUTE RESOLUTION</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#corrections"><span
                data-custom-class="body_text">15. CORRECTIONS</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#disclaimer"><span
                data-custom-class="body_text">16. DISCLAIMER</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#liability"><span
                data-custom-class="body_text">17. LIMITATIONS OF LIABILITY</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#indemnification"><span
                data-custom-class="body_text">18. INDEMNIFICATION</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#userdata"><span
                data-custom-class="body_text">19. USER DATA</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#electronic"><span
                data-custom-class="body_text">20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                SIGNATURES</span></a>
    </div>
    <div style="line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#misc"><span data-custom-class="body_text">21.
                MISCELLANEOUS</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span
                data-custom-class="body_text">22. CONTACT US</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="heading_1" id="agreement" style="line-height: 1.5;"><a
                name="_6aa3gkhykvst"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">1. AGREEMENT TO
                    TERMS</span></strong></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">These
                <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt> constitute a
                legally
                binding agreement made between you, whether personally or on behalf of an entity (“you”) and
                <bdt class="block-container question question-in-editor"
                    data-id="9d459c4e-c548-e5cb-7729-a118548965d2" data-type="question">Sheet Music</bdt>
            </span><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <bdt class="block-component"></bdt>&nbsp;
            </span><span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">("
                <bdt class="block-component"></bdt><strong>Company</strong>
                <bdt class="statement-end-if-in-editor"></bdt>", “<strong>we</strong>”, “<strong>us</strong>”,
                or
                “<strong>our</strong>”), concerning your access to and use of the <bdt
                    class="block-container question question-in-editor"
                    data-id="fdf30452-99b8-c68b-5cdf-34af764cd1fd" data-type="question">Sheet Music</bdt> website
                as
                well as any other media form, media channel, mobile website or mobile application related,
                linked,
                or otherwise connected thereto (collectively, the “Site”).<span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                            <bdt class="question">
                                <bdt class="block-component"></bdt>
                            </bdt>
                        </span>
                        <bdt class="block-component"></bdt>
                    </span></span>
            </span> You agree that by accessing the Site, you have read, understood, and agree to be bound by
            all of
            these <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt>
            <bdt class="block-container if" data-type="if" id="c4a4c609-d962-97d8-9b96-d3492402adad">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="separate_agreement_option"
                        data-type="statement"></bdt>
                </bdt>. IF YOU DO NOT AGREE WITH ALL OF THESE <bdt class="block-component"></bdt>TERMS OF USE
                <bdt class="else-block"></bdt>, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU
                MUST
                DISCONTINUE USE IMMEDIATELY.
            </bdt>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Supplemental terms
                    and
                    conditions or documents that may be posted on the Site from time to time are hereby
                    expressly
                    incorporated herein by reference. We reserve the right, in our sole discretion, to make
                    changes
                    or modifications to these <bdt class="block-component"></bdt>Terms of Use<bdt
                        class="else-block"></bdt>
                    <bdt class="block-component"></bdt>at any time and for any reason<bdt
                        class="statement-end-if-in-editor"></bdt>. We will alert you about any changes by
                    updating
                    the “Last updated” date of these <bdt class="block-component"></bdt>Terms of Use<bdt
                        class="else-block"></bdt>, and you waive any right to receive specific notice of each
                    such
                    change. Please ensure that you check the applicable Terms every time you use our Site so
                    that
                    you understand which Terms apply. You will be subject to, and will be deemed to have been
                    made
                    aware of and to have accepted, the changes in any revised <bdt class="block-component">
                    </bdt>
                    Terms of Use<bdt class="else-block"></bdt> by your continued use of the Site after the date
                    such
                    revised <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt> are
                    posted.
                </span></span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">The
                information provided on the Site is not intended for distribution to or use by any person or
                entity
                in any jurisdiction or country where such distribution or use would be contrary to law or
                regulation
                or which would subject us to any registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Site from other locations do so on their own
                initiative and are solely responsible for compliance with local laws, if and to the extent local
                laws are applicable.</span></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <bdt class="block-component"></bdt>
            </span></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <bdt class="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
                <bdt data-type="conditional-block"><span style="font-size: 15px;">
                        <bdt class="block-component" data-record-question-key="user_o18_option"
                            data-type="statement"></bdt>
                    </span></bdt>
            </bdt>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        <div class="MsoNormal" data-custom-class="heading_1" id="ip" style="line-height: 1.5;"><a
                name="_b6y29mp52qvx"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">2. INTELLECTUAL PROPERTY
                    RIGHTS</span></strong></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Unless
                otherwise indicated, the Site is our proprietary property and all source code, databases,
                functionality, software, website designs, audio, video, text, photographs, and graphics on the
                Site
                (collectively, the “Content”) and the trademarks, service marks, and logos contained therein
                (the
                “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and
                trademark laws and various other intellectual property rights and unfair competition laws of the
                United States, international copyright laws, and international conventions. The Content and the
                Marks are provided on the Site “AS IS” for your information and personal use only. Except as
                expressly provided in these <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block">
                </bdt>, no part of the Site and no Content or Marks may be copied, reproduced,
                aggregated, republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, distributed, sold, licensed, or otherwise exploited
                for any commercial purpose whatsoever, without our express prior written
                permission.</span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Provided
                that you
                are eligible to use the Site, you are granted a limited license to access and
                use the Site and to download or print a copy of any portion of the Content to
                which you have properly gained access solely for your personal, non-commercial
                use. We reserve all rights not expressly granted to you in and to the Site, the Content and the
                Marks.</span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        <div class="MsoNormal" data-custom-class="heading_1" id="userreps" style="line-height: 1.5;"><a
                name="_5hg7kgyv9l8z"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">3. USER
                    REPRESENTATIONS</span></strong></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">By using
                the
                Site, you represent and warrant that:</span>
            <bdt class="block-container if" data-type="if" id="d2d82ca8-275f-3f86-8149-8a5ef8054af6">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="user_account_option"
                        data-type="statement"><span style="font-size: 15px;"></span></bdt>&nbsp;
                </bdt><span style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span
                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">1</span></span><span
                    style="color: rgb(89, 89, 89); font-size: 15px;">) you have the legal capacity and you agree
                    to
                    comply with these <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block">
                    </bdt>;
                    <bdt class="block-component"></bdt>
                </span>
            </bdt><span style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">2</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) you are not a
                minor in the jurisdiction in which you reside<bdt class="block-container if" data-type="if"
                    id="76948fab-ec9e-266a-bb91-948929c050c9">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_o18_option"
                            data-type="statement"></bdt>
                    </bdt>;
                </bdt></span><span style="color: rgb(89, 89, 89); font-size: 11pt;">&nbsp;</span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);">3</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) you will not access the Site
                through automated or non-human means, whether through a bot, script or
                otherwise;</span><span style="color: rgb(89, 89, 89); font-size: 11pt;">&nbsp;</span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);">4</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) you will not use the Site for any illegal or
                unauthorized
                purpose; and (</span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);">5</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) your use of the Site
                will not violate any applicable law or regulation.</span>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">If you
                provide any information that is untrue, inaccurate, not current, or incomplete, we have the
                right to
                suspend or terminate your account and refuse any and all current or future use of the Site (or
                any
                portion thereof).</span></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;">
            <bdt class="block-container if" data-type="if" id="d13abc62-cc6f-e059-7fd6-cb6208085aa7">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="user_account_option"
                        data-type="statement"></bdt>
                </bdt>
            </bdt>
        </div>
        <div class="MsoNormal" data-custom-class="heading_1" id="payment" style="line-height: 1.5;"><a
                name="_ynub0jdx8pob"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">4. FEES AND
                    PAYMENT</span></strong></div>
        <div class="MsoNormal" style="line-height: 1;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><br></span></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="color: rgb(89, 89, 89); font-size: 15px;">We accept the following forms of
                payment:</span>
        </div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;">
            <div class="MsoNormal" style="line-height: 17.25px;">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1; text-align: left;">
                    <br>
                </div>
                <div class="MsoNormal" data-custom-class="body_text"
                    style="margin-left: 46.9pt; text-indent: -18.55pt; line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="block-container forloop" data-type="forloop"
                            id="a6e21b8e-b955-c546-15dd-bb6eda2fe88a">
                            <bdt data-type="conditional-block">
                                <bdt class="forloop-component" data-record-question-key="payment_list"
                                    data-type="statement"></bdt>
                            </bdt>
                        </bdt>
                    </span>
                </div>
            </div><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
            </span><span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="line-height: 115%; color: rgb(89, 89, 89);">You may be required to purchase or
                        pay
                        a fee to access some of our services. You agree to provide current, complete, and
                        accurate
                        purchase and account information for all purchases made via the Site. You further agree
                        to
                        promptly update account and payment information, including email address, payment
                        method,
                        and payment card expiration date, so that we can complete your transactions and contact
                        you
                        as needed. We bill you through an online billing account for purchases made via the
                        Site.
                        Sales tax will be added to the price of purchases as deemed required by us. We may
                        change
                        prices at any time. All payments shall be in <bdt class="question">GB Pounds</bdt>
                        .</span>
                </div>
            </span><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <div class="MsoNormal" style="line-height: 1; text-align: left;"><br></div>
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">You agree to pay
                        all
                        charges or fees at the prices then in effect for your purchases, and you authorize us to
                        charge your chosen payment provider for any such amounts upon making your purchase. <bdt
                            class="block-container if" data-type="if" id="2955e4e9-b2fc-b1ce-6d2e-99ed4a0c390d">
                            <bdt data-type="conditional-block">
                                <bdt class="block-component" data-record-question-key="recurring_charge_option"
                                    data-type="statement"></bdt>
                            </bdt>
                        </bdt></span>
                </div><br>
            </span><span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="line-height: 115%; color: rgb(89, 89, 89);">We reserve the right to correct any
                        errors or mistakes in
                        pricing, even if we have already requested or received payment. We also reserve
                        the right to refuse any order placed through the Site.</span>
                </div>
            </span>
        </div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;">
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><a name="_drzjqilz2ujm"></a>
            </div>
            <bdt class="block-container if" data-type="if" id="4380167d-5abe-b98f-f389-f707429e6c52">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="free_trial_option" data-type="statement">
                    </bdt>
                </bdt>
                <div class="MsoNormal" data-custom-class="heading_1" id="cancel"
                    style="text-align: left; line-height: 1.5;"><a name="_e993diqrk0qx"></a><strong><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;">5.
                            CANCELLATION</span></strong></div>
            </bdt>
        </div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                <span style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="cef55cf6-3a65-8031-d0c3-cffe36e64f10">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="return_option"
                                data-type="statement"></bdt>
                        </bdt>You can cancel your subscription at any time
                    </bdt>
                </span><span style="font-size:11.0pt;line-height:115%;
Arial;color:#595959;mso-themecolor:text1;mso-themetint:166;">&nbsp;</span><span
                    style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="cancel_how"
                                data-type="statement"></bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <bdt class="block-container question question-in-editor"
                                    data-id="860fd49a-dd88-3553-0f1b-0b5419dea7e6" data-type="question">
                                    
                                </bdt>
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt>. Your cancellation will take effect at the end of the current paid term.
                </span>
            </div>
        </div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                <span style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">If you are unsatisfied
                    with
                    our services, please email us at <bdt class="block-container question question-in-editor"
                        data-id="7c40698b-5cc8-bb58-245d-2fbd1b57dfba" data-type="question">developer@tomstowe.co.uk</bdt>
                    <bdt class="block-component"></bdt>
                </span>.
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1;">
                <bdt class="block-container if" data-type="if" id="b671a946-ab46-9fff-9cee-e88149335e8e">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="software_option"
                            data-type="statement"></bdt>
                    </bdt>
                </bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" id="prohibited"
                style="line-height: 1.5; text-align: left;"><a name="_h284p8mrs3r7"></a><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">6. PROHIBITED
                        ACTIVITIES</span></strong></div>
        </div>
        <div class="MsoNormal" style="line-height: 1;"><br></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">You may
                not
                access or use the Site for any purpose other than that for which we make the Site available. The
                Site may not be used in connection with any commercial endeavors except those that are
                specifically
                endorsed or approved by us.</span></div>
        <div class="MsoNormal" style="line-height: 1;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 115%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                <span style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">As a user of the
                    Site,
                    you agree not to:</span>
            </div>
            <ul>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                            style="font-family: sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Systematically
                            retrieve data or other content from the Site to create or compile, directly or
                            indirectly, a collection, compilation, database, or directory without written
                            permission
                            from us.</span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Trick,
                                        defraud, or mislead us and other users, especially in any attempt to
                                        learn
                                        sensitive account information such as user
                                        passwords.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Circumvent,
                                        disable, or otherwise interfere with security-related features of the
                                        Site,
                                        including features that prevent or restrict the use or copying of any
                                        Content or enforce limitations on the use of the Site and/or the Content
                                        contained therein.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Disparage,
                                        tarnish, or otherwise harm, in our opinion, us and/or the
                                        Site.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        any information obtained from the Site in order to harass, abuse, or
                                        harm
                                        another person.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Make
                                        improper use of our support services or submit false reports of abuse or
                                        misconduct.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        the Site in a manner inconsistent with any applicable laws or
                                        regulations.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Engage
                                        in unauthorized framing of or linking to the
                                        Site.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Upload
                                        or transmit (or attempt to upload or to transmit) viruses, Trojan
                                        horses, or
                                        other material, including excessive use of capital letters and spamming
                                        (continuous posting of repetitive text), that interferes with any
                                        party’s
                                        uninterrupted use and enjoyment of the Site or modifies, impairs,
                                        disrupts,
                                        alters, or interferes with the use, features, functions, operation, or
                                        maintenance of the Site.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Engage
                                        in any automated use of the system, such as using scripts to send
                                        comments
                                        or messages, or using any data mining, robots, or similar data gathering
                                        and
                                        extraction tools.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Delete
                                        the copyright or other proprietary rights notice from any
                                        Content.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Attempt
                                        to impersonate another user or person or use the username of another
                                        user.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Upload
                                        or transmit (or attempt to upload or to transmit) any material that acts
                                        as
                                        a passive or active information collection or transmission mechanism,
                                        including without limitation, clear graphics interchange formats
                                        (“gifs”),
                                        1×1 pixels, web bugs, cookies, or other similar devices (sometimes
                                        referred
                                        to as “spyware” or “passive collection mechanisms” or
                                        “pcms”).</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Interfere
                                        with, disrupt, or create an undue burden on the Site or the networks or
                                        services connected to the Site.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Harass,
                                        annoy, intimidate, or threaten any of our employees or agents engaged in
                                        providing any portion of the Site to
                                        you.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Attempt
                                        to bypass any measures of the Site designed to prevent or restrict
                                        access to
                                        the Site, or any portion of the Site.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Copy
                                        or adapt the Site’s software, including but not limited to Flash, PHP,
                                        HTML,
                                        JavaScript, or other code.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Except
                                        as permitted by applicable law, decipher, decompile, disassemble, or
                                        reverse
                                        engineer any of the software comprising or in any way making up a part
                                        of
                                        the Site.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Except
                                        as may be the result of standard search engine or Internet browser
                                        usage,
                                        use, launch, develop, or distribute any automated system, including
                                        without
                                        limitation, any spider, robot, cheat utility, scraper, or offline reader
                                        that accesses the Site, or using or launching any unauthorized script or
                                        other software.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        a buying agent or purchasing agent to make purchases on the
                                        Site.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Make
                                        any unauthorized use of the Site, including collecting usernames and/or
                                        email addresses of users by electronic or other means for the purpose of
                                        sending unsolicited email, or creating user accounts by automated means
                                        or
                                        under false pretenses.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                    <span style="font-size: 15px;"><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        the Site as part of any effort to compete with us or otherwise use the
                                        Site
                                        and/or the Content for any revenue-generating endeavor or commercial
                                        enterprise.</span></span></span></span></span><span
                        style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="forloop-component"></bdt>
                    </span>
                </li><li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                <span style="font-size: 15px;"><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                            style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                    style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Upload or share any files which you do not have legal right to use.</span></span></span></span></span><span
                    style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                    <bdt class="forloop-component"></bdt>
                </span>
            </li>
            </ul>
        </div>
        <div class="MsoNormal" style="line-height: 1.5;"><a name="_zbbv9tgty199"></a></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="heading_1" id="ugc" style="line-height: 1.5;">
                        <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">7. USER
                                GENERATED CONTRIBUTIONS</span></strong>
                    </div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" style="text-align:justify;line-height:115%;">
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; text-align: left;"><span
                                style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="block-container if" data-type="if"
                                    id="24327c5d-a34f-f7e7-88f1-65a2f788484f">
                                    <bdt data-type="conditional-block">
                                        <bdt class="block-component"
                                            data-record-question-key="user_post_content_option"
                                            data-type="statement"><span style="font-size: 15px;"></span></bdt>
                                        The
                                        Site does not offer users to submit or post content.<bdt
                                            class="block-component"></bdt> We may provide you with the
                                        opportunity
                                        to create, submit, post, display, transmit, perform, publish,
                                        distribute, or
                                        broadcast content and materials to us or on the Site, including but not
                                        limited to text, writings, video, audio, photographs, graphics,
                                        comments,
                                        suggestions, or personal information or other material (collectively,
                                        "Contributions"). Contributions may be viewable by other users of the
                                        Site
                                        and through third-party websites. As such, any Contributions you
                                        transmit
                                        may be treated in accordance with the Site Privacy Policy. When you
                                        create
                                        or make available any Contributions, you thereby represent and warrant
                                        that:
                                        <bdt class="block-container if" data-type="if"
                                            id="b671a946-ab46-9fff-9cee-e88149335e8e">
                                            <bdt class="statement-end-if-in-editor" data-type="close">
                                                <bdt class="block-component"></bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </span></div>
                    </div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="heading_1" id="license" style="line-height: 1.5;">
                        <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">8.
                                CONTRIBUTION LICENSE</span></strong>
                    </div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                            <bdt class="block-container if" data-type="if"
                                id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709">
                                <bdt data-type="conditional-block">
                                    <bdt class="block-component" data-record-question-key="user_post_content_option"
                                        data-type="statement"></bdt>
                                </bdt>
                            </bdt>
                        </span></div>
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">You
                            and the Site agree that we may access, store, process, and use any information and
                            personal data that you provide following the terms of the Privacy Policy and your
                            choices (including settings).</span></div>
                    <div align="center" style="text-align: left; line-height: 1;"><br></div>
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">By
                            submitting suggestions or other feedback regarding the Site, you agree that we can
                            use
                            and share &nbsp;such feedback for any purpose without compensation to you.<bdt
                                class="block-component"></bdt></span></div>
                    <div align="center" style="text-align: left; line-height: 1;"><br></div>
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We
                            do not assert any ownership over your Contributions. You retain full ownership of
                            all of
                            your Contributions and any intellectual property rights or other proprietary rights
                            associated with your Contributions. We are not liable for any statements or
                            representations in your Contributions provided by you in any area on the Site. You
                            are
                            solely responsible for your Contributions to the Site and you expressly agree to
                            exonerate us from any and all responsibility and to refrain from any legal action
                            against us regarding your Contributions.</span></div>
                    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
                    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
                    <div align="center" style="text-align: left; line-height: 1;">
                        <bdt class="block-container if" data-type="if">
                            <bdt class="statement-end-if-in-editor" data-type="close"><span
                                    style="font-size: 15px;"></span></bdt>
                        </bdt>
                        <bdt class="block-container if" data-type="if" id="a378120a-96b1-6fa3-279f-63d5b96341d3">
                            <bdt data-type="conditional-block">
                                <bdt class="block-component" data-record-question-key="review_option"
                                    data-type="statement"></bdt>
                            </bdt>
                            <bdt class="block-container if" data-type="if"
                                id="c954892f-02b9-c743-d1e8-faf0d59a4b70">
                                <bdt data-type="conditional-block">
                                    <bdt class="block-component" data-record-question-key="mobile_app_option"
                                        data-type="statement"></bdt>
                                </bdt>
                                <bdt class="block-container if" data-type="if"
                                    id="e9981d4e-3a93-85dd-654b-7ecdf4bfe7d2">
                                    <bdt data-type="conditional-block">
                                        <bdt class="block-component"
                                            data-record-question-key="socialnetwork_link_option"
                                            data-type="statement"></bdt>
                                    </bdt>
                                    <div class="MsoNormal" data-custom-class="heading_1" id="submissions"
                                        style="line-height: 1.5;"><a name="_wfq2hvrw11j4"></a><strong><span
                                                style="line-height: 115%; font-family: Arial; font-size: 19px;">9.
                                                SUBMISSIONS</span></strong></div>
                                </bdt>
                            </bdt>
                        </bdt>
                    </div>
                    <div align="center" style="text-align: left; line-height: 1;"><br></div>
                    <div align="center" style="text-align: left; line-height: 1.5;">
                        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">You
                                acknowledge and agree that any questions, comments, suggestions, ideas,
                                feedback, or
                                other information regarding the Site ("Submissions") provided by you to us are
                                non-confidential and shall become our sole property. We shall own exclusive
                                rights,
                                including all intellectual property rights, and shall be entitled to the
                                unrestricted use and dissemination of these Submissions for any lawful purpose,
                                commercial or otherwise, without acknowledgment or compensation to you. You
                                hereby
                                waive all moral rights to any such Submissions, and you hereby warrant that any
                                such
                                Submissions are original with you or that you have the right to submit such
                                Submissions. You agree there shall be no recourse against us for any alleged or
                                actual infringement or misappropriation of any proprietary right in your
                                Submissions.</span></div>
                        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                        <div class="MsoNormal" style="line-height: 1;">
                            <bdt class="block-container if" data-type="if"
                                id="36ce5a69-4560-4947-dc72-46e53e2d562a">
                                <bdt data-type="conditional-block">
                                    <bdt class="block-component" data-record-question-key="3rd_party_option"
                                        data-type="statement"></bdt>
                                </bdt>
                                <bdt class="block-container if" data-type="if"
                                    id="14038561-dad7-be9d-370f-f8aa487b2570">
                                    <bdt data-type="conditional-block">
                                        <bdt class="block-component" data-record-question-key="advertiser_option"
                                            data-type="statement">
                                            <bdt class="block-component"></bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </bdt>
                        </div>
                    </div>
                    <div class="MsoNormal" data-custom-class="heading_1" id="sitemanage" style="line-height: 1.5;">
                        <a name="_wj13r09u8u3u"></a><strong><span
                                style="line-height: 115%; font-family: Arial; font-size: 19px;">10. SITE
                                MANAGEMENT</span></strong>
                    </div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We
                reserve
                the right, but not the obligation, to: (1) monitor the Site for violations of these <bdt
                    class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt>; (2) take
                appropriate
                legal action against anyone who, in our sole discretion, violates the law or these <bdt
                    class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt>, including without
                limitation, reporting such user to law enforcement authorities; (3) in our sole
                discretion and without limitation, refuse, restrict access to, limit the
                availability of, or disable (to the extent technologically feasible) any of
                your Contributions or any portion thereof; (4) in our sole discretion and
                without limitation, notice, or liability, to remove from the Site or otherwise
                disable all files and content that are excessive in size or are in any way
                burdensome to our systems; and (5) otherwise manage the Site in a manner
                designed to protect our rights and property and to facilitate the proper
                functioning of the Site.</span></div>
        <div class="MsoNormal" style="line-height: 1.5;"><a name="_jugvcvcw0oj9"></a></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt class="block-component" data-record-question-key="privacy_policy_option" data-type="statement">
                </bdt>
                <bdt class="block-container if" data-type="if" id="7371467a-f2b5-2aff-cd0f-3379e970551e">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="privacy_policy_followup"
                            data-type="statement" style="font-size: 14.6667px;">
                            <bdt class="block-component">
                                <bdt class="block-component"></bdt>
                            </bdt>
                        </bdt>
                    </bdt>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="heading_1" id="terms" style="line-height: 1.5;"><a
                name="_k3mndam4w6w1"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">11. TERM AND
                    TERMINATION</span></strong></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">These
                <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt> shall remain in
                full
                force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE <bdt
                    class="block-component"></bdt>TERMS OF USE<bdt class="else-block"></bdt>, WE RESERVE THE
                RIGHT
                TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
                (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                INCLUDING
                WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                <bdt class="block-component"></bdt>TERMS OF USE<bdt class="else-block"></bdt> OR OF ANY
                APPLICABLE
                LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE <bdt
                    class="block-container if" data-type="if" id="a6e121c2-36b4-5066-bf9f-a0a33512e768">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_account_option"
                            data-type="statement"></bdt>
                    </bdt>ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                    DISCRETION.
                </bdt>
            </span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">If we terminate
                    or suspend your account for any reason, you are prohibited from registering and
                    creating a new account under your name, a fake or borrowed name, or the name of
                    any third party, even if you may be acting on behalf of the third party. In
                    addition to terminating or suspending your account, we reserve the right to
                    take appropriate legal action, including without limitation pursuing civil,
                    criminal, and injunctive redress.</span></span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        <div class="MsoNormal" data-custom-class="heading_1" id="modifications" style="line-height: 1.5;"><a
                name="_e2dep1hfgltt"></a><strong><span style="line-height: 115%; font-family: Arial;"><span
                        style="font-size: 19px;">12. MODIFICATIONS AND INTERRUPTIONS</span></span></strong>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We
                reserve
                the right to change, modify, or remove the contents of the Site at any time or for any reason at
                our
                sole discretion without notice. However, we have no obligation to update any information on our
                Site. We also reserve the right to modify or discontinue all or part of the Site without notice
                at
                any time. We will not be liable to you or any third party for any modification, price change,
                suspension, or discontinuance of the Site.</span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We
                cannot
                guarantee the Site will be available at all times. We may experience hardware, software, or
                other
                problems or need to perform maintenance related to the Site, resulting in interruptions, delays,
                or
                errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise
                modify
                the Site at any time or for any reason without notice to you. You agree that we have no
                liability
                whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the
                Site
                during any downtime or discontinuance of the Site. Nothing in these <bdt class="block-component">
                </bdt>Terms of Use<bdt class="else-block"></bdt> will be construed to obligate us to maintain
                and
                support the Site or to supply any corrections, updates, or releases in connection
                therewith.</span>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        <div class="MsoNormal" data-custom-class="heading_1" id="law" style="line-height: 1.5;"><a
                name="_p6vbf8atcwhs"></a><strong><span style="line-height: 115%; font-family: Arial;"><span
                        style="font-size: 19px;">13. GOVERNING LAW</span></span></strong></div>
        <div class="MsoNormal" style="line-height: 1;"><br></div>
        <div class="MsoNormal" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <bdt class="block-component"></bdt>
            </span></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px;">These Terms shall be governed by and defined following the laws of <bdt
                    class="block-component"></bdt>
                <bdt class="question">the United Kindom</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>. <bdt class="question">Sheet Music</bdt> and
                yourself
                irrevocably consent that the courts of <span style="font-size: 15px;">
                    <bdt class="block-component"></bdt>
                    <bdt class="question">the United Kingdom</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>
                </span> shall have exclusive jurisdiction to resolve any dispute which may arise in connection
                with
                these terms.<span style="font-size: 15px;"><span style="font-size: 15px;">
                        <bdt class="statement-end-if-in-editor"></bdt>
                    </span></span>
            </span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        <div class="MsoNormal" data-custom-class="heading_1" id="disputes" style="line-height: 1.5;"><a
                name="_v5i5tjw62cyw"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">14. DISPUTE
                    RESOLUTION</span></strong></div>
        <div class="MsoNormal" style="line-height: 1;"><br></div>
        <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-container if" data-type="if">
                <bdt data-type="conditional-block">
                    <bdt class="block-component"></bdt>
                </bdt>
            </bdt>
        </div>
    </div>
    <div class="MsoNormal" style="line-height: 1.5;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" style="line-height: 1.5;">
                        <bdt class="block-component"></bdt>
                    </div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div class="MsoNormal" style="line-height: 1.5;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;">
                        <strong>Informal
                            Negotiations</strong>
                    </div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                            style="font-size: 15px;">To expedite r</span><span
                            style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">esolution
                            and
                            control the cost of any dispute, controversy, or claim related to these <bdt
                                class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt> (each
                            "Dispute" and collectively, the “Disputes”) brought by either you or us
                            (individually, a
                            “Party” and collectively, the “Parties”), the Parties agree to first attempt to
                            negotiate any Dispute (except those Disputes expressly provided below) informally
                            for at
                            least <bdt class="block-container question question-in-editor"
                                data-id="33108d82-92fb-fbe9-6ef5-01cac7097351" data-type="question">30
                            </bdt>
                            days before initiating arbitration. Such informal negotiations commence upon written
                            notice from one Party to the other Party.</span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" style="line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" style="line-height: 1.5;"></div>
                </bdt>
            </bdt>
        </bdt>
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><span
                            style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);"><strong>Binding
                                Arbitration</strong></span></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
        <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
    </div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;">Any dispute arising out of or in connection with this contract, including
            any
            question regarding its existence, validity, or termination, shall be referred to and finally
            resolved by
            the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium,
            Brussels, Avenue Louise, 146) &nbsp;according to the Rules of this ICAC, which, as a result of
            referring
            to it, is considered as the part of this clause. The language to be used in the arbitral proceedings shall be <bdt class="question">
                English
            </bdt>. The governing law of the contract shall be the substantive law of <bdt class="block-component">
            </bdt>
            <bdt class="block-component"></bdt>
            <bdt class="question">the United Kingdom</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="statement-end-if-in-editor"></bdt>.
        </span>
        <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"></span></bdt>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><strong>Restrictions</strong>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;">The Parties agree that any arbitration shall be limited to the Dispute
            between
            the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined
            with
            any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a
            class-action basis or to utilize class action procedures; and (c) there is no right or authority for
            any
            Dispute to be brought in a purported representative capacity on behalf of the general public or any
            other persons.</span></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;">
        <bdt class="block-component"><span style="font-size: 15px;"><strong></strong></span></bdt>
        <strong>Exceptions
            to Informal Negotiations and Arbitration</strong>
        <bdt class="statement-end-if-in-editor"><strong></strong></bdt>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">
            <bdt class="block-component"></bdt>The Parties agree that the following Disputes are not subject to
            the
            above provisions concerning informal negotiations and binding arbitration: (a) any Disputes seeking
            to
            enforce or protect, or concerning the validity of, any of the intellectual property rights of a
            Party;
            (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or
            unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal
            or
            unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of
            this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of
            competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
            submit
            to the personal jurisdiction of that court.<span
                style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                <bdt class="statement-end-if-in-editor"></bdt>
            </span>
        </span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                    <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                </span></span></span></bdt>
                </span></span></span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="corrections" style="line-height: 1.5;">
        <strong><span style="font-size: 19px;">15. CORRECTIONS</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;">There may be information on the Site that contains typographical errors,
            inaccuracies, or omissions, including descriptions, pricing, availability, and various other
            information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior notice.</span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="disclaimer" style="line-height: 1.5;"><strong><span
                style="font-size: 19px;">16. DISCLAIMER</span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;">THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
            YOUR
            USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,
            WE
            DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
            INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
            COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL
            ASSUME
            NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
            MATERIALS,
            (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
            USE
            OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
            TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
            CONTENT
            AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
            POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
            GUARANTEE, OR
            ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
            SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION
            BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT
            OR
            SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
            CAUTION
            WHERE APPROPRIATE.</span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="liability" style="line-height: 1.5;"><strong><span
                style="font-size: 19px;">17. LIMITATIONS OF LIABILITY</span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">IN
            <span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">NO
                EVENT
                WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                DIRECT,
                INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                PROFIT,
                LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
                BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. <span
                    style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="limitations_liability_option"
                                data-type="statement"></bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">NOTWITHSTANDING</bdt>
                        </bdt>
                    </bdt>
                </span><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">&nbsp;</bdt>
                        </bdt>
                    </bdt>
                </span><span
                    style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO
                                YOU
                                FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
                                TIMES
                                BE LIMITED TO <span
                                    style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                                    <bdt class="block-container if" data-type="if"
                                        id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body">
                                                <bdt class="block-container if" data-type="if"
                                                    id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                                    <bdt data-type="conditional-block">
                                                        <bdt class="block-component"
                                                            data-record-question-key="limitations_liability_option"
                                                            data-type="statement"></bdt>
                                                    </bdt>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                    <bdt class="block-container if" data-type="if"
                                        id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body">
                                                <bdt class="block-container if" data-type="if"
                                                    id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                                    <bdt data-type="conditional-block">
                                                        <bdt data-type="body">&nbsp;THE LESSER OF THE AMOUNT
                                                            PAID,
                                                            IF ANY, BY YOU TO US <span
                                                                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                                                                <bdt class="block-container if" data-type="if"
                                                                    id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                                                    <bdt data-type="conditional-block">
                                                                        <bdt data-type="body">
                                                                            <bdt class="block-container if"
                                                                                data-type="if"
                                                                                id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                                                                <bdt data-type="conditional-block">
                                                                                    <bdt data-type="body">
                                                                                        <bdt
                                                                                            data-type="conditional-block">
                                                                                            <bdt class="block-component"
                                                                                                data-record-question-key="limilation_liability_time_option"
                                                                                                data-type="statement">
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </bdt>
                                                            </span></bdt>
                                                    </bdt>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </span></bdt>
                        </bdt>
                    </bdt>
                </span> OR <span
                    style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <bdt class="block-container if" data-type="if"
                                    id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                    <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                            <bdt class="block-container question question-in-editor"
                                                data-id="243ad246-9e92-b24d-beee-940be6aa7854" data-type="question">
                                                SHEET MUSIC</bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <bdt class="block-container if" data-type="if"
                                    id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                                </bdt>
                            </bdt>
                        </bdt>
                    </bdt>
                </span>. <span
                    style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                                LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                                DAMAGES.
                                IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
                                MAY
                                NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt>
                </span></span></span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="indemnification" style="line-height: 1.5;">
        <strong><span style="font-size: 19px;">18. INDEMNIFICATION</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;">You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from
            and
            against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and
            expenses, made by any third party due to or arising out of: <span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                <bdt class="block-container if" data-type="if" id="475fffa5-05ca-def8-ac88-f426b238903c">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_post_content_option"
                            data-type="statement"></bdt>
                    </bdt>
                </bdt>
            </span>(1) use of the Site; (2) breach of these <bdt class="block-component"></bdt>Terms of Use<bdt
                class="else-block"></bdt>; (3) any breach of your representations and warranties set forth in
            these
            <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt>; (4) your violation of
            the
            rights of a third party, including but not limited to intellectual property rights; or (5) any overt
            harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding
            the
            foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with
            our
            defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming aware of it.
        </span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="userdata" style="line-height: 1.5;"><strong><span
                style="font-size: 19px;">19. USER DATA</span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">We
            will maintain certain data that you transmit to the Site for the purpose of managing the performance
            of
            the Site, as well as data relating to your use of the Site. Although we perform regular routine
            backups
            of data, you are solely responsible for all data that you transmit or that relates to any activity
            you
            have undertaken using the Site. You agree that we shall have no liability to you for any loss or
            corruption of any such data, and you hereby waive any right of action against us arising from any
            such
            loss or corruption of such data.</span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="electronic" style="line-height: 1.5;"><span
            style="font-size: 19px;"><strong>20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                SIGNATURES</strong></span></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;">Visiting <span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">the Site, sending us
                emails,
                and completing online forms constitute electronic communications. You consent to receive
                electronic
                communications, and you agree that all agreements, notices, disclosures, and other
                communications we
                provide to you electronically, via email and on the Site, satisfy any legal requirement that
                such
                communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
                ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
                TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or
                requirements under any statutes, regulations, rules, ordinances, or other laws in any
                jurisdiction
                which require an original signature or delivery or retention of non-electronic records, or to
                payments or the granting of credits by any means other than electronic means.</span></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;">
        <bdt class="block-component"></bdt>
    </div>
    <div class="MsoNormal" data-custom-class="heading_1" id="misc" style="line-height: 1.5;"><strong><span
                style="font-size: 19px;">21. MISCELLANEOUS</span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;">These <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block">
            </bdt> and any policies or operating rules posted by us on the Site or in respect to the Site
            constitute
            the entire agreement and understanding between you and us. Our failure to exercise or enforce any
            right
            or provision of these <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt>
            shall not operate as a waiver of such right or provision. These <bdt class="block-component"></bdt>
            Terms
            of Use<bdt class="else-block"></bdt> operate to the fullest extent permissible by law. We may assign
            any
            or all of our rights and obligations to others at any time. We shall not be responsible or liable
            for
            any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any
            provision or part of a provision of these <bdt class="block-component"></bdt>Terms of Use<bdt
                class="else-block"></bdt> is determined to be unlawful, void, or unenforceable, that provision
            or
            part of the provision is deemed severable from these <bdt class="block-component"></bdt>Terms of Use
            <bdt class="else-block"></bdt> and does not affect the validity and enforceability of any remaining
            provisions. There is no joint venture, partnership, employment or agency relationship created
            between
            you and us as a result of these <bdt class="block-component"></bdt>Terms of Use<bdt class="else-block">
            </bdt> or use of the Site. You agree that these <bdt class="block-component"></bdt>Terms of Use<bdt
                class="else-block"></bdt> will not be construed against us by virtue of having drafted them. You
            hereby waive any and all defenses you may have based on the electronic form of these <bdt
                class="block-component"></bdt>Terms of Use<bdt class="else-block"></bdt> and the lack of signing
            by
            the parties hereto to execute these <bdt class="block-component"></bdt>Terms of Use<bdt
                class="else-block"></bdt>.<bdt class="block-component"></bdt>
        </span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="contact" style="line-height: 1.5;"><span
            style="font-size: 19px;"><strong>22. CONTACT US</strong></span></div>
    <div class="MsoNormal" style="line-height: 1.1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">In
            o<span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">rder
                to
                resolve a complaint regarding the Site or to receive further information regarding use of the
                Site,
                please contact us at:</span></span></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                <bdt class="question"><strong>developer@tomstowe.co.uk</strong></bdt>
            </span></span></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);"><strong><span
                        style="color: rgb(89, 89, 89);">
                        <bdt class="question"></bdt><strong><span style="color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px;">
                                    <bdt class="block-component"></bdt>
                                </span></span></strong>
                    </span></strong></span></span></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><strong><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                    <bdt class="question"></bdt><strong><span style="color: rgb(89, 89, 89);"><span
                                style="font-size: 15px;">
                                <bdt class="block-component"></bdt>
                            </span></span></strong><span style="font-size: 15px;"><strong><span
                                style="color: rgb(89, 89, 89);">
                                <bdt class="block-component"></bdt>
                            </span></strong><strong><span style="color: rgb(89, 89, 89);">
                                <bdt class="block-component"></bdt>
                            </span></strong></span>
                </span></span></strong></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><strong><span
                style="font-size: 15px;">
                <bdt class="block-component"></bdt>
            </span></strong></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><strong><span
                style="font-size: 15px;">
                <bdt class="block-component"></bdt>
            </span></strong></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
        <bdt class="question"><strong><span style="font-size: 15px;"></span></strong></bdt>
    </div>
</div>
<style>
    ul {
        list-style-type: square;
    }

    ul>li>ul {
        list-style-type: circle;
    }

    ul>li>ul>li>ul {
        list-style-type: square;
    }

    ol li {
        font-family: Arial;
    }
</style>
</div>`;
