import { SongMetaData } from './AudioDisplay';
import { Platform } from './AudioSearch';

export const GetYouTubeDetails = async (
  url: string
): Promise<{ platform: Platform; songMetaData: SongMetaData | undefined }> => {
  // Check if the YouTube video exists
  let results;
  try {
    results = await fetch('https://noembed.com/embed?url=' + url);
  } catch {
    return { platform: Platform.Invalid, songMetaData: undefined };
  }

  const text = await results.text();
  const data = JSON.parse(text);

  if (data['error']) {
    return { platform: Platform.Invalid, songMetaData: undefined };
  }

  return {
    songMetaData: {
      name: data['title'],
      artist: data['author_name'],
      thumbnailUrl: data['thumbnail_url'],
      platform: Platform.YouTube,
      url: url,
    } as SongMetaData,
    platform: Platform.YouTube,
  };
};
