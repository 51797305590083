import React, { useContext, useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AuthenticationClient } from '../../types/auto/types';
import { Configuration } from '../Constants';
import { FetchOverride } from '../utils/Requests';
import { UserContext, UserDispatchContext } from '../utils/UserStorage';

interface Props {
  children: React.ReactNode;
}

export const NavigateToLogin = (navigate: NavigateFunction): void => {
  // If the pathname is /login, don't add login again
  if (window.location.pathname === '/login') {
    return;
  }

  if ((window.location.pathname !== '' && window.location.pathname !== '/') || window.location.search !== '') {
    navigate('/login?redirect=' + window.location.pathname + window.location.search);
  } else {
    navigate('/login');
  }
};

const AuthedRoute = (props: Props): JSX.Element => {
  const user = useContext(UserContext);
  const userDispatch = useContext(UserDispatchContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id === undefined) {
      new AuthenticationClient(Configuration.SERVER_ROOT, FetchOverride)
        .isAuthorised()
        .then(x => {
          if (x?.id === undefined) {
            // If there is a current path, redirect the user with the current redirect
            NavigateToLogin(navigate);
          } else {
            userDispatch(x);
          }
        })
        .catch(() => NavigateToLogin(navigate));
    }
  }, [user]);
  return <>{user?.id !== undefined && props.children}</>;
};

export default AuthedRoute;
