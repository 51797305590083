import React, { useRef, useState } from 'react';
import { Button, Popover, Tooltip } from '@mui/material';
import { SketchPicker } from 'react-color';
import { zIndex } from '../../../Theme';

interface Props {
  colour: string;
  setColour: (colour: string) => void;
  size: string;
  marginLeft?: string | number;
  marginRight?: string | number;
}

const ColourPicker = (props: Props): JSX.Element => {
  const anchor = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title='Colour' placement='top' style={{ zIndex: zIndex.tooltip }}>
        <Button
          ref={anchor}
          style={{
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
            minWidth: props.size,
            minHeight: props.size,
            maxWidth: props.size,
            maxHeight: props.size,
            backgroundColor: props.colour,
            border: '1px solid black',
            borderRadius: 20,
          }}
          onClick={() => setOpen(x => !x)}
        />
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchor.current}
        onClose={() => setOpen(false)}
        style={{
          zIndex: zIndex.secondModal,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <SketchPicker
          color={props.colour}
          onChange={color => {
            props.setColour(color.hex);
          }}
          onChangeComplete={(result, event) => {
            // Click events only happen when a preset is clicked. Auto-close the modal if this happens
            if (event.type === 'click') {
              setOpen(false);
            }
          }}
          disableAlpha
        />
      </Popover>
    </>
  );
};

export default ColourPicker;
