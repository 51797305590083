import React, { createContext, useState } from 'react';

interface Props {
  children?: JSX.Element;
}

const ShowNavbarContext = (props: Props): JSX.Element => {
  const [showNavbar, setShowNavbar] = useState(true);

  return (
    <SetShowNavbar.Provider value={setShowNavbar}>
      <ShowNavbar.Provider value={showNavbar}>{props.children}</ShowNavbar.Provider>
    </SetShowNavbar.Provider>
  );
};

export const SetShowNavbar = createContext((() => 0) as React.Dispatch<boolean>);
export const ShowNavbar = createContext<boolean>(true);

export default ShowNavbarContext;
