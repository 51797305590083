import React, { useContext, useEffect, useState } from 'react';
import { Close, Done } from '@mui/icons-material';
import { Badge, Card, Container, IconButton, Tooltip, Typography } from '@mui/material';
import { SetSnackbarErrorContext } from '../components/elements/snackbar/SnackbarContext';
import { SetListClient, SetListInviteViewModel } from '../../types/auto/types';
import { Configuration } from '../Constants';
import { FetchOverride } from '../utils/Requests';
import { NavigateToLogin } from './AuthedRoute';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { theme, zIndex } from '../Theme';

const Notifications = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<SetListInviteViewModel[]>([]);
  const [refreshNotifications, setRefreshNotifications] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const navigate = useNavigate();

  useEffect(() => {
    new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
      .getSetListInvitesForUser()
      .then(x => {
        setNotifications(x.values || []);
        if ((x.values === undefined || x.values.length === 0) && anchorEl !== null) {
          setAnchorEl(null);
        }
      })
      .catch(x => {
        // As this notification check is everywhere, we should be able to navigate to login automatically here from every page
        if (x.status === 401) {
          NavigateToLogin(navigate);
        }
        setSnackbarError('Could not get notifications');
      });
  }, [setNotifications, refreshNotifications]);

  return (
    <>
      {notifications.length === 0 && (
        <Typography
          style={{
            fontSize: 15,
            paddingTop: '8px',
            paddingBottom: '8px',
            color: theme.palette.grey[700],
            textAlign: 'center',
            width: '100%',
          }}>
          No New Notifications
        </Typography>
      )}
      {notifications.map(x => (
        <Card
          key={x.name}
          style={{
            maxWidth: '400px',
            cursor: 'default',
            userSelect: 'none',
            padding: 20,
            paddingTop: 5,
            paddingBottom: 5,
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Container style={{ flex: 1, margin: 0, padding: 0, marginRight: 10 }}>
            <Badge
              color='primary'
              badgeContent=' '
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              variant='dot'
            />
          </Container>
          <Container
            style={{
              flex: 40,
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography style={{ fontSize: 15 }}>
              You&apos;ve been invited to the set list &apos;{x.name}&apos;
              {x.invitingUser ? ' by ' + x.invitingUser?.firstName + ' ' + x.invitingUser?.lastName : ''}.
            </Typography>
            <Typography style={{ fontSize: 10, color: 'grey', textAlign: 'left' }}>
              {moment(x.dateInviteSent).format('HH:mm DD/MM/YYYY')}
            </Typography>
          </Container>
          <Container style={{ flex: 15, margin: 0, padding: 0, display: 'inline-block' }}>
            <Tooltip title='Accept Invite' style={{ zIndex: zIndex.tooltip }}>
              <IconButton
                onClick={() => {
                  new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                    .joinSetList(x.token)
                    .then(() => setRefreshNotifications(z => !z))
                    .catch(() => setSnackbarError('Could not join the set list. Please try again'));
                }}>
                <Done style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Decline Invite' style={{ zIndex: zIndex.tooltip }}>
              <IconButton
                onClick={() => {
                  new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                    .declineSetListInvite(x.token)
                    .then(() => setRefreshNotifications(z => !z))
                    .catch(() => setSnackbarError('Could not decline the set list invite. Please try again'));
                }}>
                <Close style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          </Container>
        </Card>
      ))}
    </>
  );
};

export default Notifications;
