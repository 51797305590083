import React, { useContext, useEffect, useRef, useState } from 'react';
import { Document, pdfjs } from 'react-pdf';
import { AnnotationClient, AnnotationViewModel, SetListViewModel, SongViewModel } from '../../../../types/auto/types';
import { Configuration } from '../../../Constants';
import { FetchOverride } from '../../../utils/Requests';
import { Button, CircularProgress } from '@mui/material';
import PdfDisplayPage from './PdfDisplayPage';
import { AnnotationSettings } from '../../../../types/AnnotationSettings';
import { UserContext } from '../../../utils/UserStorage';
import { useSwipeable } from 'react-swipeable';
import { GetSongIdForPageNumber } from './helpers/GetSongIdForPageNumber';
import { SongIdToPageNumbers } from '../../../../types/SongIdToPageNumbers';
import { CalculateTransposition } from '../../../utils/CalculateTransposition';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

interface Props {
  currentPageNumber: number;
  totalPageNumber: number;
  setTotalPageNumber: React.Dispatch<React.SetStateAction<number>>;
  showTwoPages: boolean;
  fullScreen: boolean;
  annotationSettings?: AnnotationSettings;
  showAnnotations: boolean;
  showTranspositions: boolean;
  nextPage: () => void;
  prevPage: () => void;
  page1Song: SongViewModel | undefined;
  page2Song: SongViewModel | undefined;
  pdfData: ArrayBuffer | undefined;
  songIdToPageNumbers: SongIdToPageNumbers;
  setList: SetListViewModel | undefined;
  annotations: AnnotationViewModel[];
  setAnnotations: React.Dispatch<React.SetStateAction<AnnotationViewModel[]>>;
  zoomLevel: number;
  documentRef: React.MutableRefObject<Document | null>;
  isCapoRemoved: boolean;
  masterTranspose: number;
}

let startPanningTimestamp = 0;

const PdfDisplayBase = (props: Props): JSX.Element => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  // Setup the pdf worker
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const user = useContext(UserContext);
  const [rerender, setRerender] = useState(false);
  const [allowPanning, setAllowPanning] = useState(true);
  const [transformDisabled, setTransformDisabled] = useState(false);
  const [errorWhileLoading, setErrorWhileLoading] = useState(false);

  // Trigger a rerender on window resize
  window.onresize = () => setRerender(x => !x);

  const handlers = useSwipeable({
    onSwipedLeft: e => {
      (props.annotationSettings === undefined || !props.annotationSettings.isEditing) && props.nextPage();
      e.event.preventDefault();
      e.event.stopPropagation();
    },
    onSwipedRight: e => {
      (props.annotationSettings === undefined || !props.annotationSettings.isEditing) && props.prevPage();
      e.event.preventDefault();
    },
    onSwipedUp: () => undefined,
    onSwipedDown: () => undefined,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: false,
  });

  useEffect(() => {
    // The Transform Wrapper and component need to be centered (using 100% width and display block), but they don't have any style props
    const pdfDisplayBase = document.getElementById('pdfDisplayBase');
    const pdfTransformWrapper = pdfDisplayBase?.firstChild;
    if (pdfTransformWrapper) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (pdfTransformWrapper as any).style = 'width: 100%; display: block';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (pdfTransformWrapper.firstChild as any).style = 'width: 100%; display: block';
    }

    if (transformComponentRef?.current && props.annotationSettings?.isEditing) {
      // When annotations are enabled (when the user is editing), disable the transform component
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (transformComponentRef.current as any).style =
        '-webkit-touch-callout: default;-webkit-user-select: default;-khtml-user-select: default;-moz-user-select: default;-ms-user-select: default;user-select: default;';

      transformComponentRef.current.setTransform(0, 0, 1);
      setTransformDisabled(true);
    } else {
      setTransformDisabled(false);
    }
  }, [props.annotationSettings?.isEditing]);

  return (
    <div
      id='pdfDisplayBase'
      style={{
        display: 'inline-block',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textAlign: 'center',
        height: '100vh',
      }}
      {...handlers}>
      <TransformWrapper
        ref={transformComponentRef}
        disabled={transformDisabled}
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        doubleClick={{ disabled: true }}
        panning={{
          disabled: !allowPanning,
        }}
        onZoom={() => {
          ((document.body.style as unknown) as { zoom: string }).zoom = '100%';
        }}
        onPanningStart={(_, e) => {
          // Give the user 150ms before enabling panning. This allows them to still use swipe gestures to change the page
          if (startPanningTimestamp === 0) {
            setAllowPanning(false);
            startPanningTimestamp = e.timeStamp;
            setTimeout(() => setAllowPanning(true), 150);
          }
        }}
        onPanningStop={() => {
          startPanningTimestamp = 0;
        }}>
        {() => (
          <TransformComponent>
            <Document
              ref={props.documentRef}
              loading={
                <div
                  id='loading'
                  style={{
                    width: '100vw',
                    height: '80vh',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    verticalAlign: 'center',
                    alignContent: 'center',
                  }}>
                  <CircularProgress />
                </div>
              }
              onLoadError={e => {
                console.error(e);
                setErrorWhileLoading(true);
              }}
              noData={
                <div
                  id='noData'
                  style={{
                    width: '100vw',
                    height: '80vh',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    verticalAlign: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    gap: '20px',
                  }}>
                  {errorWhileLoading ? (
                    <>
                      <div>
                        <ReportGmailerrorredIcon fontSize='large' />
                      </div>
                      Sorry, we couldn&apos;t load your music. Please try again later.
                      <Button onClick={() => window.location.reload()}>Retry</Button>
                    </>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              }
              file={props.pdfData}
              onLoadSuccess={pdf => {
                props.setTotalPageNumber(pdf.numPages);
              }}>
              {[...Array(props.showTwoPages ? 2 : 1)]
                .filter((_, i) => props.currentPageNumber + i <= props.totalPageNumber)
                .map((_, i) => {
                  const songDetails = GetSongIdForPageNumber(props.songIdToPageNumbers, props.currentPageNumber + i);

                  if (songDetails === undefined) {
                    return <></>;
                  }

                  return (
                    <PdfDisplayPage
                      key={i}
                      currentPageNumber={props.currentPageNumber + i}
                      maxPageNumber={props.totalPageNumber}
                      maximise={props.fullScreen}
                      showAnnotations={props.showAnnotations}
                      showTranspositions={props.showTranspositions}
                      rerender={rerender}
                      annotationSettings={props.annotationSettings}
                      annotations={
                        props.annotations.filter(
                          x => x.songId === songDetails.songId && x.pageNumber === songDetails.pageNumberOfSong
                        ).length > 0
                          ? props.annotations.filter(
                              x => x.songId === songDetails.songId && x.pageNumber === songDetails.pageNumberOfSong
                            )[0].annotationString
                          : undefined
                      }
                      textAnnotations={
                        props.annotations.filter(
                          x => x.songId === songDetails.songId && x.pageNumber === songDetails.pageNumberOfSong
                        ).length > 0
                          ? props.annotations.filter(
                              x => x.songId === songDetails.songId && x.pageNumber === songDetails.pageNumberOfSong
                            )[0].textAnnotation
                          : undefined
                      }
                      songId={songDetails.songId}
                      pageNumber={props.currentPageNumber + i}
                      songPageNumber={songDetails.pageNumberOfSong}
                      setAnnotationsForSongPageNumber={(
                        annotations,
                        textAnnotations,
                        annotationSongId,
                        annotationPageNumber
                      ) =>
                        props.setAnnotations(x => {
                          let songAnnotationIndex = x.findIndex(
                            y => y.songId === annotationSongId && y.pageNumber === annotationPageNumber
                          );

                          // Add the annotation if it doesn't exist, or amend it if it does
                          if (songAnnotationIndex === -1) {
                            songAnnotationIndex =
                              x.push({
                                userId: user.id,
                                songId: annotationSongId,
                                pageNumber: annotationPageNumber,
                                annotationString: annotations,
                                textAnnotation: textAnnotations.filter(x => x.text !== ''),
                              } as AnnotationViewModel) - 1;
                          } else {
                            x[songAnnotationIndex].annotationString = annotations;
                            x[songAnnotationIndex].textAnnotation = textAnnotations.filter(x => x.text !== '');
                          }

                          // Send to the backend
                          new AnnotationClient(Configuration.SERVER_ROOT, FetchOverride).setAnnotationsForSong(
                            annotationSongId,
                            annotationPageNumber,
                            x[songAnnotationIndex]
                          );

                          return x;
                        })
                      }
                      transposeAmount={
                        i === 0
                          ? CalculateTransposition(props.page1Song, props.isCapoRemoved, props.masterTranspose) || 0
                          : CalculateTransposition(props.page2Song, props.isCapoRemoved, props.masterTranspose) || 0
                      }
                      showTwoPages={props.showTwoPages}
                      zoomLevel={props.zoomLevel}
                    />
                  );
                })}
            </Document>
          </TransformComponent>
        )}
      </TransformWrapper>
    </div>
  );
};

export default PdfDisplayBase;
