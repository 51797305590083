import { Configuration } from '../../Constants';

export class SetListProgressWS {
  userId = 0;
  userName = '';
  setListId = 0;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onProgressUpdate: (setListProgress: SetListProgress[]) => void = () => {};

  webSocket: WebSocket | null = null;
  reopenAttempts = 0;
  maxReopenAttempts = 10;

  constructor(
    userName: string | undefined,
    setListId: number,
    onProgressUpdate: (setListProgress: SetListProgress[]) => void
  ) {
    this.__setupWebsocket(userName, setListId, onProgressUpdate);
  }

  updatePageNumber(pageNumber: number): void {
    // Don't try if the connection's currently closed
    if (this.reopenAttempts > 0) {
      return;
    }
    try {
      this.webSocket?.send(
        JSON.stringify({
          UserId: this.userId,
          UserName: this.userName,
          SetListId: this.setListId,
          PageNumber: pageNumber,
        } as SetListProgress)
      );
    } catch (e) {
      // Try to restart the connection, otherwise log the error
      if (!this.__restartConnectionIfAppropriate(e as Error)) {
        console.log('An error occurred sending page number update');
        console.error(e);
      }
    }
  }

  closeConnection(): void {
    this.webSocket?.close();
  }

  __setupWebsocket(
    userName: string | undefined,
    setListId: number,
    onProgressUpdate: (setListProgress: SetListProgress[]) => void
  ): void {
    // Save the fields
    this.setListId = setListId;
    this.userName = userName || generateRandomUsername();
    this.userId = this.userId !== 0 ? this.userId : getRandomInt(9999999);
    this.onProgressUpdate = onProgressUpdate;

    try {
      // Create the websocket and immediately send progress
      this.webSocket = new WebSocket(Configuration.SET_LIST_WEBSOCKET_URL);
    } catch (e) {
      console.log('Could not create websocket');
      console.error(e);
      return;
    }

    try {
      this.webSocket.onmessage = e => {
        onProgressUpdate(JSON.parse(e.data) as SetListProgress[]);
      };
    } catch (e) {
      // Try to restart the connection, otherwise log the error
      if (!this.__restartConnectionIfAppropriate(e as Error)) {
        console.error(e);
      }
    }

    this.webSocket.onclose = () => {
      if (this.reopenAttempts < this.maxReopenAttempts) {
        this.reopenAttempts += 1;
        console.log('Detected connection closed, attempting to re-open');

        // Try to reconnect after 5 seconds
        setTimeout(() => this.__setupWebsocket(this.userName, this.setListId, this.onProgressUpdate), 5000);
      }
    };

    // Send an initial message to introduce yourself
    this.webSocket.onopen = () => {
      // If we get this far, reset the reopen attempts as we've successfully connected. This will also allow messages to be sent again
      this.reopenAttempts = 0;
      this.updatePageNumber(1);
    };
  }

  // Using the error message, work out if should restart the websocket and do so
  __restartConnectionIfAppropriate(error: Error): boolean {
    // If the websocket error is to do with the state already being closed, try and open it again!
    if (error.message.includes('CLOSING or CLOSED')) {
      this.__setupWebsocket(this.userName, this.setListId, this.onProgressUpdate);
      return true;
    }

    return false;
  }
}

function getRandomInt(max: number): number {
  return Math.floor(Math.random() * max);
}

function generateRandomUsername(): string {
  const instruments = [
    'Alghoza',
    'Alphorn',
    'Accordion',
    'Bagpipes',
    'Banjo',
    'Bassoon',
    'Tenoroon',
    'Berimbau',
    'Bongo',
    'Chimta',
    'Calliope',
    'Cello',
    'Clarinet',
    'Clavichord',
    'Cornet',
    'Cymbal',
    'Dhime',
    'Didgeridoo',
    'Dizi',
    'Esraj',
    'Ektara',
    'Erhu',
    'Euphonium',
    'Fiddle',
    'Flute',
    'Ghatam',
    'Glockenspiel',
    'Gong',
    'Guitar',
    'Fusetar',
    'Dobro',
    'Yotar',
    'Guqin',
    'Guzheng',
    'Hang',
    'Harmonica',
    'Harmonium',
    'Harp',
    'Harpsichord',
    'Hulusi',
    'Kalimba',
    'Kantele',
    'Lute',
    'Lyre',
    'Mandolin',
    'Mando-bass',
    'Mandocello',
    'Mandola',
    'Marimba',
    'Melodica',
    'Nyckelharpa',
    'Oboe',
    'Ocarina',
    'Octobass',
    'Organ',
    'Otamatone',
    'Oud',
    'Panduri',
    'Pennywhistle',
    'Piano',
    'Pianola',
    'Piccolo',
    'Pipa',
    'Pungi',
    'Recorder',
    'Garklein',
    'Sopranino',
    'Descant',
    'Tenor',
    'Bass',
    'Sub-subcontrabass',
    'Venova',
    'Sarangi',
    'Sarinda',
    'Suroz',
    'Sursingar',
    'Santoor',
    'Sarod',
    'Saxophone',
    'Soprillo',
    'Tubax',
    'Venova',
    'Xaphoon',
    'Shehnai',
    'Sheng',
    'Sitar',
    'Steelpan',
    'Stylophone',
    'Suona',
    'Synthesizer',
    'Tabla',
    'Tambourine',
    'Timpani',
    'Florguitar',
    'Triangle',
    'Trombone',
    'Trumpet',
    'Theremin',
    'Tuba',
    'Ukulele',
    "Lili'u",
    'U-bass',
    'Veena',
    'Vibraphone',
    'Viola',
    'Violin',
    'Viola',
    'Whamola',
    'Xylophone',
    'Zither',
  ];

  const randomInstrument = Math.floor(Math.random() * instruments.length);
  return 'Anonymous ' + instruments[randomInstrument] + ' Player (Guest)';
}

export type SetListProgress = {
  UserId: number;
  UserName: string;
  SetListId: number;
  PageNumber: number;
  LastUpdated: boolean; // Was this element the most recently updated
};
