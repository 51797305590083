import React from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import Logo from '../../images/Logo.png';

const NotFound = (): JSX.Element => (
  <Container maxWidth='xs' sx={{ padding: 10, textAlign: 'center' }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <img src={Logo} alt='Logo' style={{ width: 50, marginBottom: 10 }} />
      <Typography component='h1' variant='h5'>
        Sorry, the page you were looking for could not be found.
      </Typography>

      <Link sx={{ marginTop: 5, textDecoration: 'none' }} href='/'>
        Return Home
      </Link>
    </Box>
  </Container>
);

export default NotFound;
