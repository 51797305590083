import React, { useContext, useEffect, useState } from 'react';
import { Card, CircularProgress, Container, Grid, IconButton, Pagination, Tooltip, Typography } from '@mui/material';
import SetListCard from './SetListCard';
import { SetListClient, SetListViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import ArchiveIcon from '@mui/icons-material/Archive';
import AddIcon from '@mui/icons-material/Add';
import SetListModal from '../set-list-modal/SetListModal';
import SearchBar from '../elements/search-bar/SearchBar';
import NoResultsScreen from '../elements/no-results/NoResultsScreen';
import { SetSnackbarErrorContext } from '../elements/snackbar/SnackbarContext';
import { isMobile } from 'react-device-detect';

const searchQueryParam = 'search';

const SetListCards = (): JSX.Element => {
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [setListToEdit, setSetListToEdit] = useState<SetListViewModel>();
  const [setLists, setSetLists] = useState<SetListViewModel[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get(searchQueryParam);
  const [searchTerm, setSearchTerm] = useState(searchParam || '');
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  useEffect(() => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      (searchTerm === '' || searchTerm === null ? '' : `?${searchQueryParam}=${searchTerm}`);
    window.history.pushState({ path: newurl }, '', newurl);

    const takeAmount = 20;
    new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
      .getSetLists(searchTerm, false, false, takeAmount, (currentPageNumber - 1) * takeAmount, showDeleted)
      .then(x => {
        setSetLists(x.values || []);
        setTotalPageNumber(x.totalPageNumber);
        setCurrentPageNumber(x.currentPageNumber);
        setLoading(false);
        setInitialLoad(false);
      })
      .catch(() => {
        setLoading(false);
        setInitialLoad(false);
        setSetLists([]);
        setSnackbarError('Set lists could not be loaded. Please try again later');
      });
  }, [refresh, setSetLists, searchTerm, currentPageNumber]);

  return (
    <>
      <SetListModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSetListToEdit(undefined);
          setRefresh(x => !x);
        }}
        setListToEdit={setListToEdit}
      />
      <Card sx={{ padding: 2, margin: 0, position: 'relative' }}>
        <Typography variant='h5' sx={{ paddingTop: 2, display: 'inline-block' }}>
          My Set Lists
        </Typography>
        <Tooltip title={showDeleted ? 'Hide Deleted Set Lists' : 'Show Deleted Set Lists'}>
          <IconButton
            onClick={() => {
              setShowDeleted(x => !x);
              setRefresh(x => !x);
            }}
            sx={{ display: 'inline-block', float: 'right', marginRight: 3, marginTop: 1 }}>
            <ArchiveIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title='New Set List'>
          <IconButton
            onClick={() => setModalOpen(true)}
            sx={{ display: 'inline-block', float: 'right', marginRight: 1, marginTop: 1 }}>
            <AddIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Tooltip>
        <SearchBar
          placeholder='Search for set list name'
          searchTerm={searchTerm}
          onSearchChange={value => {
            setLoading(true);
            setSearchTerm(value);
            setCurrentPageNumber(0);
          }}
          loading={loading}
        />
        {initialLoad ? (
          <Container style={{ width: '100%', height: 300 }}>
            <CircularProgress style={{ position: 'relative', top: '50%', left: '50%' }} />
          </Container>
        ) : setLists.length === 0 ? (
          <NoResultsScreen entityName='set list' />
        ) : (
          <>
            <Grid container spacing={2} sx={{ marginTop: 1, alignItems: 'stretch' }}>
              {setLists.map((setList, index) => (
                <Grid key={'setList' + index.toString()} item xs={6}>
                  <SetListCard
                    setList={setList}
                    setSetList={s => setSetLists(x => x.map(l => (l.id === s.id ? s : l)))}
                    onEdit={() => {
                      setSetListToEdit(setList);
                      setModalOpen(true);
                    }}
                    setRefresh={setRefresh}
                  />
                </Grid>
              ))}
            </Grid>
            <Container
              style={{ marginTop: 20, width: '100%', justifyContent: 'center', display: 'flex', textAlign: 'center' }}>
              <Pagination
                count={totalPageNumber}
                page={currentPageNumber}
                onChange={(_, page) => setCurrentPageNumber(page)}
                size={isMobile ? 'small' : 'medium'}
              />
            </Container>
          </>
        )}
      </Card>
    </>
  );
};

export default SetListCards;
