import { SongViewModel } from '../../types/auto/types';

export const CalculateTransposition = (
  song: SongViewModel | undefined,
  isCapoRemoved: boolean,
  masterTranspose: number
): number => {
  let transposition = song?.storedTransposition || 0;

  if (isCapoRemoved && song?.capo) {
    transposition += song.capo;
  }

  return transposition + masterTranspose;
};
