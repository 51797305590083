import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import DownloadPdf, { waitTimeoutMs } from './DownloadPdf';
import { SetListViewModel } from '../../../../../types/auto/types';
import { colours, zIndex } from '../../../../Theme';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setList: SetListViewModel;
  totalPageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setShowAnnotations: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTranspositions: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadPdfModal = (props: Props): JSX.Element => {
  const [includeTranspositions, setIncludeTranspositions] = useState(true);
  const [includeAnnotations, setIncludeAnnotations] = useState(false);

  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open={props.open}
      onClose={() => !loading && props.setOpen(false)}
      style={{ zIndex: zIndex.fourthModal }}
      fullWidth
      maxWidth='sm'>
      <DialogContent>
        <Typography variant='h4'>Download Set List</Typography>
        <Typography style={{ marginTop: '20px' }}>Download your set list as a pdf</Typography>

        <div style={{ width: '100%', marginBottom: '20px', marginTop: '20px' }}>
          <FormControlLabel
            control={
              <Switch
                disabled={loading}
                checked={includeTranspositions}
                onClick={() => setIncludeTranspositions(x => !x)}
              />
            }
            label={`${includeTranspositions ? 'Show' : "Don't Show"} Transpositions`}
          />
          <FormControlLabel
            control={
              <Switch disabled={loading} checked={includeAnnotations} onClick={() => setIncludeAnnotations(x => !x)} />
            }
            label={`${includeAnnotations ? 'Show' : "Don't Show"} Annotations`}
          />
        </div>
        {loading && (
          <Typography style={{ marginTop: '20px', color: colours.textOrange }}>
            Generating your PDF. We expect this process to take around {(waitTimeoutMs / 1000) * props.totalPageNumber}{' '}
            seconds
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <LoadingButton
          disabled={loading}
          loading={loading}
          onClick={() => {
            setLoading(true);
            DownloadPdf(
              includeAnnotations,
              includeTranspositions,
              props.setList,
              props.totalPageNumber,
              props.setPageNumber,
              props.setShowAnnotations,
              props.setShowTranspositions,
              () => {
                setLoading(false);
                props.setOpen(false);
              }
            );
          }}>
          Download
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadPdfModal;
