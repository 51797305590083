import { Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Configuration } from '../../../Constants';
import NavBar from '../../../nav/NavBar';

interface Props {
  title?: string;
  children: JSX.Element;
  hideFooter?: boolean;
  showBackButton?: boolean;
}

const PageContainer = (props: Props): JSX.Element => {
  // Set the page title
  useEffect(() => {
    document.title = 'Sheet Music' + (props.title ? ' | ' + props.title : '');
  }, [props]);

  if (props.hideFooter) {
    return (
      <>
        <NavBar showBackButton={props.showBackButton} />
        <Container maxWidth='xl' sx={{ flexGrow: 1, padding: 0, height: '100%' }}>
          {props.children}
        </Container>
      </>
    );
  }

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <div style={{ paddingBottom: '3.3rem' }}>
        <NavBar />
        <Container maxWidth='xl' sx={{ flexGrow: 1, padding: 0, height: '100%' }}>
          {props.children}
        </Container>
      </div>
      <div style={{ position: 'absolute', bottom: 0, width: '100%', height: '3.3rem' }}>
        <Typography style={{ color: 'gray', width: '100%', textAlign: 'center' }}>
          Sheet Music - {Configuration.VERSION === undefined ? 'Development' : Configuration.VERSION}
        </Typography>
        <Container style={{ textAlign: 'center', width: '100%' }}>
          <a
            style={{
              color: 'gray',
              padding: 10,
              paddingTop: 0,
              cursor: 'pointer',
              display: 'inline',
              textAlign: 'center',
              textDecoration: 'none',
            }}
            href='/disclaimer'>
            Disclaimer
          </a>
          <a
            style={{
              color: 'gray',
              padding: 10,
              paddingTop: 0,
              cursor: 'pointer',
              display: 'inline',
              textAlign: 'center',
              textDecoration: 'none',
            }}
            href='/cookies'>
            Cookie Policy
          </a>
          <a
            style={{
              color: 'gray',
              padding: 10,
              paddingTop: 0,
              cursor: 'pointer',
              display: 'inline',
              textAlign: 'center',
              textDecoration: 'none',
            }}
            href='/privacy'>
            Privacy Policy
          </a>
          <a
            style={{
              color: 'gray',
              padding: 10,
              paddingTop: 0,
              cursor: 'pointer',
              display: 'inline',
              textAlign: 'center',
              textDecoration: 'none',
            }}
            href='/termsOfUse'>
            Terms Of Use
          </a>
        </Container>
      </div>
    </div>
  );
};

export default PageContainer;
