import { Menu, MenuItem, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SongClient, SongViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import WarningModal from '../elements/warning-modal/WarningModal';
import { Edit, Delete } from '@mui/icons-material';
import { SetSnackbarErrorContext } from '../elements/snackbar/SnackbarContext';

interface Props {
  song: SongViewModel;
  anchorElUser: HTMLElement | null;
  setAnchorElUser: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  onEdit?: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const iconStyle = { marginRight: 5, fontSize: 20 };

const SongCardMenu = (props: Props): JSX.Element => {
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  const handleCloseUserMenu = (): void => {
    props.setAnchorElUser(null);
  };

  return (
    <>
      <WarningModal
        open={warningModalOpen}
        setOpen={setWarningModalOpen}
        title={'Delete Song'}
        description={'This action cannot be undone! Are you sure you want to delete this song?'}
        actionButton='Delete'
        onAction={() =>
          new SongClient(Configuration.SERVER_ROOT, FetchOverride)
            .deleteSong(props.song.id)
            .then(() => {
              setWarningModalOpen(false);
              props.setRefresh(x => !x);
            })
            .catch(() => setSnackbarError('Could not delete song. Please try again later'))
        }
      />
      <Menu
        sx={{ mt: '45px' }}
        id='songCardMenu'
        anchorEl={props.anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(props.anchorElUser)}
        onClose={handleCloseUserMenu}>
        <MenuItem
          key='Edit'
          onClick={() => {
            props.onEdit && props.onEdit();
            handleCloseUserMenu();
          }}>
          <Edit style={iconStyle} />
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem
          key='Delete'
          onClick={() => {
            setWarningModalOpen(true);
            handleCloseUserMenu();
          }}>
          <Delete style={{ ...iconStyle, color: 'red' }} />
          <Typography sx={{ color: 'red' }}>Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SongCardMenu;
