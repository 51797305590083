import React, { useEffect, useRef } from 'react';
import { TextLayerItemInternal } from 'react-pdf';
import { transposeChord } from './transpose/PdfTransposableLayer';

interface Props {
  layer: TextLayerItemInternal;
  transposeAmount: number;
}

const PdfChordText = (props: Props): JSX.Element => {
  const ref = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current !== null && ref.current.parentElement !== null) {
      ref.current.parentElement.className = 'chordSpan';
    }
  }, [props.layer, ref]);

  const isHeader = props.layer.str.includes('[');
  const isHeaderWithClosingProp = props.layer.str.includes(']');

  return (
    <div
      ref={ref}
      style={{
        color: 'black',
        backgroundColor: isHeader ? 'rgb(230, 230, 230)' : 'white',
        fontWeight: 'bold',
        fontFamily: 'courier, monospace',
        padding: 1,
        paddingRight: 10,
      }}>
      {isHeader && '['}
      {transposeChord(props.layer.str.replace(/\[|\]|\,/g, ''), props.transposeAmount)}

      {isHeader && (isHeaderWithClosingProp ? ']' : ',')}
    </div>
  );
};

export default PdfChordText;
