import React, { useState } from 'react';
import { Save } from '@mui/icons-material';
import { CircularProgress, Container, IconButton, Popper, Tooltip } from '@mui/material';
import { SongClient, SongViewModel } from '../../../../../types/auto/types';
import { Configuration } from '../../../../Constants';
import { FetchOverride } from '../../../../utils/Requests';
import GroupedButtons from '../../grouped-buttons/GroupedButtons';
import { zIndex } from '../../../../Theme';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  capoAmount: number;
  setCapoAmount: (capo: number) => void;
  song?: SongViewModel;
  anchor: React.MutableRefObject<Element | undefined>;
}

const CapoModal = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchor.current}
      style={{ zIndex: zIndex.firstModal, backgroundColor: 'white', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}
      placement='top'>
      <Container style={{ padding: 5, margin: 0, display: 'inline-block' }}>
        <GroupedButtons
          counter={props.capoAmount}
          setCounter={value => props.setCapoAmount(value <= 0 ? 0 : value % 13)}
          title='Set Capo'
        />
        <Tooltip title='Save Capo Amount' placement='top' style={{ zIndex: zIndex.tooltip }}>
          <IconButton
            aria-label='Save Capo Amount'
            onClick={() => {
              setLoading(true);
              new SongClient(Configuration.SERVER_ROOT, FetchOverride)
                .setCapoAmount(props.song?.id, props.capoAmount)
                .catch(() => console.log('Could not save song capo amount'))
                .finally(() => setLoading(false));
            }}>
            {loading ? <CircularProgress size='20px' /> : <Save />}
          </IconButton>
        </Tooltip>
      </Container>
    </Popper>
  );
};

export default CapoModal;
