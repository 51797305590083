import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Layers,
  FullscreenExit,
  Fullscreen,
  ZoomOut,
  ZoomIn,
  Menu,
  Search,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import CapoDisplay from '../capo/CapoDisplay';
import PdfTransposeButton from '../transpose/PdfTransposeButton';
import { decreasePageCount, increasePageCount } from '../helpers/PageCountHelpers';
import { SetListViewModel, SongViewModel } from '../../../../../types/auto/types';
import { PdfDisplayType } from '../transpose/PdfTransposeModal';
import { isMobile } from 'react-device-detect';
import PlaybackButton from '../playback/PlaybackButton';
import debounce from 'lodash.debounce';
import SetListPopover from './SetListPopover';
import AdhocSongSearchPopover from './AdhocSongSearchPopover';
import { colours, zIndex } from '../../../../Theme';
import { SetShowNavbar } from '../../../../utils/ShowNavbarContext';
import MultiPeople from './MultiPeople';
import { SongIdToPageNumbers } from '../../../../../types/SongIdToPageNumbers';
import { ModalToDisplay } from '../PdfDisplayController';
import { GetPdfPreferences, PdfPreferences } from '../../../../utils/PDFReadingPreferences';

interface Props {
  currentPageNumber: number;
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
  totalPageCount: number;
  editing: boolean;
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTwoPages: React.Dispatch<React.SetStateAction<boolean>>;
  page1Song?: SongViewModel;
  setList?: SetListViewModel;
  setSetList: React.Dispatch<React.SetStateAction<SetListViewModel | undefined>>;
  setRerender: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAnnotations: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTranspositions: React.Dispatch<React.SetStateAction<boolean>>;
  pdfDisplayType: PdfDisplayType;
  modalOpen: ModalToDisplay;
  setModalOpen: (open: ModalToDisplay) => void;
  setSongToView: (song: SongViewModel) => void;
  songIdToPageNumbers: SongIdToPageNumbers;

  setZoom: (zoomIn: boolean) => void;
  isSharedSetListLink: boolean;

  masterTranspose: number;
  removeCapo: boolean;
  refreshPdf: () => void;
}

const PdfButtons = (props: Props): JSX.Element => {
  const keyboardShortcutInputRef = useRef<HTMLInputElement | null>(null);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [songSearchAnchor, setSongSearchAnchor] = useState<HTMLButtonElement | null>(null);
  const [sheetMusicUserPreferences, setSheetMusicUserPreferences] = useState<PdfPreferences>();

  const setShowNavBar = useContext(SetShowNavbar);

  useEffect(() => {
    setSheetMusicUserPreferences(GetPdfPreferences());
    window.addEventListener('keydown', e =>
      eventListener(
        e,
        props.setZoom,
        props.setCurrentPageNumber,
        props.totalPageCount,
        props.editing || popoverAnchor !== null || songSearchAnchor !== null
      )
    );
    return () => {
      window.removeEventListener('keydown', e =>
        eventListener(
          e,
          props.setZoom,
          props.setCurrentPageNumber,
          props.totalPageCount,
          props.editing || popoverAnchor !== null || songSearchAnchor !== null
        )
      );
    };
  }, [props, popoverAnchor, songSearchAnchor]);

  const eventListener = useMemo(
    () =>
      debounce(
        (
          event: KeyboardEvent,
          setZoom: (zoomIn: boolean) => void,
          setCurrentPageNumber: (value: React.SetStateAction<number>) => void,
          totalPageCount: number,
          pauseEventListener: boolean
        ): void => {
          // Quit the event listener if needed
          if (pauseEventListener) {
            return;
          }

          switch (event.key) {
            case 'ArrowRight':
            case 'ArrowDown':
            case 'Enter':
            case '.':
            case '>':
            case ' ':
              setCurrentPageNumber(x => increasePageCount(x, totalPageCount));
              break;
            case 'ArrowLeft':
            case 'ArrowUp':
            case ',':
            case '<':
            case 'Backspace':
              setCurrentPageNumber(decreasePageCount);
              break;
            case '=':
            case '+':
              setZoom(true);
              break;
            case '-':
            case '_':
              setZoom(false);
              break;
          }
        },
        100
      ),
    []
  );

  // Refocus on the input if editing has stopped
  useEffect(() => {
    if (!props.editing && !isMobile) {
      keyboardShortcutInputRef.current?.focus();
    } else if (!isMobile) {
      keyboardShortcutInputRef.current?.blur();
    }
  }, [props.editing, keyboardShortcutInputRef, isMobile]);
  return (
    <>
      {props.setList && (
        <>
          <SetListPopover
            open={popoverAnchor !== null}
            anchorEl={popoverAnchor}
            onClose={() => setPopoverAnchor(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            setList={props.setList}
            setSetList={props.setSetList}
            setRerender={props.setRerender}
            setSongToView={props.setSongToView}
            setPageNumber={props.setCurrentPageNumber}
            totalPageNumber={props.totalPageCount}
            setShowAnnotations={props.setShowAnnotations}
            setShowTranspositions={props.setShowTranspositions}
            refreshPdf={props.refreshPdf}
          />
          {!props.isSharedSetListLink && (
            <AdhocSongSearchPopover
              open={songSearchAnchor !== null}
              anchorEl={songSearchAnchor}
              onClose={() => setSongSearchAnchor(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            />
          )}
        </>
      )}
      {!props.removeCapo && (
        <CapoDisplay
          capoValue={props.page1Song?.capo || 0}
          setCapoValue={capo => {
            props.setSetList(x => {
              if (x === undefined) {
                return x;
              }
              x.songModels = x.songModels?.map(y => {
                if (y.id === props.page1Song?.id) {
                  y.capo = capo;
                }
                return y;
              });
              return x;
            });

            props.setRerender(z => !z);
          }}
          song={props.page1Song}
          open={props.modalOpen === ModalToDisplay.Capo}
          setOpen={o => {
            if (!props.isSharedSetListLink) {
              props.setModalOpen(o ? ModalToDisplay.Capo : ModalToDisplay.None);
            }
          }}
        />
      )}

      <PdfTransposeButton
        transposeAmount={props.page1Song?.storedTransposition || 0}
        setTransposeAmount={transposition => {
          props.setSetList(x => {
            if (x === undefined) {
              return x;
            }
            x.songModels = x.songModels?.map(y => {
              if (y.id === props.page1Song?.id) {
                y.storedTransposition = transposition || 0;
              }
              return y;
            });
            return x;
          });
          props.setRerender(x => !x);
        }}
        song={props.page1Song}
        setList={props.setList}
        pdfDisplayType={props.pdfDisplayType}
        open={props.modalOpen === ModalToDisplay.Transpose}
        setOpen={o => {
          if (!props.isSharedSetListLink) {
            props.setModalOpen(o ? ModalToDisplay.Transpose : ModalToDisplay.None);
          }
        }}
        isCapoRemoved={props.removeCapo}
        masterTranspose={props.masterTranspose}
      />
      <PlaybackButton
        songLink={props.page1Song?.link}
        open={props.modalOpen === ModalToDisplay.Playback}
        setOpen={o => props.setModalOpen(o ? ModalToDisplay.Playback : ModalToDisplay.None)}
      />
      <div style={{ position: 'absolute', bottom: 10, left: 20 }}>
        {props.setList && props.pdfDisplayType === PdfDisplayType.SetList && (
          <Tooltip title='Menu' style={{ zIndex: zIndex.tooltip }}>
            <span>
              <IconButton
                aria-label='menu'
                style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}
                onClick={e => setPopoverAnchor(e.currentTarget)}>
                <Menu />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {!props.isSharedSetListLink && (
          <Tooltip title='Adhoc Song Search' style={{ zIndex: zIndex.tooltip }}>
            <span>
              <IconButton
                aria-label='adhoc song search'
                style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}
                onClick={e => setSongSearchAnchor(e.currentTarget)}>
                <Search />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title='Previous Page' style={{ zIndex: zIndex.tooltip }}>
          <span>
            <IconButton
              aria-label='backward'
              style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}
              onClick={() => props.setCurrentPageNumber(decreasePageCount)}
              disabled={props.currentPageNumber - 1 < 1}>
              <ArrowBackIos />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Next Page' style={{ zIndex: zIndex.tooltip }}>
          <span>
            <IconButton
              aria-label='forward'
              style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}
              onClick={() => props.setCurrentPageNumber(x => increasePageCount(x, props.totalPageCount))}
              disabled={props.currentPageNumber + 1 > props.totalPageCount}>
              <ArrowForwardIos />
            </IconButton>
          </span>
        </Tooltip>
        <MultiPeople
          disabled={sheetMusicUserPreferences?.disableSetListProgressSharing === true}
          open={props.modalOpen === ModalToDisplay.Users}
          setOpen={o => props.setModalOpen(o ? ModalToDisplay.Users : ModalToDisplay.None)}
          disablePageNumberUpdates={props.modalOpen !== ModalToDisplay.None && props.modalOpen !== ModalToDisplay.Users} // If any modal apart from the users modal is open, prevent page changes
          setList={props.setList}
          currentPageNumber={props.currentPageNumber}
          onPageNumberChange={(pageNumber: number) => props.setCurrentPageNumber(pageNumber)}
          songIdToPageNumbers={props.songIdToPageNumbers}
        />
        {!isMobile && (
          <Tooltip title='Show Single / Multiple Pages' style={{ zIndex: zIndex.tooltip }}>
            <IconButton
              aria-label='togglePagesToShow'
              style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}
              onClick={() => props.setShowTwoPages(x => !x)}>
              <Layers />
            </IconButton>
          </Tooltip>
        )}
        {!isMobile && (
          <Tooltip title='Full Screen' style={{ zIndex: zIndex.tooltip }}>
            <IconButton
              aria-label='fullscreen'
              onClick={() =>
                props.setFullScreen(x => {
                  setShowNavBar(x);
                  return !x;
                })
              }
              style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}>
              {props.fullScreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title='Zoom Out' style={{ zIndex: zIndex.tooltip }}>
          <IconButton
            aria-label='zoom out'
            onClick={() => props.setZoom(false)}
            style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}>
            <ZoomOut />
          </IconButton>
        </Tooltip>
        <Tooltip title='Zoom In' style={{ zIndex: zIndex.tooltip }}>
          <IconButton
            aria-label='zoom in'
            onClick={() => props.setZoom(true)}
            style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}>
            <ZoomIn />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export default PdfButtons;
