import { SongIdToPageNumbers } from '../../../../../types/SongIdToPageNumbers';

export const GetSongIdForPageNumber = (
  songIdToPageNumbers: SongIdToPageNumbers,
  pageNumber: number
): { songId: number; pageNumberOfSong: number } | undefined => {
  // Get the song by the page number
  const songs = Object.entries(songIdToPageNumbers).filter(y => y[1].includes(pageNumber));

  if (songs.length === 0) {
    return;
  }

  return {
    songId: parseInt(songs[0][0] as string),
    pageNumberOfSong: songs[0][1].indexOf(pageNumber) + 1,
  };
};
