import { Tooltip } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { zIndex } from '../../../Theme';

interface Props {
  message: string;
}

const HelpIcon = (props: Props): JSX.Element => (
  <Tooltip title={props.message} PopperProps={{ style: { zIndex: zIndex.tooltip } }}>
    <InfoOutlinedIcon style={{ fontSize: '13px', color: 'grey', marginRight: '5px' }} />
  </Tooltip>
);

export default HelpIcon;
