import { CircularProgress, Tooltip } from '@mui/material';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';

interface Props {
  placeholder?: string;
  searchTerm: string;
  onSearchChange: (value: string) => void;
  loading?: boolean;
}

const SearchBar = (props: Props): JSX.Element => (
  <div style={{ position: 'relative' }}>
    <DebounceInput
      minLength={0}
      debounceTimeout={700}
      value={props.searchTerm}
      onChange={event => props.onSearchChange(event.target.value)}
      style={{
        width: '98%',
        fontSize: 20,
        border: 'none',
        borderBottom: '2px gray solid',
        padding: 10,
        outline: 'none',
      }}
      placeholder={props.placeholder || 'Search'}
    />
    <div style={{ position: 'absolute', right: 20, top: '50%', display: props.loading ? 'block' : 'none' }}>
      <Tooltip title='Loading Search Results'>
        <CircularProgress size='1.2rem' />
      </Tooltip>
    </div>
  </div>
);

export default SearchBar;
