import React, { useEffect, useMemo, useState } from 'react';
import { Close } from '@mui/icons-material';
import { CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { BsYoutube } from 'react-icons/bs';
import AudioDisplay, { SongMetaData } from './AudioDisplay';
import { GetYouTubeDetails } from './AudioSearchHelper';

interface Props {
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const AudioSearch = (props: Props): JSX.Element => {
  const [platform, setPlatform] = useState(Platform.None);
  const [url, setUrl] = useState(props.link);
  const [songMetaData, setSongMetaData] = useState<SongMetaData>();

  const debouncedResults = useMemo(
    () =>
      debounce((value: string) => {
        setSongMetaData(undefined);
        if (value === '' || value === undefined || value === null) {
          setPlatform(Platform.None);
          props.setError(false);
        } else if (value.toLocaleLowerCase().includes('youtube') || value.toLocaleLowerCase().includes('youtu.be')) {
          setPlatform(Platform.Loading);
          GetYouTubeDetails(value).then(x => {
            setPlatform(x.platform);
            setSongMetaData(x.songMetaData);

            props.setError(x.platform === Platform.Invalid);
          });
        } else {
          setPlatform(Platform.Invalid);
          props.setError(true);
        }

        props.setLink(value);
      }, 1000),
    []
  );

  useEffect(() => debouncedResults(props.link), [props.link]);

  useEffect(() => () => debouncedResults.cancel());

  useEffect(() => {
    setUrl(props.link);
  }, [props.link]);

  useEffect(() => {
    debouncedResults(url);
  }, [url, setPlatform]);

  return (
    <>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              {platform === Platform.YouTube ? (
                <BsYoutube color='#FF0000' />
              ) : platform === Platform.Invalid ? (
                <Close style={{ color: 'red' }} />
              ) : platform === Platform.Loading ? (
                <CircularProgress size='15px' />
              ) : (
                <></>
              )}
            </InputAdornment>
          ),
        }}
        margin='dense'
        id='link'
        label='YouTube Audio Link'
        type='string'
        fullWidth
        style={{ width: '98%', display: 'inline-block' }}
        variant='standard'
        value={url}
        onChange={e => {
          setUrl(e.target.value);
        }}
      />
      <AudioDisplay songMetaData={songMetaData} />
      {platform === Platform.Invalid && (
        <Typography style={{ color: 'red' }}>
          The supplied URL is invalid. Please enter a working YouTube link
        </Typography>
      )}
    </>
  );
};

export enum Platform {
  None,
  YouTube,
  Loading,
  Invalid,
}

export default AudioSearch;
