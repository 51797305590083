export enum AnnotationType {
  Text,
  Pen,
  Eraser,
}

export interface AnnotationSettings {
  annotationType: AnnotationType;
  isEditing: boolean;
  colour: string;
  size: number;
  clearAll: boolean;
}
