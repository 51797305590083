import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SetListClient, SetListViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import ChipList, { ChipOptions } from '../elements/chip-list/ChipList';
import { SetSnackbarErrorContext, SetSnackbarSuccessContext } from '../elements/snackbar/SnackbarContext';
import { zIndex } from '../../Theme';

interface Props {
  setList: SetListViewModel;
  setSetList: (setList: SetListViewModel) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShareModal = (props: Props): JSX.Element => {
  const [sharedUsers, setSharedUsers] = useState<ChipOptions[]>([]);
  const [email, setEmail] = useState('');
  const [, setRerender] = useState(false);

  const [loading, setLoading] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  // Set the users that currently have been shared with
  useEffect(() => {
    setSharedUsers(
      (props.setList.sharedUsers?.map(x => ({ id: x.id, name: x.firstName + ' ' + x.lastName })) || []) as ChipOptions[]
    );
  }, [props, setSharedUsers]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      fullWidth
      maxWidth='xl'
      style={{ zIndex: zIndex.thirdModal }}>
      <DialogContent>
        <Typography variant='h4'>Share Set List</Typography>
        <ChipList
          label='Shared Users'
          chipValues={sharedUsers}
          setChipValues={setSharedUsers}
          onRemoveValue={removeValue => {
            new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
              .removeUserFromSharedSetList(props.setList.id, removeValue.id)
              .then(() => setSnackbarSuccess('User removed from set list'))
              .catch(() => setSnackbarError('User could not be removed from the set list'));
          }}
        />
        <Typography variant='h6' style={{ marginTop: '40px' }}>
          Invite Users By Email
        </Typography>
        <TextField
          label='Email of User To Invite'
          value={email}
          onChange={e => setEmail(e.target.value)}
          type='email'
          style={{ width: '50%' }}
        />
        <LoadingButton
          loading={loading}
          style={{ marginLeft: '15px', marginTop: '10px' }}
          onClick={() => {
            setLoading(true);
            new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
              .inviteUserToSetList(props.setList.id, email)
              .then(() => {
                setLoading(false);
                setSnackbarSuccess("User invited! They'll appear on your shared list once they accept their invite");
              })
              .catch(() => {
                setLoading(false);
                setSnackbarError('User could not be invited');
              });
          }}>
          Send Invite To User
        </LoadingButton>

        <Typography variant='h6' style={{ marginTop: '40px' }}>
          Public Set List Link
        </Typography>
        <div style={{ width: '100%', marginBottom: '20px' }}>
          <FormControlLabel
            control={
              <Switch
                checked={props.setList.shareLink !== undefined && props.setList.shareLink !== null}
                onClick={() => {
                  new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                    .toggleSetListLinkSharing(props.setList.id)
                    .then(x => {
                      const list = props.setList;
                      list.shareLink = x === null ? undefined : x;
                      props.setSetList(list);
                      setRerender(y => !y);

                      setSnackbarSuccess(
                        x ? 'Link sharing enabled for the set list' : 'Link sharing disabled for the set list'
                      );
                    })
                    .catch(() => {
                      setSnackbarError('Could not toggle sharing link for this set list. Please try again later');
                    });
                }}
              />
            }
            label={`Public Sharing Link ${props.setList.shareLink ? 'Enabled' : 'Disabled'} `}
          />
        </div>
        {props.setList.shareLink && (
          <>
            <TextField
              label='Public Link'
              value={Configuration.SERVER_ROOT + '/sharedSetList?id=' + props.setList.shareLink}
              style={{ width: '50%' }}
            />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  Configuration.SERVER_ROOT + '/sharedSetList?id=' + props.setList.shareLink
                );
              }}
              style={{ marginLeft: '15px', marginTop: '10px' }}>
              Copy Link
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => props.setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
