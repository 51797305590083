import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { zIndex } from '../../../Theme';
import { GetKeyForTransposition } from '../../../utils/KeyChart';

interface Props {
  songKey: string | undefined;
  songName: string | undefined;
  marginTop?: string;
  transposeAmount?: number;
}

const SongKeyChip = ({ songKey, marginTop, songName, transposeAmount }: Props): JSX.Element => {
  if (!songKey) {
    return <></>;
  }

  const transposedKey = GetKeyForTransposition(songKey, transposeAmount || 0);

  return (
    <div style={{ display: 'inline-block', marginLeft: 5 }}>
      <Tooltip
        title={`${songName} in the key of ${transposedKey}${
          transposeAmount
            ? '. The song was originally in the key of ' + songKey + ' but has been transposed by ' + transposeAmount
            : ''
        }`}
        style={{ marginTop: marginTop, zIndex: zIndex.tooltip }}>
        <Chip label={transposedKey} size='small' />
      </Tooltip>
    </div>
  );
};

export default SongKeyChip;
