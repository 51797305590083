import React, { useContext, useEffect, useRef, useState } from 'react';
import { Group } from '@mui/icons-material';
import { Avatar, Badge, Container, IconButton, Popper, Tooltip, Typography } from '@mui/material';
import { SetListViewModel } from '../../../../../types/auto/types';
import { colours, zIndex } from '../../../../Theme';
import { SetListProgress, SetListProgressWS } from '../../../../utils/websocket/SetListProgressWSHandler';
import { UserContext } from '../../../../utils/UserStorage';
import { SongIdToPageNumbers } from '../../../../../types/SongIdToPageNumbers';
import { GetSongIdForPageNumber } from '../helpers/GetSongIdForPageNumber';

interface Props {
  setList: SetListViewModel | undefined;
  disabled?: boolean;
  disablePageNumberUpdates: boolean;

  // Websocket support for set lists
  currentPageNumber: number;
  onPageNumberChange: (pageNumber: number) => void;
  songIdToPageNumbers: SongIdToPageNumbers;

  open: boolean;
  setOpen: (open: boolean) => void;
}

const MultiPeople = ({
  setList,
  currentPageNumber,
  onPageNumberChange,
  disabled,
  disablePageNumberUpdates,
  songIdToPageNumbers,
  open,
  setOpen,
}: Props): JSX.Element => {
  // Do absolutely nothing if disabled
  if (disabled) {
    return <></>;
  }

  const user = useContext(UserContext);
  const buttonRef = useRef<Element>();
  const [webSocket, setWebSocket] = useState<SetListProgressWS>();
  const [setListProgress, setSetListProgress] = useState<SetListProgress[]>([]);
  const [progressUpdated, setProgressUpdated] = useState(false);
  const [followingUserId, setFollowingUserId] = useState<number>();

  // When current page number changes, update the web socket
  useEffect(() => {
    webSocket?.updatePageNumber(currentPageNumber);
  }, [currentPageNumber]);

  useEffect(() => {
    if (setList?.id) {
      const userName = user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : undefined;
      const newWs = new SetListProgressWS(userName, setList?.id, (progress: SetListProgress[]) => {
        setSetListProgress(progress);
        setProgressUpdated(x => !x);
      });

      setWebSocket(newWs);

      document.addEventListener('beforeunload', () => {
        webSocket?.closeConnection();
        setSetListProgress([]);
      });

      return () =>
        document.removeEventListener('beforeunload', () => {
          webSocket?.closeConnection();
          setSetListProgress([]);
        });
    }
  }, [setList?.id]);

  // Listen for when the progress has been updated
  useEffect(() => {
    if (followingUserId && !disablePageNumberUpdates) {
      // Get the user we're following if they just changed the page
      const followingUserPageNumber = setListProgress.filter(x => x.UserId === followingUserId && x.LastUpdated);

      if (followingUserPageNumber && followingUserPageNumber.length > 0) {
        onPageNumberChange(followingUserPageNumber[0].PageNumber);
      }
    }
  }, [progressUpdated, followingUserId, disablePageNumberUpdates]);

  // If disabled or there isn't anyone else on the set list, don't show
  if (setListProgress.length < 2) {
    return <></>;
  }

  return (
    <>
      <Popper
        open={open}
        anchorEl={buttonRef.current}
        style={{ zIndex: zIndex.firstModal, backgroundColor: 'white', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}
        placement='top'>
        <Container style={{ padding: 15, margin: 0, display: 'inline-block' }}>
          <Typography variant='h6' style={{ paddingLeft: '5px' }}>
            Set List Users
          </Typography>
          {setListProgress.map((x, i) => {
            if (x.UserId === webSocket?.userId) {
              return <div key={'HIDDEN_USER' + i}></div>;
            }

            const pageDetail = GetSongIdForPageNumber(songIdToPageNumbers, x.PageNumber);

            const song = setList?.songModels?.filter(x => x.id === pageDetail?.songId) || [];

            const following = x.UserId === followingUserId;

            return (
              <div key={'Multi-People' + i}>
                <Tooltip title={following ? 'Unfollow ' + x.UserName : 'Follow ' + x.UserName}>
                  <Container
                    style={{
                      padding: 0,
                      margin: '6px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setFollowingUserId(followingUserId ? undefined : x.UserId);
                      if (!followingUserId) {
                        onPageNumberChange(x.PageNumber);
                      }
                    }}>
                    <Avatar sx={{ bgcolor: userIconColours[i], width: 30, height: 30, fontSize: '20px' }}>
                      {x.UserName.replace('(Guest)', '')
                        .match(/\b(\w)/g)
                        ?.join('')
                        .toUpperCase()
                        .slice(0, 2)}
                    </Avatar>
                    <Container style={{ padding: 0 }}>
                      <Typography
                        fontWeight='bold'
                        style={{
                          fontSize: '16px',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          flexWrap: 'nowrap',
                          whiteSpace: 'nowrap',
                        }}>
                        {x.UserName}
                      </Typography>
                      <Typography
                        color={following ? 'darkorange' : 'GrayText'}
                        style={{
                          fontSize: '14px',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          flexWrap: 'nowrap',
                          whiteSpace: 'nowrap',
                        }}>
                        {following
                          ? 'Following'
                          : 'Viewing: ' + song && song.length > 0
                          ? song[0].name + ' (Page ' + pageDetail?.pageNumberOfSong + ')'
                          : 'Page ' + x.PageNumber}
                      </Typography>
                    </Container>
                  </Container>
                </Tooltip>
              </div>
            );
          })}
        </Container>
      </Popper>
      <Tooltip title='View Set List Users' style={{ zIndex: zIndex.tooltip }} ref={buttonRef}>
        <IconButton
          aria-label='people'
          onClick={() => setOpen(!open)}
          style={{ zIndex: zIndex.pdfIconOverlay, backgroundColor: colours.backgroundWhite }}>
          <Badge
            badgeContent={setListProgress.length}
            color='default'
            showZero
            max={9}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Group />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default MultiPeople;

const userIconColours = [
  '#FF6633',
  '#3366E6',
  '#00B3E6',
  '#E6B333',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#FF33FF',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#FFB399',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#FFFF99',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
