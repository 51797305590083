import React from 'react';
import { FaEraser, FaKeyboard, FaPen, FaTrash } from 'react-icons/fa';
import { Container, IconButton, Popper, Tooltip } from '@mui/material';
import ColourPicker from '../elements/colour-picker/ColourPicker';
import { colours, zIndex } from '../../Theme';
import { AnnotationSettings, AnnotationType } from '../../../types/AnnotationSettings';
import GroupedButtons from '../elements/grouped-buttons/GroupedButtons';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  annotationSettings: AnnotationSettings;
  setAnnotationSettings: React.Dispatch<React.SetStateAction<AnnotationSettings>>;
  anchor: React.MutableRefObject<Element | undefined>;
}

const AnnotationModal = (props: Props): JSX.Element => (
  <Popper
    open={props.open}
    anchorEl={props.anchor.current}
    style={{ zIndex: zIndex.firstModal, backgroundColor: 'white', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}
    placement='top'>
    <Container style={{ padding: 5, margin: 0, display: 'inline-block' }}>
      <Tooltip title='Text' placement='top' style={{ zIndex: zIndex.tooltip }}>
        <IconButton
          style={{
            backgroundColor:
              props.annotationSettings.annotationType === AnnotationType.Text ? colours.selectedGray : undefined,
          }}
          aria-label='Keyboard'
          onClick={() =>
            props.setAnnotationSettings(x => ({ ...x, annotationType: AnnotationType.Text, clearAll: false }))
          }>
          <FaKeyboard size={20} />
        </IconButton>
      </Tooltip>
      <Tooltip title='Pen' placement='top' style={{ zIndex: zIndex.tooltip }}>
        <IconButton
          style={{
            backgroundColor:
              props.annotationSettings.annotationType === AnnotationType.Pen ? colours.selectedGray : undefined,
          }}
          aria-label='Pen'
          onClick={() =>
            props.setAnnotationSettings(x => ({ ...x, annotationType: AnnotationType.Pen, clearAll: false }))
          }>
          <FaPen size={20} />
        </IconButton>
      </Tooltip>
      <Tooltip title='Eraser' placement='top' style={{ zIndex: zIndex.tooltip }}>
        <IconButton
          style={{
            backgroundColor:
              props.annotationSettings.annotationType === AnnotationType.Eraser ? colours.selectedGray : undefined,
          }}
          aria-label='Eraser'
          onClick={() =>
            props.setAnnotationSettings(x => ({ ...x, annotationType: AnnotationType.Eraser, clearAll: false }))
          }>
          <FaEraser size={20} />
        </IconButton>
      </Tooltip>
      <ColourPicker
        colour={props.annotationSettings.colour}
        setColour={colour => props.setAnnotationSettings(x => ({ ...x, colour: colour, clearAll: false }))}
        size='25px'
        marginLeft={20}
        marginRight={20}
      />
      <GroupedButtons
        counter={props.annotationSettings.size}
        setCounter={value =>
          props.setAnnotationSettings(x => ({ ...x, clearAll: false, size: value < 1 ? 1 : value > 20 ? 20 : value }))
        }
        title='Pen Size'
      />
      <Tooltip title='Clear All Annotations' placement='top' style={{ zIndex: zIndex.tooltip }}>
        <IconButton
          style={{ color: 'gray' }}
          onClick={() => props.setAnnotationSettings(x => ({ ...x, clearAll: true }))}
          disabled={props.annotationSettings.clearAll}
          aria-label='Clear All Annotations'>
          <FaTrash size={20} />
        </IconButton>
      </Tooltip>
    </Container>
  </Popper>
);

export default AnnotationModal;
