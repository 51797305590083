import React, { useEffect, useRef, useState } from 'react';
import { Document } from 'react-pdf';
import PdfDisplayAnnotation from './PdfDisplayAnnotation';
import { AnnotationViewModel, SetListViewModel, SongViewModel } from '../../../../types/auto/types';
import PdfButtons from './pdf-controls/PdfButtons';
import { SongIdToPageNumbers } from '../../../../types/SongIdToPageNumbers';
import { GetSongIdForPageNumber } from './helpers/GetSongIdForPageNumber';
import { decreasePageCount, increasePageCount } from './helpers/PageCountHelpers';
import { isMobile } from 'react-device-detect';
import { PdfDisplayType } from './transpose/PdfTransposeModal';
import { PdfPreferences } from '../../../utils/PDFReadingPreferences';

interface Props {
  setList: SetListViewModel | undefined;
  setSetList: React.Dispatch<React.SetStateAction<SetListViewModel | undefined>>;
  songIdToPageNumbers: SongIdToPageNumbers;
  pdfData: ArrayBuffer | undefined;
  annotations: AnnotationViewModel[];
  setAnnotations: React.Dispatch<React.SetStateAction<AnnotationViewModel[]>>;
  pdfDisplayType: PdfDisplayType;
  isSharedSetListLink: boolean;

  pdfDisplayPreferences?: PdfPreferences;
  pdfPreferencesUpdated: boolean;
  refreshPdf: () => void;
}

const ZOOM_CHANGE_LEVEL = 0.1;

const PdfDisplayController = (props: Props): JSX.Element => {
  // Song state
  const [page1Song, setPage1Song] = useState<SongViewModel>();
  const [page2Song, setPage2Song] = useState<SongViewModel>();

  // Page number state
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [showTwoPages, setShowTwoPages] = useState(!isMobile);
  const [fullScreen, setFullScreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [rerender, setRerender] = useState(false);

  // Annotation state
  const [editing, setEditing] = useState(false);
  const [modalToDisplay, setModalToDisplay] = useState(ModalToDisplay.None);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [showTranspositions, setShowTranspositions] = useState(true);

  // Transpose / Capo Overrides
  const [removeCapo, setRemoveCapo] = useState(
    props.pdfDisplayPreferences?.hideCapo === undefined
      ? props.isSharedSetListLink
      : props.pdfDisplayPreferences.hideCapo
  );
  const [masterTranspose, setMasterTranspose] = useState(0);

  // Reference of the internal document holder
  const documentReference = useRef<Document | null>(null);

  // On page change, get the transpose information
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const song1Details = GetSongIdForPageNumber(props.songIdToPageNumbers, currentPageNumber);

    if (song1Details === undefined || song1Details.songId === undefined) {
      return;
    }

    // Deal with page 2
    let song2Details:
      | {
          songId: number;
          pageNumberOfSong: number;
        }
      | undefined = undefined;

    if (showTwoPages) {
      song2Details = GetSongIdForPageNumber(props.songIdToPageNumbers, currentPageNumber + 1);
    }

    // Set the page 1 song if applicable
    const newPage1Song = props.setList?.songModels?.filter(x => x.id === song1Details.songId);
    if (newPage1Song === undefined || newPage1Song.length === 0) {
      return;
    }

    // If the song id has changed, or there has been a transposition change (from the transposition button), or the capo has changed
    if (
      (song1Details.songId !== page1Song?.id ||
        newPage1Song[0].storedTransposition !== page1Song?.storedTransposition ||
        newPage1Song[0].capo !== page1Song?.capo) &&
      song1Details.songId > 0
    ) {
      setPage1Song(newPage1Song[0]);

      // Set the page 2 song if applicable
      if (showTwoPages && song2Details !== undefined && song1Details.songId === song2Details.songId) {
        setPage2Song(newPage1Song[0]);
      } else if (showTwoPages && song2Details !== undefined && page2Song?.id !== song2Details.songId) {
        const newPage2Song = props.setList?.songModels?.filter(x => x.id === song2Details?.songId);
        if (newPage2Song === undefined || newPage2Song.length === 0) {
          return;
        }
        setPage2Song(newPage2Song[0]);
      }
    }
  }, [currentPageNumber, props.songIdToPageNumbers, showTwoPages, setPage1Song, setPage2Song, props.setList, rerender]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const idFromParam = queryParameters.get('id');

    if (
      idFromParam &&
      props.pdfDisplayPreferences?.setListToMasterTranspose &&
      props.pdfDisplayPreferences.setListToMasterTranspose[idFromParam]
    ) {
      setMasterTranspose(props.pdfDisplayPreferences.setListToMasterTranspose[idFromParam]);
    } else {
      setMasterTranspose(0);
    }

    if (props.pdfDisplayPreferences) {
      setRemoveCapo(props.pdfDisplayPreferences.hideCapo || false);
    }
  }, [props.pdfDisplayPreferences, props.pdfPreferencesUpdated]);

  return (
    <>
      <PdfDisplayAnnotation
        currentPageNumber={currentPageNumber}
        totalPageNumber={totalPageNumber}
        setTotalPageNumber={setTotalPageNumber}
        showTwoPages={showTwoPages}
        isSharedSetList={props.isSharedSetListLink}
        fullScreen={fullScreen}
        showAnnotations={showAnnotations}
        showTranspositions={showTranspositions}
        isEditing={modalToDisplay === ModalToDisplay.Annotation}
        setIsEditing={isEditing => {
          setEditing(isEditing);
          setModalToDisplay(isEditing ? ModalToDisplay.Annotation : ModalToDisplay.None);
        }}
        prevPage={() => setCurrentPageNumber(decreasePageCount)}
        nextPage={() => setCurrentPageNumber(x => increasePageCount(x, totalPageNumber))}
        page1Song={page1Song}
        page2Song={page2Song}
        pdfData={props.pdfData}
        songIdToPageNumbers={props.songIdToPageNumbers}
        setList={props.setList}
        annotations={props.annotations}
        setAnnotations={props.setAnnotations}
        zoomLevel={zoomLevel}
        documentRef={documentReference}
        isCapoRemoved={removeCapo}
        masterTranspose={masterTranspose}
      />
      <PdfButtons
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        totalPageCount={totalPageNumber}
        editing={editing}
        isSharedSetListLink={props.isSharedSetListLink}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        setShowTwoPages={setShowTwoPages}
        page1Song={page1Song}
        setList={props.setList}
        setSetList={props.setSetList}
        setShowAnnotations={setShowAnnotations}
        setShowTranspositions={setShowTranspositions}
        setRerender={setRerender}
        masterTranspose={masterTranspose}
        removeCapo={removeCapo}
        pdfDisplayType={props.pdfDisplayType}
        modalOpen={modalToDisplay}
        setModalOpen={open => setModalToDisplay(open)}
        setZoom={zoomIn => setZoomLevel(x => (zoomIn ? x + ZOOM_CHANGE_LEVEL : x - ZOOM_CHANGE_LEVEL))}
        refreshPdf={props.refreshPdf}
        songIdToPageNumbers={props.songIdToPageNumbers}
        setSongToView={song => {
          const songPages = Object.values(props.songIdToPageNumbers).filter(x => x[0] === song.id);

          // Make sure the pages exist
          if (songPages.length > 0 && songPages[0].length > 1 && songPages[0][1].length > 0) {
            setCurrentPageNumber(songPages[0][1][0]);
            setRerender(x => !x);
          } else {
            // Otherwise the song list could be in another format - this is the one that actually works from testing - otherone left in for legacy
            const pages = props.songIdToPageNumbers[song.id];

            if (pages !== undefined && pages.length > 0) {
              setCurrentPageNumber(pages[0]);
              setRerender(x => !x);
            }
          }
        }}
      />
    </>
  );
};

export default PdfDisplayController;

export enum ModalToDisplay {
  None,
  Annotation,
  Transpose,
  Capo,
  Playback,
  Users,
}
