export const getCookie = (cname: string, cookie: string): string => {
  const name = cname + '=';
  const ca = decodeURIComponent(cookie).split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const FetchOverride = {
  fetch(url: RequestInfo, init?: RequestInit, attempts = 3): Promise<Response> {
    const controller = new AbortController();

    // Add a 10 second timeout (60s for PDF retrieval) to requests - if it hasn't loaded after that time, the request was probably lost
    const timeoutTime = url.toString().includes('pdf') ? 60000 : 10000;
    const timeoutId = setTimeout(() => controller.abort(), timeoutTime);

    if (init !== undefined) {
      init.headers = {
        ...init.headers,
        credentials: 'include',
        redirect: 'manual',
      };

      // Add a timeout controller - if too long pases after the request has been made, timeout
      init.signal = controller.signal;
    }

    return fetch(url, init)
      .then(x => {
        // Clear the timeout, so we don't interrupt response processing
        clearTimeout(timeoutId);

        return x;
      })
      .catch(e => {
        // If the error code is 20 (from the Abort Controller) and we haven't used all of our attempts, re-try fetching, else return the error
        if (e?.code !== 20 || attempts === 0) {
          return e;
        }

        return FetchOverride.fetch(url, init, attempts - 1);
      });
  },
};

interface FetchOverrideType {
  fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
}

export const FileUploadFetchOverride = (fileToUpload: string | ArrayBuffer | null): FetchOverrideType => ({
  fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init !== undefined) {
      init.headers = {
        ...init.headers,
        Accept: 'application/json',
        'Accept-Charset': 'cp1252',
      };
      init.body = fileToUpload;
      init.method = 'POST';
    }
    return fetch(url, init);
  },
});
