import React, { useRef } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CapoModal from './CapoModal';
import { SongViewModel } from '../../../../../types/auto/types';
import {
  GetAdjustableMobileEditButtonBottom,
  GetAdjustableMobileEditButtonRight,
} from '../../../../utils/MobileViewChanges';
import { colours, zIndex } from '../../../../Theme';

interface Props {
  capoValue?: number;
  setCapoValue: (capo: number) => void;
  song?: SongViewModel;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PdfTransposeButton = (props: Props): JSX.Element => {
  const capoButtonRef = useRef<Element>();

  return (
    <>
      <CapoModal
        open={props.open}
        setOpen={props.setOpen}
        capoAmount={props.capoValue || 0}
        setCapoAmount={props.setCapoValue}
        song={props.song}
        anchor={capoButtonRef}
      />
      <Tooltip
        title='Capo'
        style={{
          zIndex: zIndex.tooltip,
        }}
        ref={capoButtonRef}>
        <IconButton
          aria-label='Capo'
          onClick={() => props.setOpen(!props.open)}
          style={{
            zIndex: zIndex.pdfIconOverlay,
            position: 'absolute',
            bottom: GetAdjustableMobileEditButtonBottom('capo'),
            right: GetAdjustableMobileEditButtonRight('capo'),
            backgroundColor: colours.backgroundWhite,
          }}>
          <HorizontalRuleIcon />
          <Typography style={{ position: 'absolute', fontSize: 10, textAlign: 'center' }}>
            {props.capoValue === 0 || props.capoValue === undefined || props.capoValue === null ? (
              <>
                No
                <br />
                Capo
              </>
            ) : props.capoValue === 1 ? (
              <>
                1st
                <br />
                Fret
              </>
            ) : props.capoValue === 2 ? (
              <>
                2nd
                <br />
                Fret
              </>
            ) : props.capoValue === 3 ? (
              <>
                3rd
                <br />
                Fret
              </>
            ) : (
              <>
                {props.capoValue}th
                <br />
                Fret
              </>
            )}
          </Typography>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PdfTransposeButton;
