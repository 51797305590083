import React, { useState } from 'react';
import { ButtonBase, Card, Container, IconButton, Typography } from '@mui/material';
import { colours, theme } from '../../Theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';
import moment from 'moment';
import { SetListViewModel } from '../../../types/auto/types';
import SetListCardMenu from './SetListCardMenu';

interface Props {
  setList: SetListViewModel;
  setSetList: (setList: SetListViewModel) => void;
  onEdit?: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetListCard = (props: Props): JSX.Element => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };
  return (
    <Card
      variant='outlined'
      sx={{
        position: 'relative',
        height: '100%',
      }}>
      <ButtonBase
        sx={{
          padding: 2,
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block',
          textAlign: 'left',
          width: '100%',
          height: '100%',
          '&:hover': { backgroundColor: theme.palette.background.paper, transition: 'background-color 500ms' },
        }}
        onClick={() => (window.location.href = '/setList?id=' + props.setList.id)}>
        <Container style={{ padding: 0, margin: 0, width: '90%', height: '100%' }}>
          <Typography
            variant='h6'
            sx={{
              display: 'inline-block',
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {props.setList.name}
          </Typography>
          <Typography
            sx={{
              color: colours.textGray,
              display: 'inline-block',
              marginLeft: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            by {props.setList.user?.firstName + ' ' + props.setList.user?.lastName}
          </Typography>
          <Typography sx={{ color: colours.textGray, fontSize: 13 }}>
            Created {moment(props.setList.created).format('DD/MM/YYYY')}
          </Typography>
          {props.setList.pinned && (
            <Typography sx={{ color: colours.textOrange, fontSize: 13, display: 'flex', gap: 0.7 }}>
              <PushPinIcon sx={{ fontSize: 13, paddingTop: '4px' }} />
              Pinned
            </Typography>
          )}
        </Container>
      </ButtonBase>
      <IconButton
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          handleOpenUserMenu(event);
        }}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
        }}>
        <MoreVertIcon />
      </IconButton>
      <SetListCardMenu
        setList={props.setList}
        setSetList={props.setSetList}
        anchorElUser={anchorElUser}
        setAnchorElUser={setAnchorElUser}
        onEdit={props.onEdit}
        setRefresh={props.setRefresh}
      />
    </Card>
  );
};

export default SetListCard;
