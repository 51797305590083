import React, { useContext, useState } from 'react';
import { Box, Container, Grid, Link, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UserClient } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import { useNavigate } from 'react-router-dom';
import Logo from '../../images/Logo.png';
import { SetSnackbarErrorContext, SetSnackbarSuccessContext } from '../../components/elements/snackbar/SnackbarContext';
import PasswordChecklist from 'react-password-checklist';

const ResetPassword = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsValid, setPasswordsValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  const resetPassword = (): void => {
    setLoading(true);

    const token = new URLSearchParams(window.location.search).get('token');
    if (token === undefined || token === null || token === '') {
      navigate('/');
      return;
    }

    if (!passwordsValid) {
      setSnackbarError('Please check password validity');
      setLoading(false);
      return;
    }

    new UserClient(Configuration.SERVER_ROOT, FetchOverride)
      .resetPassword(password, parseInt(token))
      .then(() => {
        setLoading(false);
        setSnackbarSuccess('Password reset. Please login with your new credentials');
        navigate('/');
      })
      .catch(() => {
        setLoading(false);
        setSnackbarError('Could not reset password. Please try again');
      });
  };

  return (
    <Container maxWidth='xs' sx={{ padding: 10, textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={Logo} alt='Logo' style={{ width: 50, marginBottom: 10 }} />
        <Typography component='h1' variant='h5'>
          Reset Password
        </Typography>
        <Box component='form' noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='password'
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Confirm Password'
            type='password'
            id='password'
            autoComplete='password'
            value={confirmPassword}
            onChange={event => setConfirmPassword(event.target.value)}
            onKeyDown={event => event.key === 'Enter' && resetPassword()}
          />
          <PasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={isValid => setPasswordsValue(isValid)}
          />
          <LoadingButton loading={loading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={resetPassword}>
            Reset Password
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href='/' variant='body2'>
                Back to Login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPassword;
