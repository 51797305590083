import { createTheme } from '@mui/material';

export const colours = {
  mainBlue: '#044389',
  backgroundPrimary: '#fafafa',
  backgroundSecondary: '#f0f0f0',
  backgroundWhite: '#ffffff',
  textPrimary: '#000000',
  textGray: '#6e6e6e',
  textOrange: '#F58526',
  textRed: '#d0342c',
  selectedGray: '#eeeeee',
};

export const theme = createTheme({
  palette: {
    primary: { main: colours.mainBlue },
    background: { default: colours.backgroundPrimary, paper: colours.backgroundSecondary },
    text: { primary: colours.textPrimary },
  },
});

export const zIndex = {
  backingPdf: 1,
  annotation: 2,
  textAnnotation: 3,
  pdfIconOverlay: 4,
  firstModal: 5,
  secondModal: 6,
  thirdModal: 7,
  fourthModal: 8,
  tooltip: 9,
  topMost: 10000, // Only to be used in extreeme cases - e.g. where a dropdown is ontop of a modal
};
