import React, { useEffect, useState } from 'react';
import { Page, PageProps } from 'react-pdf';

interface Props extends PageProps {
  maxPageNumber: number;
}

// Have 3 pages loaded, so that changing between songs doesn't cause a visual rerender
const PdfDisplayPagePreRenderedPage = (props: Props): JSX.Element => {
  const [page1Number, setPage1Number] = useState(1);
  const [page2Number, setPage2Number] = useState(2);
  const [page3Number, setPage3Number] = useState(3);

  // Alter the pages so that the page number is always shown
  useEffect(() => {
    if (props.pageNumber === page1Number) {
      setPage2Number(props.pageNumber + 1);
      setPage3Number(props.pageNumber - 1);
    } else if (props.pageNumber === page2Number) {
      setPage1Number(props.pageNumber - 1);
      setPage3Number(props.pageNumber + 1);
    } else if (props.pageNumber === page3Number) {
      setPage1Number(props.pageNumber + 1);
      setPage2Number(props.pageNumber - 1);
    } else if (props.pageNumber !== undefined) {
      // If we don't have any pages loaded, reset
      setPage1Number(props.pageNumber - 1);
      setPage2Number(props.pageNumber);
      setPage3Number(props.pageNumber + 1);
    }
  }, [props.pageNumber]);

  return (
    <div style={{ display: 'grid' }}>
      <div
        style={{
          gridColumn: '1',
          gridRow: '1',
          display: page1Number === props.pageNumber ? undefined : 'none',
        }}>
        {page1Number > 0 && page1Number <= props.maxPageNumber && <Page {...props} pageNumber={page1Number} />}
      </div>
      <div
        style={{
          gridColumn: '1',
          gridRow: '1',
          display: page2Number === props.pageNumber ? undefined : 'none',
        }}>
        {page2Number > 0 && page2Number <= props.maxPageNumber && <Page {...props} pageNumber={page2Number} />}
      </div>
      <div
        style={{
          gridColumn: '1',
          gridRow: '1',
          display: page3Number === props.pageNumber ? undefined : 'none',
        }}>
        {page3Number > 0 && page3Number <= props.maxPageNumber && <Page {...props} pageNumber={page3Number} />}
      </div>
    </div>
  );
};

export default PdfDisplayPagePreRenderedPage;
