import React, { useContext, useState } from 'react';
import { Box, Checkbox, Container, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthenticationClient, UserViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import { UserDispatchContext } from '../../utils/UserStorage';
import { useNavigate } from 'react-router-dom';
import Logo from '../../images/Logo.png';
import { SetSnackbarErrorContext } from '../../components/elements/snackbar/SnackbarContext';

const Login = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const userDispatch = useContext(UserDispatchContext);
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  const signIn = (): void => {
    setLoading(true);
    const redirectUrl = new URLSearchParams(window.location.search).get('redirect');
    new AuthenticationClient(Configuration.SERVER_ROOT, FetchOverride)
      .login({
        email: email,
        password: password,
      } as UserViewModel)
      .then(x => {
        userDispatch(x);
        setLoading(false);
        setError(false);
        navigate(redirectUrl ? redirectUrl : '/');
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        setSnackbarError('Could not login. Please check your login credentials and try again');
      });
  };

  return (
    <Container maxWidth='xs' sx={{ padding: 10, textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={Logo} alt='Logo' style={{ width: 50, marginBottom: 10 }} />
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box component='form' noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
            error={error}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={event => setPassword(event.target.value)}
            onKeyDown={event => event.key === 'Enter' && signIn()}
            error={error}
          />
          <FormControlLabel control={<Checkbox checked />} label='Remember Me' />
          {error && <Typography style={{ color: 'red' }}>Incorrect email or password entered</Typography>}
          <LoadingButton loading={loading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={signIn}>
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href='/createAccount' variant='body2'>
                Create Account
              </Link>
            </Grid>
            <Grid item xs>
              <Link href='/forgotPassword' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
