import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import PlaybackModal from './PlaybackModal';
import {
  GetAdjustableMobileEditButtonBottom,
  GetAdjustableMobileEditButtonRight,
} from '../../../../utils/MobileViewChanges';
import { colours, zIndex } from '../../../../Theme';

interface Props {
  songLink: string | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PlaybackButton = (props: Props): JSX.Element => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const buttonRef = useRef<Element>();

  useEffect(() => {
    if (props.songLink === undefined || props.songLink === '' || props.songLink === null) {
      props.setOpen(false);
      setShowButton(false);
      setIsPlaying(false);
    } else {
      setShowButton(true);
    }
  }, [props.songLink]);

  if (!showButton) {
    return <></>;
  }

  return (
    <>
      <PlaybackModal
        open={props.open}
        setOpen={props.setOpen}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        songLink={props.songLink}
        anchor={buttonRef}
      />
      <Tooltip
        title='Playback'
        style={{
          zIndex: zIndex.tooltip,
        }}
        ref={buttonRef}>
        <IconButton
          aria-label='Playback'
          onClick={() => props.setOpen(!props.open)}
          style={{
            zIndex: zIndex.pdfIconOverlay,
            position: 'absolute',
            bottom: GetAdjustableMobileEditButtonBottom('video'),
            right: GetAdjustableMobileEditButtonRight('video'),
            backgroundColor: colours.backgroundWhite,
          }}>
          <PlayArrow />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PlaybackButton;
