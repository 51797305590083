import React, { useContext, useEffect, useState } from 'react';
import { AnnotationViewModel, SetListViewModel } from '../../types/auto/types';
import { SongIdToPageNumbers } from '../../types/SongIdToPageNumbers';
import { GetSetListPdfById } from '../components/elements/pdf-display/helpers/GetSetList';
import PdfDisplayController from '../components/elements/pdf-display/PdfDisplayController';
import { PdfDisplayType } from '../components/elements/pdf-display/transpose/PdfTransposeModal';
import { Box, Button, Modal, Typography } from '@mui/material';
import { Configuration } from '../Constants';
import { UserContext } from '../utils/UserStorage';
import { isMobile } from 'react-device-detect';
import { GetPdfPreferences, PdfPreferences } from '../utils/PDFReadingPreferences';
import { zIndex } from '../Theme';

const PerformSetList = ({ isSharedSetListLink }: { isSharedSetListLink?: boolean }): JSX.Element => {
  // Set list state
  const [setList, setSetList] = useState<SetListViewModel>();
  const [songIdToPageNumbers, setSongIdToPageNumbers] = useState<SongIdToPageNumbers>({} as SongIdToPageNumbers);
  const [pdfData, setPdfData] = useState<ArrayBuffer>();
  const [annotations, setAnnotations] = useState<AnnotationViewModel[]>([]);
  const user = useContext(UserContext);
  const [sharedModalOpen, setSharedModalOpen] = useState(false);
  const [continueAsGuest, setContinueAsGuest] = useState(false);
  const [pdfPreferences, setPdfPreferences] = useState<PdfPreferences>();
  const [pdfPreferencesUpdated, setPdfPreferencesUpdated] = useState(false);
  const [refreshPdf, setRefreshPdf] = useState(false);

  // Load the pdf data on page load
  useEffect(() => {
    const setListId = new URLSearchParams(window.location.search).get('id');
    if (setListId === undefined || setListId === null || setListId === '') {
      window.location.href = '';
      return;
    }

    // If there is no logged in user
    if (user?.id === undefined && !continueAsGuest) {
      setSharedModalOpen(true);
      return;
    }
    const preferences = GetPdfPreferences();
    setPdfPreferences(preferences);
    setPdfPreferencesUpdated(x => !x);

    GetSetListPdfById(parseInt(setListId as string), isSharedSetListLink || false, preferences.fontSize).then(x => {
      if (x === undefined) {
        return;
      }
      setSetList(x.setList);
      setSongIdToPageNumbers(x.songIdToPageNumbersMapping);
      setPdfData(x.pdfData);
      x.annotations !== undefined && setAnnotations(x.annotations);
    });
  }, [setSetList, user, continueAsGuest, sharedModalOpen, refreshPdf]);

  return (
    <div style={{ overflowX: 'hidden', overflowY: 'hidden', width: '100%', height: '100%' }}>
      <Modal open={sharedModalOpen && !continueAsGuest} onClose={() => setContinueAsGuest(true)} disableAutoFocus>
        <Box
          maxWidth='xl'
          minWidth={isMobile ? '80%' : 'md'}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            zIndex: zIndex.firstModal,
          }}>
          <Typography variant='h4' component='h2'>
            Welcome To Sheet Music 👋
          </Typography>
          <Typography sx={{ mt: '40px' }}>You&apos;re currently in read-only mode for this set list.</Typography>

          <Typography sx={{ mt: '20px' }}>
            To save this set list for later or annotate the music, please log in or create an account. Otherwise
            continue as a guest to view the set.
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100',
              justifyContent: 'flex-end',
              gap: '20px',
              marginTop: '50px',
            }}>
            <Button
              variant='contained'
              onClick={() => {
                // Redirect to login
                window.location.href =
                  Configuration.SERVER_ROOT + '/login?redirect=' + window.location.pathname + window.location.search;
              }}>
              Login / Sign Up
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                setContinueAsGuest(true);
              }}>
              Continue As Guest
            </Button>
          </div>
        </Box>
      </Modal>
      <PdfDisplayController
        setList={setList}
        setSetList={setSetList}
        songIdToPageNumbers={songIdToPageNumbers}
        pdfData={pdfData}
        annotations={annotations}
        setAnnotations={setAnnotations}
        pdfDisplayType={PdfDisplayType.SetList}
        isSharedSetListLink={isSharedSetListLink || false}
        pdfDisplayPreferences={pdfPreferences}
        pdfPreferencesUpdated={pdfPreferencesUpdated}
        refreshPdf={() => {
          setRefreshPdf(x => !x);
        }}
      />
    </div>
  );
};

export default PerformSetList;
