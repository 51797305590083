import {
  AnnotationClient,
  AnnotationViewModel,
  SetListClient,
  SetListViewModel,
} from '../../../../../types/auto/types';
import { SongIdToPageNumbers } from '../../../../../types/SongIdToPageNumbers';
import { Configuration } from '../../../../Constants';
import { FetchOverride } from '../../../../utils/Requests';

export const GetSetListPdfById = async (
  id: number,
  isShared: boolean,
  generatedFontSizeBody: number
): Promise<
  | {
      songIdToPageNumbersMapping: SongIdToPageNumbers;
      pdfData: ArrayBuffer;
      annotations: AnnotationViewModel[] | undefined;
      setList: SetListViewModel;
    }
  | undefined
> => {
  let songsPdf = undefined;
  try {
    if (isShared) {
      // Try to join the shared set list. If we can, we'll get the proper set list id rather than the shared id, so we should redirect to that
      const setListId = await new SetListClient(Configuration.SERVER_ROOT, FetchOverride).tryJoinSharedSetList(id);

      // If the set list id is not null, we've got access, so redirect to the proper set list url
      if (setListId !== null) {
        window.location.href = Configuration.SERVER_ROOT + '/setList?id=' + setListId;
        return;
      }

      // Otherwise we need to get the shared set list details as we can't assign the set list to the account
      songsPdf = await new SetListClient(Configuration.SERVER_ROOT, FetchOverride).getSharedSetListSongsPdfs(
        id,
        generatedFontSizeBody
      );
    } else {
      songsPdf = await new SetListClient(Configuration.SERVER_ROOT, FetchOverride).getSetListSongsPdfs(
        id,
        generatedFontSizeBody
      );
    }
  } catch {
    console.log("Couldn't get song pdf");
    return;
  }

  let songIdToPageNumbers: SongIdToPageNumbers = {};
  if (isShared) {
    const metaData = await new SetListClient(Configuration.SERVER_ROOT, FetchOverride).getSharedSetListSongsPdfMetaData(
      id,
      generatedFontSizeBody
    );
    songIdToPageNumbers = metaData.songIdToPageNumbersMapping || {};
  } else {
    const metaData = await new SetListClient(Configuration.SERVER_ROOT, FetchOverride).getSetListSongsPdfMetaData(
      id,
      generatedFontSizeBody
    );
    songIdToPageNumbers = metaData.songIdToPageNumbersMapping || {};
  }

  // Get the file data
  const pdfData = await songsPdf.data.arrayBuffer();

  let setList = undefined;
  if (isShared) {
    setList = await new SetListClient(Configuration.SERVER_ROOT, FetchOverride).getSetListBySharedId(id);
  } else {
    setList = await new SetListClient(Configuration.SERVER_ROOT, FetchOverride).getSetListById(id);
  }

  // Get the annotations only if the setlist isn't shared
  let annotations = undefined;
  if (!isShared) {
    try {
      annotations = await new AnnotationClient(Configuration.SERVER_ROOT, FetchOverride).getAnnotationsForSetListById(
        id
      );
    } catch {
      console.log("Couldn't get annotations");
      return {
        songIdToPageNumbersMapping: songIdToPageNumbers,
        pdfData: pdfData,
        annotations: undefined,
        setList: setList,
      };
    }
  }

  return {
    songIdToPageNumbersMapping: songIdToPageNumbers,
    pdfData: pdfData,
    annotations: annotations,
    setList: setList,
  };
};
