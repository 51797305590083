import React, { useContext, useEffect } from 'react';
import { CircularProgress, Container, Typography } from '@mui/material';
import { UserClient } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import { useNavigate } from 'react-router-dom';
import { SetSnackbarErrorContext, SetSnackbarSuccessContext } from '../../components/elements/snackbar/SnackbarContext';

const ConfirmEmail = (): JSX.Element => {
  const navigate = useNavigate();
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token === undefined || token === null || token === '') {
      setSnackbarError('Invalid email token');
      navigate('/');
      return;
    }

    new UserClient(Configuration.SERVER_ROOT, FetchOverride)
      .confirmEmailAddress(parseInt(token))
      .then(() => {
        setSnackbarSuccess('Email Address Activated. You can now login to Sheet Music!');
        navigate('/');
      })
      .catch(() => {
        setSnackbarError('Could not confirm email address. Please try again');
        navigate('/');
      });
  }, []);

  return (
    <Container maxWidth='xs' sx={{ padding: 10, textAlign: 'center' }}>
      <CircularProgress />
      <Typography>Confirming Email Address</Typography>
    </Container>
  );
};

export default ConfirmEmail;
