import React, { useContext, useState } from 'react';
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { AccountCircle, Lock, Monitor, Person, Settings } from '@mui/icons-material';
import { UserContext, UserDispatchContext } from '../utils/UserStorage';
import { AuthenticationClient, UserViewModel } from '../../types/auto/types';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Configuration } from '../Constants';
import { FetchOverride } from '../utils/Requests';
import { SetSnackbarErrorContext } from '../components/elements/snackbar/SnackbarContext';
import Notifications from './Notifications';
import BackButton from './BackButton';
import { theme, zIndex } from '../Theme';
import SetListViewSettingsModal from '../components/set-list-card/SetListViewSettingsModal';
import { ShowNavbar } from '../utils/ShowNavbarContext';

interface Props {
  showBackButton?: boolean;
}

const NavBar = (props: Props): JSX.Element => {
  const userDispatch = useContext(UserDispatchContext);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const user = useContext(UserContext);
  const showNavbar = useContext(ShowNavbar);
  const isUserNull = user === undefined || user === null || user.id === undefined;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };
  return (
    <>
      {showNavbar && (
        <AppBar position='static' style={{ display: showNavbar ? undefined : 'none' }}>
          <Toolbar disableGutters style={{ justifyContent: 'center' }}>
            {props.showBackButton && <BackButton />}
            <Typography
              variant='h5'
              noWrap
              component='a'
              href={Configuration.SERVER_ROOT}
              sx={{
                mr: 2,
                textAlign: 'center',
                flexGrow: 1,
                color: 'inherit',
                textDecoration: 'none',
                position: 'absolute',
              }}>
              Sheet Music
            </Typography>

            {!isUserNull ? (
              <AccountPane
                handleOpenUserMenu={handleOpenUserMenu}
                handleCloseUserMenu={handleCloseUserMenu}
                anchorElUser={anchorElUser}
                navigate={navigate}
                setSnackbarError={setSnackbarError}
                userDispatch={userDispatch}
              />
            ) : (
              <ViewSettingsPane />
            )}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

const ViewSettingsPane = (): JSX.Element => {
  const [viewSettingsModalOpen, setViewSettingsModalOpen] = useState(false);
  return (
    <>
      <SetListViewSettingsModal open={viewSettingsModalOpen} setOpen={setViewSettingsModalOpen} />
      <Box sx={{ position: 'absolute', right: 20 }}>
        <Tooltip title='View Settings' style={{ zIndex: zIndex.tooltip }}>
          <IconButton onClick={() => setViewSettingsModalOpen(true)} sx={{ p: 0, mt: 0 }}>
            <Monitor sx={{ fontSize: 40, color: 'white' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

const AccountPane = ({
  handleOpenUserMenu,
  handleCloseUserMenu,
  anchorElUser,
  navigate,
  setSnackbarError,
  userDispatch,
}: {
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseUserMenu: () => void;
  anchorElUser: HTMLElement | null;
  navigate: NavigateFunction;
  setSnackbarError: React.Dispatch<string | undefined>;
  userDispatch: React.Dispatch<UserViewModel>;
}): JSX.Element => {
  const [viewSettingsModalOpen, setViewSettingsModalOpen] = useState(false);
  return (
    <>
      <SetListViewSettingsModal open={viewSettingsModalOpen} setOpen={setViewSettingsModalOpen} />
      <Box sx={{ position: 'absolute', right: 20 }}>
        <Tooltip title='Profile' style={{ zIndex: zIndex.tooltip }}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mt: 0 }}>
            <AccountCircle sx={{ fontSize: 40, color: 'white' }} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px', zIndex: zIndex.firstModal }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}>
          <Notifications />
          <hr />
          <MenuItem
            key='Account'
            onClick={() => {
              handleCloseUserMenu();
              navigate('/account');
            }}
            style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Person />
            <Typography>Account Settings</Typography>
          </MenuItem>
          <MenuItem
            key='Music View Settings'
            onClick={() => {
              setViewSettingsModalOpen(true);
              handleCloseUserMenu();
            }}
            style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Settings />
            <Typography>Music View Settings</Typography>
          </MenuItem>
          <MenuItem
            key='Logout'
            onClick={() => {
              handleCloseUserMenu();
              new AuthenticationClient(Configuration.SERVER_ROOT, FetchOverride)
                .logout()
                .then(() => {
                  userDispatch({} as UserViewModel);
                  navigate('/login');
                })
                .catch(() =>
                  setSnackbarError('An unknown error occurred and you could not be logged out. Please try again')
                );
            }}>
            <Typography
              sx={{ color: theme.palette.error.dark }}
              style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <Lock />
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default NavBar;
