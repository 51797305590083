import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactSketchCanvas, ReactSketchCanvasRef, CanvasPath as SketchCanvasPath, Point } from 'react-sketch-canvas';
import { AnnotationSettings, AnnotationType } from '../../../../types/AnnotationSettings';
import { TextAnnotationViewModel } from '../../../../types/auto/types';
import TextInputAnnotationController from './TextInputAnnotationController';
import debounce from 'lodash.debounce';
import { zIndex } from '../../../Theme';

// DO NOT CHANGE THIS OR ALL ANNOTATIONS WILL BREAK
const SavedAnnotationDrawingSize = 100000;

export interface Props {
  canvasWidth: number;
  canvasHeight: number;
  annotationSettings?: AnnotationSettings;
  annotations?: string;
  showAnnotations: boolean;
  textAnnotations?: TextAnnotationViewModel[];
  setAnnotationsForSongPageNumber: (
    annotations: string,
    textAnnotations: TextAnnotationViewModel[],
    songId: number,
    pageNumber: number
  ) => void;
  songId: number;
  songPageNumber: number;
  zoomLevel: number;
}

const AnnotationCanvas = ({
  canvasWidth,
  canvasHeight,
  annotationSettings,
  annotations,
  textAnnotations,
  setAnnotationsForSongPageNumber,
  songId,
  songPageNumber,
  zoomLevel,
  showAnnotations,
}: Props): JSX.Element => {
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const [drawnAnnotations, setDrawnAnnotations] = useState('');
  const [textAnnotationsNew, setTextAnnotationsNew] = useState<TextAnnotationViewModel[]>([]);
  const [hasChanged, setHasChanged] = useState(false);

  // Clear the canvas and load annotations if relevant
  useEffect(() => {
    canvasRef.current?.clearCanvas();
    canvasRef.current?.resetCanvas();
    setHasChanged(false);
    if (annotations) {
      let paths = JSON.parse(annotations) as SketchCanvasPath[];

      // Scale the data points to the new device width / height
      paths = paths.map(
        x =>
          // We need to create a new object here as the properties are read only
          ({
            paths: x.paths.map(
              y =>
                ({
                  x: (y.x / SavedAnnotationDrawingSize) * canvasWidth,
                  y: (y.y / SavedAnnotationDrawingSize) * canvasHeight,
                } as Point)
            ),
            strokeWidth: x.strokeWidth,
            strokeColor: x.strokeColor,
            drawMode: x.drawMode,
            startTimestamp: x.startTimestamp,
            endTimestamp: x.endTimestamp,
          } as SketchCanvasPath)
      );
      canvasRef.current?.loadPaths(paths);
    }
    setTextAnnotationsNew(textAnnotations || []);
  }, [
    annotations,
    setHasChanged,
    songId,
    songPageNumber,
    textAnnotations,
    setTextAnnotationsNew,
    canvasWidth,
    canvasHeight,
    showAnnotations,
  ]);

  // Set the erase mode
  useEffect(() => {
    canvasRef.current?.eraseMode(annotationSettings?.annotationType === AnnotationType.Eraser);
  }, [annotationSettings]);

  // Save the annotations after 2 seconds of editing
  const updateAnnotationsDebounced = useMemo(
    () =>
      debounce((drawnAnnotations, textAnnotations, songId, songPageNumber, hasChanged, setHasChanged) => {
        if (hasChanged) {
          setAnnotationsForSongPageNumber(drawnAnnotations, textAnnotations, songId, songPageNumber);
        }
        setHasChanged(false);
      }, 800),
    []
  );

  // Clear all button pressed
  useEffect(() => {
    if (annotationSettings?.clearAll) {
      setAnnotationsForSongPageNumber('', [], songId, songPageNumber);

      canvasRef.current?.clearCanvas();
      canvasRef.current?.resetCanvas();
      setHasChanged(false);
    }
  }, [annotationSettings?.clearAll]);

  return (
    <>
      <TextInputAnnotationController
        editable={annotationSettings?.isEditing === true && annotationSettings.annotationType === AnnotationType.Text}
        textInputs={textAnnotationsNew}
        showAnnotations={showAnnotations}
        zIndex={
          annotationSettings?.isEditing === true && annotationSettings.annotationType !== AnnotationType.Text
            ? zIndex.backingPdf
            : zIndex.textAnnotation
        }
        annotationSettings={annotationSettings}
        setTextInputs={textInputs => {
          setTextAnnotationsNew(textInputs);
          setHasChanged(true);

          setAnnotationsForSongPageNumber(drawnAnnotations, textInputs, songId, songPageNumber);
        }}
        zoomLevel={zoomLevel}
        canvasWidth={canvasWidth.toString() + 'px'}
        canvasHeight={canvasHeight.toString() + 'px'}
      />
      <ReactSketchCanvas
        id={'song' + songId + 'pg' + songPageNumber}
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: zIndex.annotation,
          display: showAnnotations ? undefined : 'none',
        }}
        svgStyle={{
          background: 'rgba(0, 0, 0, 0)',
        }}
        width={canvasWidth.toString() + 'px'}
        height={canvasHeight.toString() + 'px'}
        canvasColor='rgba(0, 0, 0, 0)'
        onChange={path => {
          const annotations = JSON.stringify(
            // Scale the canvas to a default value
            path.map(
              x =>
                ({
                  paths: x.paths.map(
                    y =>
                      ({
                        x: (y.x / canvasWidth) * SavedAnnotationDrawingSize,
                        y: (y.y / canvasHeight) * SavedAnnotationDrawingSize,
                      } as Point)
                  ),
                  strokeWidth: x.strokeWidth,
                  strokeColor: x.strokeColor,
                  drawMode: x.drawMode,
                  startTimestamp: x.startTimestamp,
                  endTimestamp: x.endTimestamp,
                } as SketchCanvasPath)
            )
          );
          setDrawnAnnotations(annotations);
          updateAnnotationsDebounced(
            annotations,
            textAnnotationsNew,
            songId,
            songPageNumber,
            hasChanged,
            setHasChanged
          );
        }}
        onStroke={() => setHasChanged(true)}
        strokeColor={annotationSettings?.colour}
        strokeWidth={annotationSettings?.size}
        eraserWidth={(annotationSettings?.size as number) * 5}
        allowOnlyPointerType={
          annotationSettings?.isEditing &&
          (annotationSettings.annotationType === AnnotationType.Eraser ||
            annotationSettings.annotationType === AnnotationType.Pen)
            ? 'all'
            : 'none'
        }
      />
    </>
  );
};

export default AnnotationCanvas;
