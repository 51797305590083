import React from 'react';
import { Card, Container, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

export interface SongMetaData {
  name: string | undefined;
  artist: string | undefined;
  thumbnailUrl: string | undefined;
  url: string | undefined;
}

interface Props {
  songMetaData: SongMetaData | undefined;
}

const AudioDisplay = (props: Props): JSX.Element =>
  props.songMetaData && props.songMetaData.name && props.songMetaData.thumbnailUrl ? (
    <Card
      style={{
        width: '94%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '2%',
        marginTop: '10px',
        paddingBottom: 0,
        backgroundColor: '#f8f8f8',
        cursor: props.songMetaData.url ? 'pointer' : undefined,
        maxHeight: '130px',
      }}
      onClick={() => {
        if (props.songMetaData?.url) {
          window.open(props.songMetaData.url, '_blank')?.focus();
        }
      }}>
      <img
        src={props.songMetaData.thumbnailUrl}
        alt='Song Thumbnail'
        style={{ maxWidth: isMobile ? '40%' : '20%', height: '130px' }}
      />
      <Container
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          width: isMobile ? '70%' : '50%',
          height: '100%',
          paddingTop: 'auto',
          paddingBottom: 'auto',
          paddingLeft: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: 0,
          marginRight: 0,
        }}>
        <Typography style={{ fontWeight: 'bold' }}>{props.songMetaData.name}</Typography>
        <Typography>{props.songMetaData.artist}</Typography>
      </Container>
    </Card>
  ) : (
    <></>
  );

export default AudioDisplay;
