import { LoadingButton } from '@mui/lab';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { FileUpload as FileUploadIcon, Notes } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { SongClient, SongViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride, FileUploadFetchOverride } from '../../utils/Requests';
import FileUpload from '../elements/file-upload/FileUpload';
import { SetSnackbarErrorContext } from '../elements/snackbar/SnackbarContext';
import AudioSearch from '../elements/audio-search/AudioSearch';
import { GetKeyFromChordText, keys } from '../../utils/KeyChart';
import { zIndex } from '../../Theme';
interface Props {
  open: boolean;
  onClose: (newSongIfApplicable: SongViewModel | undefined) => void;
  songToEdit?: SongViewModel;
}

const SongUploadModal = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [songName, setSongName] = useState('');
  const [artist, setArtist] = useState('');
  const [bpm, setBpm] = useState<number>();
  const [key, setKey] = useState<string>('');
  const [capo, setCapo] = useState<number>(0);
  const [link, setLink] = useState('');
  const [linkError, setLinkError] = useState(false);
  const [file, setFile] = useState<File>();
  const [textUpload, setTextUpload] = useState('');
  const [nameError, setNameError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [uploadMode, setUploadMode] = useState(UploadType.Text);
  const [amEditing, setAmEditing] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  useEffect(() => {
    if (props.open) {
      setSongName(props.songToEdit?.name || '');
      setArtist(props.songToEdit?.artist || '');
      setBpm(props.songToEdit?.bpm);
      setCapo(props.songToEdit?.capo || 0);
      setLink(props.songToEdit?.link || '');
      setLinkError(false);
      setFile(undefined);
      setNameError(false);
      setFileError(false);
      setUploadMode(props.songToEdit === undefined || props.songToEdit.text ? UploadType.Text : UploadType.File);
      setTextUpload(props.songToEdit?.text || '');
      setAmEditing(props.songToEdit !== undefined);

      // Try and guess the key if possible
      if (
        props.songToEdit?.text &&
        (props.songToEdit.key === undefined || props.songToEdit.key === null || props.songToEdit.key === '')
      ) {
        setKey(GetKeyFromChordText(props.songToEdit?.text || '') || '');
      } else {
        setKey(props.songToEdit?.key || '');
      }
    } else {
      setLink('');
    }
  }, [props]);

  return (
    <Dialog open={props.open} fullWidth maxWidth='xl' style={{ zIndex: zIndex.thirdModal }}>
      <DialogContent>
        <Typography variant='h4'>{amEditing ? 'Update' : 'Create New'} Song</Typography>

        <TextField
          margin='dense'
          error={nameError}
          helperText={nameError ? 'Please add the name of the song' : undefined}
          id='name'
          label='Song Name'
          type='text'
          fullWidth
          variant='standard'
          value={songName}
          style={{ width: '48%', marginRight: '2%', display: 'inline-block' }}
          onChange={event => setSongName(event.target.value)}
          onBlur={event => setNameError(event.target.value === '')}
          autoCapitalize='words'
        />
        <TextField
          margin='dense'
          id='artist'
          label='Artist'
          type='text'
          fullWidth
          variant='standard'
          value={artist || ''}
          style={{ width: '48%', display: 'inline-block' }}
          onChange={event => setArtist(event.target.value)}
          autoCapitalize='words'
        />
        <div style={{ width: '48%', display: 'inline-block', marginRight: '2%' }}>
          <InputLabel id='key-label'>Key</InputLabel>
          <Select
            labelId='key-label'
            label='Key'
            value={key}
            style={{ width: '100%' }}
            variant='standard'
            MenuProps={{ style: { zIndex: zIndex.fourthModal } }}
            onChange={event => setKey(event.target.value)}>
            <MenuItem key='empty' value=''>
              Please Select a Key
            </MenuItem>
            {keys.map(x => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div style={{ width: '48%', display: 'inline-block' }}>
          <InputLabel id='capo-label'>Capo</InputLabel>
          <Select
            labelId='capo-label'
            label='Capo'
            value={capo}
            style={{ width: '100%' }}
            variant='standard'
            MenuProps={{ style: { zIndex: zIndex.fourthModal } }}
            onChange={event => setCapo(event.target.value as number)}>
            <MenuItem value={0}>No Capo</MenuItem>
            <MenuItem value={1}>1st Fret</MenuItem>
            <MenuItem value={2}>2nd Fret</MenuItem>
            <MenuItem value={3}>3rd Fret</MenuItem>
            <MenuItem value={4}>4th Fret</MenuItem>
            <MenuItem value={5}>5th Fret</MenuItem>
            <MenuItem value={6}>6th Fret</MenuItem>
            <MenuItem value={7}>7th Fret</MenuItem>
            <MenuItem value={8}>8th Fret</MenuItem>
            <MenuItem value={9}>9th Fret</MenuItem>
            <MenuItem value={10}>10th Fret</MenuItem>
            <MenuItem value={11}>11th Fret</MenuItem>
            <MenuItem value={12}>12th Fret</MenuItem>
          </Select>
        </div>
        <TextField
          margin='dense'
          id='name'
          label='BPM'
          type='number'
          fullWidth
          style={{ width: '48%', marginRight: '2%', display: 'inline-block' }}
          variant='standard'
          value={bpm || 120}
          onChange={event => setBpm(parseInt(event.target.value))}
        />
        <AudioSearch link={link} setLink={setLink} setError={setLinkError} />
        <Container style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <ToggleButtonGroup
            value={uploadMode}
            exclusive
            onChange={(_, value) => value !== null && setUploadMode(value)}
            aria-label='fileMode'
            style={{ margin: 0, padding: 0, textAlign: 'center' }}>
            <ToggleButton value={UploadType.Text} aria-label='text upload'>
              <Notes /> <Typography style={{ textTransform: 'none', marginLeft: 10 }}>Write Text</Typography>
            </ToggleButton>
            <ToggleButton value={UploadType.File} aria-label='file upload'>
              <FileUploadIcon /> <Typography style={{ textTransform: 'none', marginLeft: 10 }}>Upload File</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
        <div style={{ marginTop: 20 }}>
          {uploadMode === UploadType.File && (
            <>
              <FileUpload setFile={file => setFile(file)} />
              {fileError && <FormHelperText style={{ color: 'red' }}>Please upload a pdf of a song</FormHelperText>}
            </>
          )}
          {uploadMode === UploadType.Text && (
            <>
              <TextField
                label='Song Text'
                style={{ width: '100%' }}
                InputProps={{
                  style: { fontFamily: 'courier, monospace' },
                }}
                multiline
                minRows={10}
                maxRows={10}
                value={textUpload}
                onChange={e => {
                  const textValue = e.target.value;
                  setTextUpload(textValue);

                  if (props.songToEdit?.key === undefined) {
                    setKey(GetKeyFromChordText(textValue) || '');
                  }
                }}
              />
              {fileError && (
                <FormHelperText style={{ color: 'red' }}>Please upload some text for the song</FormHelperText>
              )}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => props.onClose(undefined)}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => {
            const isNameError = songName === '';

            if (linkError) {
              return;
            }

            // File error depends on the type of file selected
            let isFileError = false;
            if (uploadMode === UploadType.File) {
              // Error if the file is empty AND we are creating a new song, or we are editing a song that had text previously
              isFileError =
                file === undefined &&
                (props.songToEdit === undefined ||
                  (props.songToEdit.text !== undefined &&
                    props.songToEdit.text !== null &&
                    props.songToEdit.text !== ''));
            } else if (uploadMode === UploadType.Text) {
              isFileError = textUpload === '';
            }

            setNameError(isNameError);
            setFileError(isFileError);
            if (isNameError || isFileError) {
              return;
            }
            setLoading(true);

            if (uploadMode === UploadType.File && (file !== undefined || amEditing)) {
              const reader = new FileReader();
              reader.onload = async () => {
                amEditing
                  ? await new SongClient(Configuration.SERVER_ROOT, FileUploadFetchOverride(reader.result))
                      .updateSongWithPdf(
                        props.songToEdit?.id,
                        songName,
                        artist,
                        bpm,
                        capo,
                        link,
                        key === '' ? undefined : key
                      )
                      .then(() => {
                        setLoading(false);
                        props.onClose(undefined);
                      })
                      .catch(() => setSnackbarError('Could not update song. Please try again later'))
                      .finally(() => setLoading(false))
                  : await new SongClient(Configuration.SERVER_ROOT, FileUploadFetchOverride(reader.result))
                      .createFromPdf(songName, artist, bpm, capo, key === '' ? undefined : key, link)
                      .then(x => {
                        props.onClose(x.value);
                        setLoading(false);
                      })
                      .catch(() => setSnackbarError('Could not create song. Please try again later'))
                      .finally(() => setLoading(false));
              };

              if (amEditing && file === undefined) {
                new SongClient(Configuration.SERVER_ROOT, FetchOverride)
                  .updateSongWithPdf(
                    props.songToEdit?.id,
                    songName,
                    artist,
                    bpm,
                    capo,
                    link,
                    key === '' ? undefined : key
                  )
                  .then(() => {
                    setLoading(false);
                    props.onClose(undefined);
                  })
                  .catch(() => setSnackbarError('Could not update song. Please try again later'))
                  .finally(() => setLoading(false));
                return;
              }
              reader.readAsArrayBuffer(file as File);
            } else if (uploadMode === UploadType.Text) {
              amEditing
                ? new SongClient(Configuration.SERVER_ROOT, FetchOverride)
                    .updateSong({
                      ...props.songToEdit,
                      name: songName,
                      artist: artist,
                      bpm: bpm,
                      capo: capo,
                      link: link,
                      text: textUpload,
                      key: key === '' ? undefined : key,
                    } as SongViewModel)
                    .then(() => {
                      setLoading(false);
                      props.onClose(undefined);
                    })
                    .catch(() => setSnackbarError('Could not update song. Please try again later'))
                    .finally(() => setLoading(false))
                : new SongClient(Configuration.SERVER_ROOT, FetchOverride)
                    .create(songName, artist, bpm, capo, link, key === '' ? undefined : key, textUpload)
                    .then(x => {
                      setLoading(false);

                      props.onClose(x.value);
                    })
                    .catch(() => setSnackbarError('Could not create song. Please try again later'))
                    .finally(() => setLoading(false));
            }
          }}>
          {amEditing ? 'Update' : 'Create'} Song
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

enum UploadType {
  File,
  Text,
}

export default SongUploadModal;
