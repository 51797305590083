import React from 'react';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { zIndex } from '../../../Theme';

interface Props {
  counter: number;
  setCounter: (v: number) => void;
  title: NonNullable<React.ReactNode>;
  incrementAmount?: number;
  min?: number;
  max?: number;
}

const GroupedButtons = (props: Props): JSX.Element => (
  <Tooltip title={props.title} placement='top' style={{ zIndex: zIndex.tooltip }}>
    <ButtonGroup size='small' style={{ height: 25, zIndex: zIndex.pdfIconOverlay }}>
      <Button
        disabled={
          props.min !== undefined &&
          props.counter - (props.incrementAmount === undefined ? 1 : props.incrementAmount) < props.min
        }
        onClick={() =>
          props.setCounter(props.counter - (props.incrementAmount === undefined ? 1 : props.incrementAmount))
        }
        style={{ padding: '1px' }}>
        -
      </Button>
      <Button disabled>{props.counter}</Button>
      <Button
        onClick={() =>
          props.setCounter(props.counter + (props.incrementAmount === undefined ? 1 : props.incrementAmount))
        }
        disabled={
          props.max !== undefined &&
          props.counter + (props.incrementAmount === undefined ? 1 : props.incrementAmount) > props.max
        }>
        +
      </Button>
    </ButtonGroup>
  </Tooltip>
);

export default GroupedButtons;
