import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SongClient, SongViewModel } from '../../../types/auto/types';
import DraggableList from '../elements/draggable-list/DraggableList';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Autocomplete, TextField, Tooltip, Typography } from '@mui/material';
import SongUploadModal from '../song-upload-modal/SongUploadModal';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import debounce from 'lodash.debounce';
import { SetSnackbarErrorContext } from '../elements/snackbar/SnackbarContext';
import { colours, zIndex } from '../../Theme';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SongKeyChip from '../elements/song-key-chip/SongKeyChip';

interface Props {
  selectedSongs: SongViewModel[];
  setSelectedSongs: React.Dispatch<React.SetStateAction<SongViewModel[]>>;
  songError: boolean;
  setSongError: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate?: () => void;
  hideTitle?: boolean;
  isSetListView?: boolean;
  jumpToCard?: (song: SongViewModel) => void;
  forceMobileView?: boolean;
  viewOnly?: boolean;
  maxHeight?: string;
}

const AddNewSong = {
  id: -1,
  name: 'Add New Song',
} as SongViewModel;

const SetListSongSelect = (props: Props): JSX.Element => {
  const [autocompleteSong, setAutocompleteSong] = useState<SongViewModel>();
  const [songToEdit, setSongToEdit] = useState<SongViewModel>();
  const [allSongs, setAllSongs] = useState<SongViewModel[]>([]);
  const [render, setRerender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [songUploadModalOpen, setSongUploadModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  // Get all of the songs for the user
  useEffect(() => {
    if (props.viewOnly) {
      return;
    }

    if (allSongs.length === 0 || songUploadModalOpen === false) {
      setLoading(true);
      new SongClient(Configuration.SERVER_ROOT, FetchOverride)
        .getSongs(searchTerm, true, true, 100, 0, false)
        .then(x => {
          if (x.values === undefined) {
            return;
          }
          const songs = x.values.sort((a, b) => a.name?.localeCompare(b.name || '') || 0);
          songs.unshift(AddNewSong);
          setAllSongs(songs.filter((val, index, self) => self.indexOf(val) === index));
        })
        .catch(() => setSnackbarError('Could not get songs. Please try again later'))
        .finally(() => setLoading(false));
    }
  }, [setAllSongs, songUploadModalOpen, searchTerm, render, props.viewOnly]);

  const debouncedResults = useMemo(() => {
    setLoading(true);
    return debounce((value: string) => setSearchTerm(value), 800);
  }, []);

  useEffect(() => () => debouncedResults.cancel());

  // Split out the drag drop list so we can re-render this when viewOnly changes
  const DragDropList = (): JSX.Element => (
    <div
      style={{
        marginTop: '10px',
        overflow: 'auto',
        maxHeight: props.maxHeight,
      }}>
      <DndProvider backend={HTML5Backend}>
        <DraggableList
          songs={props.selectedSongs}
          setSongs={props.setSelectedSongs}
          rerender={() => {
            setRerender(x => !x);
            if (props.selectedSongs.length === 0) {
              props.setSongError(true);
            }
          }}
          onUpdate={props.onUpdate}
          isSetListView={props.isSetListView === undefined ? false : props.isSetListView}
          jumpToCard={props.jumpToCard}
          editCard={
            props.viewOnly
              ? undefined
              : song => {
                  setSongToEdit(song);
                  setSongUploadModalOpen(true);
                }
          }
          forceMobileView={props.forceMobileView}
          viewOnly={props.viewOnly}
          setSongTransposition={(song, transpositionAmount) => {
            props.setSelectedSongs(x =>
              x.map(songS => {
                if (songS.id === song.id) {
                  songS.storedTransposition = transpositionAmount;
                }

                return songS;
              })
            );

            props.onUpdate && props.onUpdate();
          }}
        />
      </DndProvider>
    </div>
  );

  return (
    <>
      {props.viewOnly !== true && (
        <>
          <SongUploadModal
            open={songUploadModalOpen}
            onClose={songToAdd => {
              setSongUploadModalOpen(false);

              if (songToEdit) {
                setSongToEdit(undefined);
                props.onUpdate && props.onUpdate();
              }

              if (songToAdd) {
                props.setSelectedSongs(x => {
                  x.push(songToAdd);
                  return x;
                });

                setAutocompleteSong(undefined);
                setRerender(x => !x);
                props.setSongError(false);
                setSearchTerm('');
              }
            }}
            songToEdit={songToEdit}
          />
          {props.hideTitle !== true && <Typography variant='h5'>Select Songs</Typography>}
          <Autocomplete
            key={render ? 1 : 0}
            componentsProps={{ popper: props.isSetListView ? { style: { zIndex: zIndex.secondModal } } : undefined }}
            options={loading ? [] : allSongs} // Loading only displays if the options are blank
            value={autocompleteSong}
            loading={loading}
            getOptionLabel={x => x.name + (x.artist ? ' - ' + x.artist : '')}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.id}
                style={{ fontWeight: option.id === AddNewSong.id ? 'bold' : undefined, width: '100%' }}>
                {option.id === AddNewSong.id ? (
                  <></>
                ) : option.text ? (
                  <Tooltip title='Song Has Uploaded Chords'>
                    <TextFieldsIcon
                      sx={{ color: colours.textGray, fontSize: 20, marginRight: '5px', display: 'inline-block' }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title='Song Has Uploaded PDF'>
                    <PictureAsPdfIcon
                      sx={{ color: colours.textGray, fontSize: 20, marginRight: '5px', display: 'inline-block' }}
                    />
                  </Tooltip>
                )}
                {option.name + (option.artist ? ' - ' + option.artist : '')}
                <SongKeyChip songKey={option.key} songName={option.name} transposeAmount={option.storedTransposition} />
              </li>
            )}
            onChange={(_, value) => {
              if (value) {
                props.onUpdate && props.onUpdate();

                // If the new song value is chosen, open the modal instead
                if (value.id === AddNewSong.id) {
                  setSongUploadModalOpen(true);
                  setAutocompleteSong(undefined);
                  setRerender(x => !x);
                  return;
                }

                props.setSelectedSongs(x => {
                  x.push(value);
                  return x;
                });
              }
              setAutocompleteSong(undefined);
              setRerender(x => !x);
              props.setSongError(false);
              setSearchTerm('');
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='Add Song'
                error={props.songError}
                helperText={props.songError && 'Please add at least 1 song'}
              />
            )}
            filterOptions={options => options}
            onInputChange={(_, value) => debouncedResults(value)}
          />
        </>
      )}
      {props.viewOnly ? <DragDropList /> : <DragDropList />}
    </>
  );
};

export default SetListSongSelect;
