import React from 'react';
import { Container, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  entityName: string;
}

const NoResultsScreen = (props: Props): JSX.Element => (
  <Container style={{ marginTop: '40px', textAlign: 'center' }}>
    <ErrorIcon style={{ fontSize: 50 }} />
    <Typography style={{ fontSize: 23 }}>No {props.entityName}s were found</Typography>
    <Typography style={{ fontSize: 18 }}>Please try a different search term</Typography>
  </Container>
);

export default NoResultsScreen;
