import React, { useContext, useState } from 'react';
import { Box, Checkbox, Container, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UserClient, UserViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import { useNavigate } from 'react-router-dom';
import Logo from '../../images/Logo.png';
import { SetSnackbarErrorContext, SetSnackbarSuccessContext } from '../../components/elements/snackbar/SnackbarContext';
import PasswordChecklist from 'react-password-checklist';
import { isEmailInvalid } from '../../utils/EmailChecker';

const CreateAccount = (): JSX.Element => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsValid, setPasswordsValue] = useState(false);
  const [acceptedTos, setAcceptedTos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const navigate = useNavigate();
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  const createAccount = (): void => {
    setLoading(true);

    if (firstName.replace(' ', '').length === 0 || lastName.replace(' ', '').length === 0) {
      setSnackbarError('Please enter your name');
      setLoading(false);
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }

    if (isEmailInvalid(email)) {
      setSnackbarError('Please enter a valid email address');
      setLoading(false);
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    if (!passwordsValid) {
      setSnackbarError('Please check password validity');
      setLoading(false);
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    if (!acceptedTos) {
      setSnackbarError('Please accept the terms of service to continue');
      setLoading(false);
      return;
    }

    new UserClient(Configuration.SERVER_ROOT, FetchOverride)
      .createUser({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        acceptedTosVersion: 1,
      } as UserViewModel)
      .then(() => {
        setLoading(false);
        setSnackbarSuccess('Account created! Please confirm your email address before logging in');
        navigate('/');
      })
      .catch(() => {
        setLoading(false);
        setSnackbarError('Could not login. Please check your account details and try again');
      });
  };

  return (
    <Container maxWidth='xs' sx={{ padding: 10, textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={Logo} alt='Logo' style={{ width: 50, marginBottom: 10 }} />
        <Typography component='h1' variant='h5'>
          Create Account
        </Typography>
        <Box component='form' noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='firstName'
            label='First Name'
            name='firstName'
            autoComplete='firstName'
            autoFocus
            value={firstName}
            onChange={event => setFirstName(event.target.value)}
            error={nameError}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            id='lastName'
            label='Last Name'
            name='lastName'
            autoComplete='lastName'
            value={lastName}
            onChange={event => setLastName(event.target.value)}
            error={nameError}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            value={email}
            onChange={event => setEmail(event.target.value)}
            error={emailError}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={event => setPassword(event.target.value)}
            onKeyDown={event => event.key === 'Enter' && createAccount()}
            error={passwordError}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='passwordConfirm'
            label='Confirm Password'
            type='password'
            id='passwordConfirm'
            autoComplete='password'
            value={confirmPassword}
            onChange={event => setConfirmPassword(event.target.value)}
            onKeyDown={event => event.key === 'Enter' && createAccount()}
            error={passwordError}
          />
          <PasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={isValid => setPasswordsValue(isValid)}
          />
          <FormControlLabel
            control={<Checkbox value={acceptedTos} onChange={(_, checked) => setAcceptedTos(checked)} />}
            label={
              <Typography>
                I have read and agree to the{' '}
                <a style={{ textDecoration: 'underline' }} href='/termsOfUse' target='_blank'>
                  Terms of Use
                </a>
                ,{' '}
                <a style={{ textDecoration: 'underline' }} href='/cookies' target='_blank'>
                  Cookie Policy
                </a>
                ,{' '}
                <a style={{ textDecoration: 'underline' }} href='/privacy' target='_blank'>
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a style={{ textDecoration: 'underline' }} href='/disclaimer' target='_blank'>
                  Disclaimer
                </a>
              </Typography>
            }></FormControlLabel>
          {(nameError || emailError || passwordError) && (
            <Typography style={{ color: 'red' }}>Invalid details entered</Typography>
          )}
          <LoadingButton loading={loading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={createAccount}>
            Create Account
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href='/' variant='body2'>
                Back
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateAccount;
