import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HelpIcon from '../elements/help-icon/HelpIcon';
import GroupedButtons from '../elements/grouped-buttons/GroupedButtons';
import { GetPdfPreferences, PdfPreferences, SetPdfPreferences } from '../../utils/PDFReadingPreferences';
import { zIndex } from '../../Theme';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const refresh = (): void => {
  window.location.reload();
};

const SetListViewSettingsModal = (props: Props): JSX.Element => {
  const [preferences, setPreferences] = useState<PdfPreferences>();
  const [transposeAmountForId, setTransposeAmountForId] = useState<number>();
  const [id, setId] = useState<string>();

  const [needsToReload, setNeedsToReload] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const idFromParam = queryParameters.get('id');

    if (idFromParam) {
      setId(idFromParam);
    }

    const pref = GetPdfPreferences();
    setPreferences(pref);
    setTransposeAmountForId(
      pref.setListToMasterTranspose && idFromParam ? pref.setListToMasterTranspose[idFromParam] : undefined
    );
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      fullWidth
      maxWidth='sm'
      style={{ zIndex: zIndex.thirdModal }}>
      <DialogContent>
        <Typography variant='h4' style={{ marginBottom: '20px' }}>
          Music View Settings
        </Typography>

        <div style={{ marginLeft: '16px', display: 'flex', gap: '9px', marginBottom: '20px' }}>
          <div>
            <HelpIcon message='Increase or decrease the font size of generated PDFs' />
            Music Font Size
          </div>
          <GroupedButtons
            counter={preferences?.fontSize || 12}
            setCounter={value =>
              setPreferences(x => {
                const pref = { ...(x as PdfPreferences), fontSize: value };

                setNeedsToReload(true);

                return pref;
              })
            }
            title='Font Size'
            min={5}
            max={30}
          />
        </div>

        <FormControlLabel
          labelPlacement='start'
          control={
            <Checkbox
              checked={preferences?.hideCapo || false}
              onChange={(_, checked) =>
                setPreferences(x => {
                  const pref = { ...(x as PdfPreferences), hideCapo: checked };

                  setNeedsToReload(true);

                  return pref;
                })
              }
            />
          }
          label={
            <>
              <HelpIcon message='If a capo has been added to any songs, it will be removed along with any transpositions' />
              Remove Capo From Music
            </>
          }
        />
        <FormControlLabel
          labelPlacement='start'
          control={
            <Checkbox
              checked={preferences?.disableSetListProgressSharing || false}
              onChange={(_, checked) =>
                setPreferences(x => {
                  const pref = { ...(x as PdfPreferences), disableSetListProgressSharing: checked };

                  setNeedsToReload(true);

                  return pref;
                })
              }
            />
          }
          label={
            <>
              <HelpIcon message='Disable functionality that shares which page you are on when viewing a setlist with others' />
              Disable Set List Progress Sharing
            </>
          }
        />
        {id && (
          <div style={{ marginLeft: '16px', display: 'flex', gap: '9px', marginTop: '20px' }}>
            <div>
              <HelpIcon message='Increase or decrease the base transpose amount on all songs in this set' />
              Master Transpose For Song / Set List
            </div>
            <GroupedButtons
              counter={transposeAmountForId || 0}
              setCounter={value =>
                setTransposeAmountForId(() => {
                  const transposeValue = value % 12;

                  if (transposeValue === 0) {
                    return undefined;
                  }

                  setNeedsToReload(true);

                  return transposeValue;
                })
              }
              title='Master Transpose'
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            // Update the preferences if there has been a chage
            if (needsToReload && preferences) {
              let transposeValues = preferences.setListToMasterTranspose;

              // If we have an id, update the master transpose for the song / set list
              if (id) {
                // Set the master transpose value to the updated value
                if (transposeValues) {
                  // Update the value if we need to create it
                  if (transposeAmountForId) {
                    transposeValues[id] = transposeAmountForId;
                  }

                  // Otherwise delete the transpose value if it exists
                  else if (Object.keys(transposeValues).includes(id)) {
                    delete transposeValues[id];
                  }
                } else {
                  if (transposeAmountForId) {
                    transposeValues = {};
                    transposeValues[id] = transposeAmountForId;
                  }
                }
              }

              SetPdfPreferences({ ...preferences, setListToMasterTranspose: transposeValues });
            }

            // If we have an id, changes we make here will impact everything live, so we should refresh the page
            if (needsToReload && id) {
              refresh();
              return;
            }

            props.setOpen(false);
          }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetListViewSettingsModal;
