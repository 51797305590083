import React from 'react';
import { Chip, Typography } from '@mui/material';

export interface ChipOptions {
  id: number;
  name: string;
}

interface Props {
  label: string;
  chipValues: ChipOptions[];
  setChipValues: React.Dispatch<React.SetStateAction<ChipOptions[]>>;
  onRemoveValue: (removeValue: ChipOptions) => void;
}

const ChipList = (props: Props): JSX.Element => (
  <>
    <Typography variant='h6'>Shared Users</Typography>
    {props.chipValues.length === 0 ? (
      <Typography>
        You&apos;ve not invited anyone to your set list yet (or no-one&apos;s accepted your request).
      </Typography>
    ) : (
      props.chipValues.map(x => (
        <Chip
          key={x.id}
          label={x.name}
          variant='outlined'
          onDelete={() => {
            props.setChipValues(y => {
              y = y.filter(z => z.id !== x.id);

              return y;
            });
            props.onRemoveValue(x);
          }}
        />
      ))
    )}
  </>
);

export default ChipList;
