import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  actionButton: string;
  onAction: () => void;
}

const WarningModal = (props: Props): JSX.Element => (
  <Dialog
    open={props.open}
    onClose={() => props.setOpen(false)}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'>
    <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>{props.description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.setOpen(false)}>Cancel</Button>
      <Button onClick={props.onAction}>{props.actionButton}</Button>
    </DialogActions>
  </Dialog>
);

export default WarningModal;
