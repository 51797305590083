import React from 'react';
import { Container, Popper } from '@mui/material';
import YouTubePlayer from './YouTubePlayer/YouTubePlayer';
import { zIndex } from '../../../../Theme';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  songLink: string | undefined;
  anchor: React.MutableRefObject<Element | undefined>;
}

const PlaybackModal = (props: Props): JSX.Element =>
  props.anchor?.current !== null ? (
    <Popper
      open={props.anchor?.current !== undefined}
      anchorEl={props.anchor.current}
      style={{
        zIndex: zIndex.firstModal,
        backgroundColor: 'white',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        display: props.open ? undefined : 'none',
      }}
      placement='top'>
      <Container style={{ padding: 5, margin: 0, display: 'inline-block', width: '360px', transition: 'height 1s' }}>
        {props.songLink && <YouTubePlayer url={props.songLink} open={props.open} setIsPlaying={props.setIsPlaying} />}
      </Container>
    </Popper>
  ) : (
    <></>
  );

export default PlaybackModal;
