import React, { useState } from 'react';
import { Save } from '@mui/icons-material';
import { CircularProgress, Container, IconButton, Popper, Tooltip } from '@mui/material';
import { SetListClient, SetListViewModel, SongClient, SongViewModel } from '../../../../../types/auto/types';
import { Configuration } from '../../../../Constants';
import { FetchOverride } from '../../../../utils/Requests';
import GroupedButtons from '../../grouped-buttons/GroupedButtons';
import { zIndex } from '../../../../Theme';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  transposeAmount: number;
  setTransposeAmount: (transpose: number) => void;
  setList?: SetListViewModel;
  song?: SongViewModel;
  pdfDisplayType: PdfDisplayType;
  anchor: React.MutableRefObject<Element | undefined>;
}

const PdfTransposeModal = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchor.current}
      style={{ zIndex: zIndex.firstModal, backgroundColor: 'white', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}
      placement='top'>
      <Container style={{ padding: 5, margin: 0, display: 'inline-block' }}>
        <GroupedButtons
          counter={props.transposeAmount}
          setCounter={value => props.setTransposeAmount(value % 12)}
          title='Set Transpose After Set List Master Transpose Has Been Applied'
        />
        <Tooltip title='Save Transpose' placement='top' style={{ zIndex: zIndex.tooltip }}>
          <IconButton
            aria-label='Save Transpose'
            onClick={() => {
              setLoading(true);
              if (props.pdfDisplayType === PdfDisplayType.Song) {
                new SongClient(Configuration.SERVER_ROOT, FetchOverride)
                  .setTranspositonAmount(props.song?.id, props.transposeAmount)
                  .catch(() => console.log('Could not save song transpose amount'))
                  .finally(() => setLoading(false));
              } else {
                new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                  .setTransposeForSongInSetList(props.setList?.id, props.song?.id, props.transposeAmount)
                  .catch(() => console.log('Could not save set list transpose amount'))
                  .finally(() => setLoading(false));
              }
            }}>
            {loading ? <CircularProgress size='20px' /> : <Save />}
          </IconButton>
        </Tooltip>
      </Container>
    </Popper>
  );
};

export default PdfTransposeModal;

export enum PdfDisplayType {
  SetList,
  Song,
}
