import React, { useContext, useState } from 'react';
import { Button, Card, Container, TextField, Typography } from '@mui/material';
import { UserContext, UserDispatchContext } from '../utils/UserStorage';
import { LoadingButton } from '@mui/lab';
import { SetSnackbarErrorContext, SetSnackbarSuccessContext } from '../components/elements/snackbar/SnackbarContext';
import { AuthenticationClient, UserClient, UserViewModel } from '../../types/auto/types';
import { Configuration } from '../Constants';
import { FetchOverride } from '../utils/Requests';
import { isEmailInvalid } from '../utils/EmailChecker';
import { useNavigate } from 'react-router-dom';
import WarningModal from '../components/elements/warning-modal/WarningModal';

const AccountManagement = (): JSX.Element => {
  const user = useContext(UserContext);
  const userDispatch = useContext(UserDispatchContext);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.firstName || '');
  const [email, setEmail] = useState(user.email || '');
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [deleteAccountWarningModalOpen, setDeleteAccountWarningModalOpen] = useState(false);

  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  return (
    <>
      <WarningModal
        open={deleteAccountWarningModalOpen}
        setOpen={setDeleteAccountWarningModalOpen}
        title='Delete Account'
        description='Warning. This will delete your account and all of your data forever. This action cannot be undone'
        actionButton='Delete Account'
        onAction={() => {
          new UserClient(Configuration.SERVER_ROOT, FetchOverride)
            .deleteUser()
            .then(() => {
              setSnackbarSuccess('Account was deleted successfully');
              userDispatch({} as UserViewModel);
              navigate('/');
            })
            .catch(() => setSnackbarError('Account could not be deleted at this time. Please try again later'));
        }}
      />
      <Card sx={{ padding: 2, maxWidth: '500px', margin: 'auto' }}>
        <Typography variant='h5' sx={{ paddingTop: 2, display: 'inline-block' }}>
          Account
        </Typography>
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '5px',
            margin: 0,
            maxWidth: '500px',
          }}>
          <Typography variant='h6' sx={{ paddingTop: 2, display: 'inline-block', textAlign: 'center' }}>
            Account Details
          </Typography>
          <Container
            style={{ display: 'flex', flexDirection: 'row', margin: 0, padding: 0, width: '100%', marginTop: '20px' }}>
            <TextField
              label='First Name'
              style={{ width: '48%', marginRight: '1%' }}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              error={nameError}
            />
            <TextField
              label='Last Name'
              style={{ width: '48%', marginLeft: '1%' }}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              error={nameError}
            />
          </Container>

          <TextField
            label='Email'
            style={{ marginTop: '20px' }}
            error={emailError}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <LoadingButton
            loading={userDataLoading}
            onClick={() => {
              setUserDataLoading(true);

              if (firstName.replace(' ', '').length === 0 || lastName.replace(' ', '').length === 0) {
                setUserDataLoading(false);
                setNameError(true);
                setEmailError(false);
                setSnackbarError('Invalid name supplied');
                return;
              }

              if (isEmailInvalid(email)) {
                setUserDataLoading(false);
                setEmailError(true);
                setNameError(false);
                setSnackbarError('Invalid email address supplied');
                return;
              }

              new UserClient(Configuration.SERVER_ROOT, FetchOverride)
                .updateUser({ ...user, firstName: firstName, lastName: lastName, email: email } as UserViewModel)
                .then(() => {
                  setUserDataLoading(false);
                  setNameError(false);
                  const emailChanged = email !== user.email;
                  userDispatch({ ...user, firstName: firstName, lastName: lastName } as UserViewModel);
                  setSnackbarSuccess(
                    'User data updated' + (emailChanged ? '. Please confirm your email address before logging in' : '')
                  );

                  if (emailChanged) {
                    new AuthenticationClient(Configuration.SERVER_ROOT, FetchOverride)
                      .logout()
                      .then(() => {
                        userDispatch({} as UserViewModel);
                        navigate('/login');
                      })
                      .catch(() =>
                        setSnackbarError('An unknown error occurred and you could not be logged out. Please try again')
                      );
                  }
                })
                .catch(() => {
                  setSnackbarError('Could not update user data. Please try again later');
                  setUserDataLoading(false);
                });
            }}>
            Update
          </LoadingButton>
        </Container>
        <Container style={{ display: 'flex', flexDirection: 'column', padding: '5px', margin: 0, maxWidth: '500px' }}>
          <Typography
            variant='h6'
            sx={{ paddingTop: 2, display: 'inline-block', marginTop: '20px', textAlign: 'center' }}>
            Account Management
          </Typography>
          <Button
            style={{ color: 'red', textAlign: 'left', width: 'auto' }}
            onClick={() => setDeleteAccountWarningModalOpen(true)}>
            Delete Account
          </Button>
        </Container>
      </Card>
    </>
  );
};

export default AccountManagement;
