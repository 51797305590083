import React, { createContext, useReducer } from 'react';
import { UserViewModel } from '../../types/auto/types';
import { StorageAccess } from './CookieStorage';

interface Props {
  children?: JSX.Element;
}

const UserStore = ({ children }: Props): React.ReactElement => {
  const CURRENTUSER_KEY = 'currentUser';
  const getCurrentUser = (): UserViewModel => {
    const store = StorageAccess.userStorage;
    const user: string = store?.fetch(CURRENTUSER_KEY) ?? '';

    if (user.length > 0) {
      return JSON.parse(user) as UserViewModel;
    }

    return {} as UserViewModel;
  };

  const reducer = (prevUser: UserViewModel, newUser: UserViewModel): UserViewModel => {
    const store = StorageAccess.userStorage;
    store?.set(CURRENTUSER_KEY, JSON.stringify(newUser));

    return newUser;
  };

  const [user, dispatchUser] = useReducer(reducer, getCurrentUser());

  return (
    <UserDispatchContext.Provider value={dispatchUser}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </UserDispatchContext.Provider>
  );
};

/** Allows the User state to be updated. */
export const UserDispatchContext = createContext((() => 0) as React.Dispatch<UserViewModel>);

/** Returns the current user state. */
export const UserContext = createContext({} as UserViewModel);

export default UserStore;
