import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Popover, PopoverProps, TextField, Tooltip, Typography } from '@mui/material';
import { SongClient, SongViewModel } from '../../../../../types/auto/types';
import { LoadingButton } from '@mui/lab';
import { Configuration } from '../../../../Constants';
import { FetchOverride } from '../../../../utils/Requests';
import { SetSnackbarErrorContext } from '../../snackbar/SnackbarContext';
import { colours, zIndex } from '../../../../Theme';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import debounce from 'lodash.debounce';

const AdhocSongSearchPopover = ({ ...props }: PopoverProps): JSX.Element => {
  const [allSongs, setAllSongs] = useState<SongViewModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [render, setRerender] = useState(false);
  const [selectedSong, setSelectedSong] = useState<SongViewModel>();
  const [loading, setLoading] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  // Get all of the songs for the user
  useEffect(() => {
    setLoading(true);
    new SongClient(Configuration.SERVER_ROOT, FetchOverride)
      .getSongs(searchTerm, true, true, 100, 0, false)
      .then(x => {
        if (x.values === undefined) {
          return;
        }
        const songs = x.values.sort((a, b) => a.name?.localeCompare(b.name || '') || 0);
        setAllSongs(songs.filter((val, index, self) => self.indexOf(val) === index));
      })
      .catch(() => setSnackbarError('Could not get songs. Please try again later'))
      .finally(() => setLoading(false));
  }, [setAllSongs, searchTerm, render]);

  const debouncedResults = useMemo(() => {
    setLoading(true);
    return debounce((value: string) => {
      setSearchTerm(value);
      setRerender(x => !x);
    }, 800);
  }, [setSearchTerm, setRerender, setLoading]);

  useEffect(() => () => debouncedResults.cancel());

  return (
    <Popover {...props} style={{ zIndex: zIndex.firstModal }}>
      <Box style={{ padding: '20px', zIndex: zIndex.firstModal, position: 'relative' }}>
        <Typography variant='h6' sx={{ mb: '20px', fontSize: '15px' }}>
          Adhoc Song Search
        </Typography>
        <div
          style={{
            overflow: 'auto',
            paddingTop: 15,
            width: window.innerWidth > 500 ? '500px' : window.innerWidth - 75,
          }}>
          <Autocomplete
            componentsProps={{ popper: { style: { zIndex: zIndex.secondModal } } }}
            options={loading ? [] : allSongs} // Loading only displays if the options are blank
            value={selectedSong}
            loading={loading}
            onClose={() => window.scrollTo(0, 0)} // Scroll to top as on mobile the scrolling can get messed up
            getOptionLabel={x => x.name + (x.artist ? ' - ' + x.artist : '')}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.text ? (
                  <Tooltip title='Song Has Uploaded Chords'>
                    <TextFieldsIcon
                      sx={{ color: colours.textGray, fontSize: 20, marginRight: '5px', display: 'inline-block' }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title='Song Has Uploaded PDF'>
                    <PictureAsPdfIcon
                      sx={{ color: colours.textGray, fontSize: 20, marginRight: '5px', display: 'inline-block' }}
                    />
                  </Tooltip>
                )}
                {option.name + (option.artist ? ' - ' + option.artist : '')}
              </li>
            )}
            onChange={(_, value) => {
              setSelectedSong(value ? value : undefined);
            }}
            renderInput={params => <TextField {...params} label='Add Song' />}
            filterOptions={options => options}
            onInputChange={(_, value) => debouncedResults(value)}
          />
        </div>
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: '10px' }}>
          <LoadingButton
            disabled={selectedSong === undefined}
            loading={loading}
            onClick={() => {
              window.open(Configuration.SERVER_ROOT + '/song?id=' + selectedSong?.id, '_blank');
              setSelectedSong(undefined);
            }}>
            Open Song In New Tab
          </LoadingButton>
        </Box>
      </Box>
    </Popover>
  );
};

export default AdhocSongSearchPopover;
