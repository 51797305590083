import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { AnnotationSettings } from '../../../../types/AnnotationSettings';
import { TextAnnotationViewModel } from '../../../../types/auto/types';
import TextInputAnnotation from './TextInputAnnotation';

interface Props {
  textInputs: TextAnnotationViewModel[];
  editable: boolean;
  zIndex?: number;
  canvasWidth: string;
  canvasHeight: string;
  annotationSettings?: AnnotationSettings;
  setTextInputs: (textInputs: TextAnnotationViewModel[]) => void;
  zoomLevel: number;
  showAnnotations: boolean;
}

const TextInputAnnotationController = (props: Props): JSX.Element => {
  const textInputContainerRef = useRef<HTMLDivElement>(null);
  const [textInputs, setTextInputs] = useState<TextAnnotationViewModel[]>([]);
  const [, setRerender] = useState(false);

  useEffect(() => {
    setTextInputs(props.textInputs);
  }, [props.textInputs, setTextInputs]);

  return (
    <div
      ref={textInputContainerRef}
      style={{
        width: props.canvasWidth,
        height: props.canvasHeight,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: props.zIndex,
        display: props.showAnnotations ? undefined : 'none',
      }}
      onClick={e =>
        props.editable &&
        setTextInputs(x => {
          const getActualCoordsAsPercentages = (
            ev: React.MouseEvent<HTMLDivElement, MouseEvent>
          ): { x: string; y: string; size: number } => {
            const rect = (ev.target as Element).getBoundingClientRect();
            return {
              x: ((ev.clientX - rect.x) / rect.width) * 100 + '%',
              y: ((ev.clientY - rect.y - 20) / rect.height) * 100 + '%',
              size: (props.annotationSettings?.size || 10) / rect.height,
            };
          };
          const newCoords = getActualCoordsAsPercentages(e);

          // Check that there isn't already a text value at this coord
          if (x.filter(y => y.x === newCoords.x && y.y === newCoords.y).length > 0) {
            return x;
          }

          const textAnnotation = new TextAnnotationViewModel();
          textAnnotation.text = '';
          textAnnotation.x = newCoords.x;
          textAnnotation.y = newCoords.y;
          textAnnotation.colour = props.annotationSettings?.colour || 'black';
          textAnnotation.textSize = newCoords.size;

          x.push(textAnnotation);
          setRerender(z => !z);
          return x;
        })
      }>
      {textInputs.map(x => (
        <TextInputAnnotation
          key={x.text + ' ' + x.x + ' ' + x.y}
          textAnnotationControllerRef={textInputContainerRef}
          annotation={x}
          disabled={!props.editable}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          zoomLevel={props.zoomLevel}
          zIndex={props.zIndex}
          onBlur={e => {
            // Store the text inputs, so we can send them to the backend before the state updates
            let temporaryTextInputs = [] as TextAnnotationViewModel[];

            // If the text is blank, remove the annotation
            if (e.target.value.replace(' ', '').replace('\n', '') === '') {
              setTextInputs(x => {
                temporaryTextInputs = x.filter(y => y.x + ',' + y.y !== e.target.id);
                return temporaryTextInputs;
              });
            }
            // If the text isn't blank, update the text inputs
            else {
              setTextInputs(x => {
                temporaryTextInputs = x.map(y => {
                  if (y.x + ',' + y.y === e.target.id) {
                    y.text = e.target.value;
                  }

                  return y;
                });

                return temporaryTextInputs;
              });
            }

            // Update the stored data here on the backend
            props.setTextInputs(temporaryTextInputs);
            window.scrollTo(0, 0);
          }}
        />
      ))}
    </div>
  );
};

export default TextInputAnnotationController;
