import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { colours } from '../../../Theme';
import { Typography } from '@mui/material';

interface Props {
  setFile: (file: File) => void;
}

const dragNDropText = "Drag 'n' drop or click to select a pdf";
const dropText = 'Drop the pdf here';

const FileUpload = (props: Props): JSX.Element => {
  const [text, setText] = useState(dragNDropText);
  const [dragOver, setDragOver] = useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      setText(file.name);
      props.setFile(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { 'application/pdf': [], 'application/x-pdf': [] },
    onDragOver: () => {
      setDragOver(true);
      setText(dropText);
    },
    onDragLeave: () => {
      setDragOver(false);
      setText(dragNDropText);
    },
  });

  return (
    <div {...getRootProps()} style={{ width: '98%' }}>
      <input {...getInputProps()} />
      <div
        style={{
          cursor: 'pointer',
          width: '100%',
          borderRadius: 10,
          border: (dragOver ? '2' : '1') + 'px dashed ' + colours.textGray,
          verticalAlign: 'center',
          textAlign: 'center',
          padding: 10,
        }}>
        <FileUploadIcon />
        <Typography>{text}</Typography>
      </div>
    </div>
  );
};

export default FileUpload;
