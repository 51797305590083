import { EveryChordWith2SpacesAfterRegex } from '../components/elements/pdf-display/transpose/PdfTransposableLayer';

// Given the complete chord text from a song (including lyrics) determine the most likely key
export const GetKeyFromChordText = (chordText: string): string | undefined => {
  const chordMatches = chordText.match(EveryChordWith2SpacesAfterRegex);

  if (chordMatches === null) {
    return undefined;
  }

  // Dedupe the array so we have as few chords as possible
  const dedupedChordMatches = chordMatches
    .map(x => x.replace(/\s/g, ''))
    .filter((element, index, self) => self.indexOf(element) === index);

  // Get a list of all possible keys, ordered from most likely to least
  const possibleKeys = GuessKeysFromChords(dedupedChordMatches);

  // Take the most likely
  return possibleKeys[0][0];
};

// Given a starting key and a transposition number, transpose the set
export const GetKeyForTransposition = (startingKey: string, transposition: number): string => {
  // Get all of the keys that are valid given the starting key
  const filteredKeys = GetValidKeysForTransposition(startingKey);

  // Get the starting key, add the transposition and 2 full key changes to ensure all numbers are positive. Mod this by 12 to bring back in the bounds of the array
  const keyIndex = (filteredKeys.indexOf(startingKey) + transposition + filteredKeys.length * 2) % filteredKeys.length;

  return filteredKeys[keyIndex];
};

// Given a starting key and ending key, presuming that only valid keys are used, find the transposition amount
export const GetTranspositionForKeyDifference = (startingKey: string, endingKey: string): number => {
  // Get all of the keys that are valid given the starting key
  const filteredKeys = GetValidKeysForTransposition(startingKey);

  // Get the starting key index and the ending key index
  const startingKeyIndex = filteredKeys.indexOf(startingKey);
  const endingKeyIndex = filteredKeys.indexOf(endingKey);

  return endingKeyIndex - startingKeyIndex;
};

// Given a list of chords, gives the likelihood of different keys being chosen - sorted so the first item is the most likely key
export const GuessKeysFromChords = (chordsToFind: string[]): [string, number][] => {
  // Get the
  const keyToNumberOfMatching: [string, number][] = Object.entries(keyChart).map(keyValues => {
    const key = keyValues[0];
    const chords = keyValues[1];

    const numberOfMatchingChords = chords.filter(chordInKey => chordsToFind.includes(chordInKey)).length;

    return [key, numberOfMatchingChords];
  });

  return keyToNumberOfMatching.sort((a, b) => b[1] - a[1]);
};

// The key chart of every chord for a given key
const keyChart: Record<string, string[]> = {
  C: ['C', 'Dm', 'Em', 'F', 'G', 'Am', 'Bm♭5'],
  'C#': ['C#', 'D#m', 'E#m', 'F#', 'G#', 'A#m', 'B#m♭5'],
  'D♭': ['D♭', 'E♭m', 'Fm', 'G♭', 'A♭', 'B♭m', 'Cm♭5'],
  D: ['D', 'Em', 'F#m', 'G', 'A', 'Bm', 'C#m♭5'],
  'E♭': ['E♭', 'Fm', 'Gm', 'A♭', 'B♭', 'Cm', 'Dm♭5'],
  E: ['E', 'F#m', 'G#m', 'A', 'B', 'C#m', 'D#m♭5'],
  F: ['F', 'Gm', 'Am', 'B♭', 'C', 'Dm', 'Em♭5'],
  'F#': ['F#', 'G#m', 'A#m', 'B', 'C#', 'D#m', 'E#m♭5'],
  'G♭': ['G♭', 'A♭m', 'B♭m', 'C♭', 'D♭', 'E♭m', 'Fm♭5'],
  G: ['G', 'Am', 'Bm', 'C', 'D', 'Em', 'F#m♭5'],
  'A♭': ['A♭', 'B♭m', 'Cm', 'D♭', 'E♭m', 'Fm', 'Gm♭5'],
  A: ['A', 'Bm', 'C#m', 'D', 'E', 'F#m', 'G#m♭5'],
  'B♭': ['B♭', 'Cm', 'Dm', 'E♭', 'F', 'Gm', 'Am♭5'],
  B: ['B', 'C#m', 'D#m', 'E', 'F#', 'G#m', 'A#m♭5'],
  Am: ['Am', 'Bm♭5', 'C', 'Dm', 'Em', 'F', 'G'],
  'A#m': ['A#m', 'B#m♭5', 'C#', 'D#m', 'E#m', 'F#', 'G#'],
  'B♭m': ['B♭m', 'Cm♭5', 'D♭', 'E♭m', 'Fm', 'G♭', 'A♭'],
  Bm: ['Bm', 'C#m♭5', 'D', 'Em', 'F#m', 'G', 'A'],
  Cm: ['Cm', 'Dm♭5', 'E♭', 'Fm', 'Gm', 'A♭', 'B♭'],
  'C#m': ['C#m', 'Dm♭5', 'E', 'F#m', 'G#m', 'A', 'B'],
  Dm: ['Dm', 'Em♭5', 'F', 'Gm', 'Am', 'B♭', 'C'],
  'D#m': ['D#m', 'E#m♭5', 'F#', 'G#m', 'A#m', 'B', 'C#'],
  'E♭m': ['E♭m', 'Fm♭5', 'G♭', 'A♭m', 'B♭m', 'C♭', 'D♭'],
  Em: ['Em', 'F#m♭5', 'G', 'Am', 'Bm', 'C', 'D'],
  Fm: ['Fm', 'Gm♭5', 'A♭', 'B♭m', 'Cm', 'D♭', 'E♭m'],
  'F#m': ['F#m', 'G#m♭5', 'A', 'Bm', 'C#m', 'D', 'E'],
  Gm: ['Gm', 'Am♭5', 'B♭', 'Cm', 'Dm', 'E♭', 'F'],
  'G#m': ['G#m', 'A#m♭5', 'B', 'C#', 'D#', 'E', 'F#'],
};

export const keys = [
  'C',
  'C#',
  'D♭',
  'D',
  'D#',
  'E♭',
  'E',
  'F',
  'F#',
  'G♭',
  'G',
  'G#',
  'A♭',
  'A',
  'A#',
  'B♭',
  'B',
  'A♭m',
  'Am',
  'A#m',
  'B♭m',
  'Bm',
  'Cm',
  'C#m',
  'D♭m',
  'Dm',
  'D#m',
  'E♭m',
  'Em',
  'Fm',
  'F#m',
  'G♭m',
  'Gm',
  'G#m',
];

export const GetValidKeysForTransposition = (startingKey: string): string[] => {
  // Work out if the starting key is minor or major
  const isMinor = startingKey.includes('m');

  // If the starting key has a flat in it, remove any sharps as we'll just transpose in flats. Otherwise, we'll transpose in sharps - also remove major / minor keys that are irrelevant
  let filteredKeys = keys;
  if (startingKey.includes('♭')) {
    filteredKeys = filteredKeys.filter(x => !x.includes('#') && (isMinor ? x.includes('m') : !x.includes('m')));
  } else {
    filteredKeys = filteredKeys.filter(x => !x.includes('♭') && (isMinor ? x.includes('m') : !x.includes('m')));
  }

  return filteredKeys;
};
