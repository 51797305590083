import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { zIndex } from '../Theme';

const BackButton = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box sx={{ position: 'absolute', left: 20 }}>
      <Tooltip title='Back' style={{ zIndex: zIndex.tooltip }}>
        <IconButton onClick={() => navigate(-1)} sx={{ p: 0 }}>
          <ArrowBack sx={{ fontSize: 35, color: 'white' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default BackButton;
