import React, { useState } from 'react';
import { Box, ButtonBase, Card, Container, IconButton, Tooltip, Typography } from '@mui/material';
import { colours, theme } from '../../Theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import moment from 'moment';
import { SongViewModel } from '../../../types/auto/types';
import SongCardMenu from './SongCardMenu';
import SongKeyChip from '../elements/song-key-chip/SongKeyChip';

interface Props {
  song: SongViewModel;
  onEdit?: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const SongCard = (props: Props): JSX.Element => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };
  return (
    <Card
      variant='outlined'
      sx={{
        position: 'relative',
        height: '100%',
      }}>
      <ButtonBase
        sx={{
          padding: 2,
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block',
          textAlign: 'left',
          verticalAlign: 'top',
          width: '100%',
          height: '100%',

          '&:hover': { backgroundColor: theme.palette.background.paper, transition: 'background-color 500ms' },
        }}
        onClick={() => (window.location.href = '/song?id=' + props.song.id)}>
        <Container style={{ padding: 0, margin: 0, width: '90%', height: '100%' }}>
          <Typography variant='h6' sx={{ display: 'inline-block' }}>
            {props.song.name}
          </Typography>
          {props.song.artist && (
            <Typography sx={{ color: colours.textGray, display: 'inline-block', marginLeft: 1 }}>
              by {props.song.artist}
            </Typography>
          )}
          <SongKeyChip
            songKey={props.song.key}
            songName={props.song.name}
            marginTop='-5px'
            transposeAmount={props.song.storedTransposition}
          />
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {props.song.text ? (
              <Tooltip title='Song Has Uploaded Chords'>
                <TextFieldsIcon
                  sx={{ color: colours.textGray, fontSize: 13, marginRight: '5px', display: 'inline-block' }}
                />
              </Tooltip>
            ) : (
              <Tooltip title='Song Has Uploaded PDF'>
                <PictureAsPdfIcon
                  sx={{ color: colours.textGray, fontSize: 13, marginRight: '5px', display: 'inline-block' }}
                />
              </Tooltip>
            )}
            <Typography sx={{ color: colours.textGray, fontSize: 13 }}>
              Uploaded {moment(props.song.created).format('DD/MM/YYYY')}
            </Typography>
          </Box>
        </Container>
      </ButtonBase>
      <IconButton
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          handleOpenUserMenu(event);
        }}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
        }}>
        <MoreVertIcon />
      </IconButton>
      <SongCardMenu
        song={props.song}
        anchorElUser={anchorElUser}
        setAnchorElUser={setAnchorElUser}
        onEdit={props.onEdit}
        setRefresh={props.setRefresh}
      />
    </Card>
  );
};

export default SongCard;
