import * as React from 'react';
import DraggableItem from './DraggableItem';
import update from 'immutability-helper';
import { SongViewModel } from '../../../../types/auto/types';
import { useCallback } from 'react';

type Props = {
  songs: SongViewModel[];
  setSongs: React.Dispatch<React.SetStateAction<SongViewModel[]>>;
  rerender: () => void;
  isSetListView: boolean;
  editCard?: (song: SongViewModel) => void;
  jumpToCard?: (song: SongViewModel) => void;
  forceMobileView?: boolean;
  viewOnly?: boolean;
  onUpdate?: () => void;
  setSongTransposition: (song: SongViewModel, newTransposition: number) => void;
};

const DraggableList = (props: Props): JSX.Element => {
  {
    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
      props.onUpdate && props.onUpdate();

      props.setSongs((prevSongs: SongViewModel[]) =>
        update(prevSongs, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevSongs[dragIndex] as SongViewModel],
          ],
        })
      );
    }, []);

    const renderCard = useCallback(
      (song: SongViewModel, index: number, totalIndex: number) => (
        <DraggableItem
          key={song.id + Math.random()}
          index={index}
          totalIndex={totalIndex}
          id={song.id}
          isSetListView={props.isSetListView}
          song={song}
          moveCard={moveCard}
          deleteCard={() => {
            props.setSongs(x => {
              x.splice(index, 1);
              return x;
            });
            props.rerender();

            props.onUpdate && props.onUpdate();
          }}
          jumpToCard={props.jumpToCard}
          editCard={props.editCard}
          forceMobileView={props.forceMobileView}
          viewOnly={props.viewOnly}
          setSongTransposition={props.setSongTransposition}
        />
      ),
      []
    );

    return (
      <>
        <div>{props.songs.map((song, i) => renderCard(song, i, props.songs.length))}</div>
      </>
    );
  }
};

export default DraggableList;
