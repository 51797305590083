export const EveryChordRegex = /\b[CDEFGAB]#?b?m?(minor)?(min)?(maj)?(major)?(sus)?(dim)?(alt)?(aug)?5?2?4?6?9?7?(7b5)?(9b5)?(7b9)?(7#9)?(11)?(\s|$)/g;
export const EveryChordValueRegex = /\b[CDEFGAB]#?/g;

// This helps us make sure that every value detected is a chord - while potentially leaving some - e.g. `A Whole` whill not be picked up here - this is needed for key chart detection
export const EveryChordWith2SpacesAfterRegex = /\b[CDEFGAB]#?b?m?(minor)?(min)?(maj)?(major)?(sus)?(dim)?(alt)?(aug)?5?2?4?6?9?7?(7b5)?(9b5)?(7b9)?(7#9)?(11)?(\s{2,}|$)/g;

export function transposeChord(chord: string, amount: number): string {
  const scale = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  let endOfString = '';

  // Add extra buffer if there are many spaces already in the string
  if (chord.replace(/\s+/, '').length < chord.length - 2) {
    endOfString = '    ';
  }

  return chord
    .split('/')
    .map(x =>
      x.replace(
        EveryChordRegex,
        match =>
          match.replace(EveryChordValueRegex, chordMatch => {
            const i = (scale.indexOf(chordMatch) + amount) % scale.length;
            return scale[i < 0 ? i + scale.length : i];
          }) + endOfString
      )
    )
    .join('/');
}
