import React from 'react';
import { Input } from '@mui/material';
import { TextAnnotationViewModel } from '../../../../types/auto/types';
import { zIndex } from '../../../Theme';

interface Props {
  annotation: TextAnnotationViewModel;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  zIndex?: number;
  textAnnotationControllerRef: React.RefObject<HTMLDivElement>;
  zoomLevel: number;
}

const TextInputAnnotation = (props: Props): JSX.Element => (
  <Input
    id={props.annotation.x + ',' + props.annotation.y}
    autoFocus={props.annotation.text === ''}
    multiline
    disabled={props.disabled}
    style={{
      position: 'absolute',
      left: props.annotation.x,
      top: props.annotation.y,
      zIndex: props.zIndex || zIndex.textAnnotation,
      pointerEvents: props.disabled ? 'none' : 'auto',
      color: props.annotation.colour,
      fontSize:
        props.annotation.textSize *
        (props.textAnnotationControllerRef.current?.getBoundingClientRect().height || 1) * // Scale the size based on the height of the canvas
          5 || 10,
    }}
    sx={{
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: props.annotation.colour,
      },
    }}
    defaultValue={props.annotation.text}
    disableUnderline
    onClick={e => {
      if (!props.disabled && props.onClick) {
        props.onClick(e);
      }
    }}
    onBlur={props.onBlur}
  />
);

export default TextInputAnnotation;
