import React, { createContext, useState } from 'react';

interface Props {
  children?: JSX.Element;
}

const SnackbarContextProvider = (props: Props): JSX.Element => {
  const [snackbarError, setSnackbarError] = useState<string>();
  const [snackbarSuccess, setSnackbarSuccess] = useState<string>();

  return (
    <SetSnackbarErrorContext.Provider value={setSnackbarError}>
      <SetSnackbarSuccessContext.Provider value={setSnackbarSuccess}>
        <SnackbarSuccessContext.Provider value={snackbarSuccess}>
          <SnackbarErrorContext.Provider value={snackbarError}>{props.children}</SnackbarErrorContext.Provider>
        </SnackbarSuccessContext.Provider>
      </SetSnackbarSuccessContext.Provider>
    </SetSnackbarErrorContext.Provider>
  );
};

/** Allows the User state to be updated. */
export const SetSnackbarErrorContext = createContext((() => 0) as React.Dispatch<string | undefined>);
export const SetSnackbarSuccessContext = createContext((() => 0) as React.Dispatch<string | undefined>);

/** Returns the current user state. */
export const SnackbarErrorContext = createContext<string | undefined>(undefined);
export const SnackbarSuccessContext = createContext<string | undefined>(undefined);

export default SnackbarContextProvider;
