import { createTheme, StyledEngineProvider, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { isMobile } from 'react-device-detect';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import PageContainer from './ts/components/elements/page/PageContainer';
import CustomSnackbar from './ts/components/elements/snackbar/CustomSnackbar';
import SnackbarContextProvider from './ts/components/elements/snackbar/SnackbarContext';
import AuthedRoute from './ts/nav/AuthedRoute';
import CookiePolicy from './ts/policy/CookiePolicy';
import { Disclaimer } from './ts/policy/Disclaimer';
import { PrivacyPolicy } from './ts/policy/PrivacyPolicy';
import { TermsOfUse } from './ts/policy/TermsOfUse';
import { colours } from './ts/Theme';
import UserStore from './ts/utils/UserStorage';
import AccountManagement from './ts/views/AccountManagement';
import NotFound from './ts/views/errors/NotFound';
import Home, { ViewType } from './ts/views/Home';
import ConfirmEmail from './ts/views/login/ConfirmEmail';
import CreateAccount from './ts/views/login/CreateAccount';
import ForgotPassword from './ts/views/login/ForgotPassword';
import Login from './ts/views/login/Login';
import ResetPassword from './ts/views/login/ResetPassword';
import PerformSetList from './ts/views/PerformSetList';
import PerformSong from './ts/views/PerformSong';
import SetListConfirm from './ts/views/setlistconfirm/SetListConfirm';
import ShowNavbarContext from './ts/utils/ShowNavbarContext';

// Cookie consent does not have to be provided for auth cookies - leaving this here for future incase other cookies are stored as part of the application

const enableCookieConsent = false;

function App(): JSX.Element {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider>
        <UserStore>
          <ShowNavbarContext>
            <SnackbarContextProvider>
              <>
                <BrowserRouter>
                  <Routes>
                    <Route path='/login' element={<Login />}></Route>
                    <Route path='/forgotPassword' element={<ForgotPassword />}></Route>
                    <Route path='/resetPassword' element={<ResetPassword />}></Route>
                    <Route path='/createAccount' element={<CreateAccount />}></Route>
                    <Route path='/confirmEmail' element={<ConfirmEmail />}></Route>
                    <Route
                      path='/joinSetList'
                      element={
                        <AuthedRoute>
                          <SetListConfirm />
                        </AuthedRoute>
                      }></Route>
                    <Route
                      path='/'
                      element={
                        <AuthedRoute>
                          <PageContainer title='Home'>
                            <Home viewMode={ViewType.SetList} />
                          </PageContainer>
                        </AuthedRoute>
                      }></Route>
                    <Route
                      path='/setLists'
                      element={
                        <AuthedRoute>
                          <PageContainer title='Set Lists'>
                            <Home viewMode={ViewType.SetList} />
                          </PageContainer>
                        </AuthedRoute>
                      }></Route>
                    <Route
                      path='/songs'
                      element={
                        <AuthedRoute>
                          <PageContainer title='Songs'>
                            <Home viewMode={ViewType.Song} />
                          </PageContainer>
                        </AuthedRoute>
                      }></Route>
                    <Route
                      path='/sharedSetList'
                      element={
                        <PageContainer title='Set List' hideFooter>
                          <PerformSetList isSharedSetListLink />
                        </PageContainer>
                      }></Route>
                    <Route
                      path='/setList'
                      element={
                        <AuthedRoute>
                          <PageContainer title='Set List' hideFooter showBackButton={isMobile}>
                            <PerformSetList />
                          </PageContainer>
                        </AuthedRoute>
                      }></Route>
                    <Route
                      path='/song'
                      element={
                        <AuthedRoute>
                          <PageContainer title='Song' hideFooter showBackButton={isMobile}>
                            <PerformSong />
                          </PageContainer>
                        </AuthedRoute>
                      }></Route>
                    <Route
                      path='/account'
                      element={
                        <AuthedRoute>
                          <PageContainer title='Account'>
                            <AccountManagement />
                          </PageContainer>
                        </AuthedRoute>
                      }></Route>
                    <Route
                      path='/privacy'
                      element={<div dangerouslySetInnerHTML={{ __html: PrivacyPolicy }} />}></Route>
                    <Route path='/cookies' element={<CookiePolicy />}></Route>
                    <Route
                      path='/disclaimer'
                      element={<div dangerouslySetInnerHTML={{ __html: Disclaimer }} />}></Route>
                    <Route
                      path='/termsOfUse'
                      element={<div dangerouslySetInnerHTML={{ __html: TermsOfUse }} />}></Route>
                    <Route path='*' element={<NotFound />}></Route>
                  </Routes>
                </BrowserRouter>
                <CustomSnackbar />
                {enableCookieConsent && (
                  <CookieConsent
                    overlay
                    location='bottom'
                    buttonText='Accept'
                    cookieName='enableCookies'
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                    buttonStyle={{
                      backgroundColor: colours.mainBlue,
                      color: 'white',
                      fontSize: '20px',
                      verticalAlign: 'center',
                    }}
                    overlayStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                    expires={150}>
                    <Typography variant='h4'>🍪 Cookies</Typography>
                    <Typography style={{ marginTop: 10 }}>
                      This site uses cookies to enhance your experience. Please accept our{' '}
                      <a href='/cookies'>Cookie Policy</a> before continuing.
                    </Typography>
                  </CookieConsent>
                )}
              </>
            </SnackbarContextProvider>
          </ShowNavbarContext>
        </UserStore>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
