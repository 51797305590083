import { SetListViewModel } from '../../../../../types/auto/types';
import html2pdf from 'html2pdf.js';

export const waitTimeoutMs = 2000;
const classNameToFetch = 'pageContainer';

const getPages = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doc: any,
  setList: SetListViewModel,
  currentPage: number,
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>,
  totalPages: number,
  setShowAnnotations: React.Dispatch<React.SetStateAction<boolean>>,
  setShowTranspositions: React.Dispatch<React.SetStateAction<boolean>>,
  onComplete: () => void
): void => {
  console.log('Loading Page ' + currentPage);
  let isFirstPage = false;

  // If the document doesn't exist, create it
  if (doc === undefined) {
    doc = html2pdf();
    isFirstPage = true;
  }

  // Get the page
  const elements = document.getElementsByClassName(classNameToFetch);

  // If the page didn't exist, something's gone wrong so we should quit now while we're ahead
  if (elements.length < 0) {
    doc.save(setList.name);
    setCurrentPageNumber(1);
    setShowAnnotations(true);
    setShowTranspositions(true);
    onComplete();
    return;
  }

  // Add the element to the pdf (this is different depending on if it's the first page or not)
  if (isFirstPage) {
    doc = doc.from(elements[0]).toPdf();
  } else {
    doc = doc
      .get('pdf')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((pdf: any) => {
        pdf.addPage();
      })
      .from(elements[0])
      .toContainer()
      .toCanvas()
      .toPdf();
  }

  // If we've reached the end, save the pdf and quit
  if (currentPage === totalPages) {
    doc.save(setList.name);
    setCurrentPageNumber(1);
    setShowAnnotations(true);
    setShowTranspositions(true);
    onComplete();
    return;
  }

  // If we're not done, increment the page number, wait until everything's loaded then recursively call
  setCurrentPageNumber(currentPage + 1);
  setTimeout(() => {
    getPages(
      doc,
      setList,
      currentPage + 1,
      setCurrentPageNumber,
      totalPages,
      setShowAnnotations,
      setShowTranspositions,
      onComplete
    );
  }, waitTimeoutMs);
};

const DownloadPdf = (
  showAnnotations: boolean,
  showTranspositions: boolean,
  setList: SetListViewModel,
  totalPageCount: number,
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>,
  setShowAnnotations: React.Dispatch<React.SetStateAction<boolean>>,
  setShowTranspositions: React.Dispatch<React.SetStateAction<boolean>>,
  onComplete: () => void
): void => {
  // Move to the first page, wait until done then recursively load the page
  setCurrentPageNumber(1);
  setShowAnnotations(showAnnotations);
  setShowTranspositions(showTranspositions);

  setTimeout(() => {
    getPages(
      undefined,
      setList,
      1,
      setCurrentPageNumber,
      totalPageCount,
      setShowAnnotations,
      setShowTranspositions,
      onComplete
    );
  }, waitTimeoutMs);
};

export default DownloadPdf;
