import { Container, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SetListCards from '../components/set-list-card/SetListCards';
import SongCards from '../components/song-list-card/SongCards';
import { MusicNote, QueueMusic } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export enum ViewType {
  SetList,
  Song,
}

interface Props {
  viewMode: ViewType;
}

const Home = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState(props.viewMode);

  useEffect(() => setViewMode(props.viewMode), [props.viewMode]);

  return (
    <>
      <Container style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(_, value) => {
            if (value !== null) {
              setViewMode(value);
              if (value === ViewType.SetList) {
                navigate('/setLists');
              } else {
                navigate('/songs');
              }
            }
          }}
          aria-label='fileMode'
          style={{ margin: 0, padding: 0, textAlign: 'center' }}>
          <ToggleButton value={ViewType.SetList} aria-label='set list'>
            <QueueMusic /> <Typography style={{ textTransform: 'none', marginLeft: 10 }}>View Set Lists</Typography>
          </ToggleButton>
          <ToggleButton value={ViewType.Song} aria-label='song'>
            <MusicNote /> <Typography style={{ textTransform: 'none', marginLeft: 10 }}>View Songs</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>
      {viewMode === ViewType.SetList && <SetListCards />}
      {viewMode === ViewType.Song && <SongCards />}
    </>
  );
};

export default Home;
