import { Edit, EditOff } from '@mui/icons-material';
import { Document } from 'react-pdf';
import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { AnnotationSettings, AnnotationType } from '../../../../types/AnnotationSettings';
import { AnnotationViewModel, SetListViewModel, SongViewModel } from '../../../../types/auto/types';
import { SongIdToPageNumbers } from '../../../../types/SongIdToPageNumbers';
import AnnotationModal from '../../annotation-modal/AnnotationModal';
import PdfDisplayBase from './PdfDisplayBase';
import {
  GetAdjustableMobileEditButtonBottom,
  GetAdjustableMobileEditButtonRight,
} from '../../../utils/MobileViewChanges';
import { colours, zIndex } from '../../../Theme';

interface Props {
  currentPageNumber: number;
  totalPageNumber: number;
  setTotalPageNumber: React.Dispatch<React.SetStateAction<number>>;
  showTwoPages: boolean;
  fullScreen: boolean;
  showAnnotations: boolean;
  showTranspositions: boolean;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  nextPage: () => void;
  prevPage: () => void;
  page1Song: SongViewModel | undefined;
  page2Song: SongViewModel | undefined;
  pdfData: ArrayBuffer | undefined;
  songIdToPageNumbers: SongIdToPageNumbers;
  setList: SetListViewModel | undefined;
  annotations: AnnotationViewModel[];
  setAnnotations: React.Dispatch<React.SetStateAction<AnnotationViewModel[]>>;
  zoomLevel: number;
  documentRef: React.MutableRefObject<Document | null>;
  isSharedSetList: boolean;

  isCapoRemoved: boolean;
  masterTranspose: number;
}

const PdfDisplayAnnotation = (props: Props): JSX.Element => {
  const buttonRef = useRef<Element>();
  const [annotationSettings, setAnnotationSettings] = useState<AnnotationSettings>({
    annotationType: AnnotationType.Text,
    isEditing: false,
    colour: '#000000',
    size: 4,
    clearAll: false,
  } as AnnotationSettings);

  useEffect(() => {
    setAnnotationSettings(x => ({ ...x, isEditing: props.isEditing }));
  }, [props.isEditing, setAnnotationSettings]);

  return (
    <>
      <PdfDisplayBase {...props} annotationSettings={annotationSettings} />
      <AnnotationModal
        open={annotationSettings.isEditing}
        setOpen={open => {
          props.setIsEditing(open);
        }}
        anchor={buttonRef}
        annotationSettings={annotationSettings}
        setAnnotationSettings={setAnnotationSettings}
      />
      {!props.isSharedSetList && (
        <Tooltip
          ref={buttonRef}
          title='Annotate'
          style={{
            position: 'absolute',
            bottom: GetAdjustableMobileEditButtonBottom('annotate'),
            right: GetAdjustableMobileEditButtonRight('annotate'),
            zIndex: zIndex.tooltip,
          }}>
          <IconButton
            aria-label='Annotate'
            onClick={() => props.setIsEditing(!props.isEditing)}
            style={{
              zIndex: zIndex.pdfIconOverlay,
              position: 'absolute',
              bottom: GetAdjustableMobileEditButtonBottom('annotate'),
              right: GetAdjustableMobileEditButtonRight('annotate'),
              backgroundColor: colours.backgroundWhite,
            }}>
            {annotationSettings.isEditing ? <EditOff /> : <Edit />}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default PdfDisplayAnnotation;
