import React, { useContext, useState } from 'react';
import { Box, Container, Grid, Link, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UserClient } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import { useNavigate } from 'react-router-dom';
import Logo from '../../images/Logo.png';
import { SetSnackbarErrorContext, SetSnackbarSuccessContext } from '../../components/elements/snackbar/SnackbarContext';

const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const setSnackbarSuccess = useContext(SetSnackbarSuccessContext);

  const forgotPassword = (): void => {
    setLoading(true);
    if (email.length <= 3) {
      setSnackbarError('Please enter a valid email');
      setLoading(false);
      return;
    }
    new UserClient(Configuration.SERVER_ROOT, FetchOverride)
      .generatePasswordResetEmail(email)
      .then(() => {
        setLoading(false);
        navigate('/');
        setSnackbarSuccess('Please check your emails for a password reset request');
      })
      .catch(() => {
        setLoading(false);
        setSnackbarError('Could not send password reset email. Please try again later');
      });
  };

  return (
    <Container maxWidth='xs' sx={{ padding: 10, textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={Logo} alt='Logo' style={{ width: 50, marginBottom: 10 }} />
        <Typography component='h1' variant='h5'>
          Forgot Password
        </Typography>
        <Box component='form' noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                // Do code here
                forgotPassword();
                ev.preventDefault();
              }
            }}
          />
          <LoadingButton loading={loading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={forgotPassword}>
            Send Reset Password Email
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href='/' variant='body2'>
                Back
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
