import React, { useContext, useEffect, useState } from 'react';
import { Card, CircularProgress, Container, Grid, IconButton, Pagination, Typography } from '@mui/material';
import SongCard from './SongCard';
import { SongClient, SongViewModel } from '../../../types/auto/types';
import { Configuration } from '../../Constants';
import { FetchOverride } from '../../utils/Requests';
import AddIcon from '@mui/icons-material/Add';
import SongUploadModal from '../song-upload-modal/SongUploadModal';
import SearchBar from '../elements/search-bar/SearchBar';
import NoResultsScreen from '../elements/no-results/NoResultsScreen';
import { SetSnackbarErrorContext } from '../elements/snackbar/SnackbarContext';
import { isMobile } from 'react-device-detect';

const searchQueryParam = 'search';

const SongCards = (): JSX.Element => {
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [songToEdit, setSongToEdit] = useState<SongViewModel>();
  const [songs, setSongs] = useState<SongViewModel[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get(searchQueryParam);
  const [searchTerm, setSearchTerm] = useState(searchParam || '');
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);

  useEffect(() => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      (searchTerm === '' || searchTerm === null ? '' : `?${searchQueryParam}=${searchTerm}`);
    window.history.pushState({ path: newurl }, '', newurl);

    const takeAmount = 20;
    new SongClient(Configuration.SERVER_ROOT, FetchOverride)
      .getSongs(searchTerm, true, true, takeAmount, (currentPageNumber - 1) * takeAmount, false)
      .then(x => {
        setSongs(x.values || []);
        setTotalPageNumber(x.totalPageNumber);
        setCurrentPageNumber(x.currentPageNumber);
        setLoading(false);
        setInitialLoading(false);
      })
      .catch(() => {
        setInitialLoading(false);
        setLoading(false);
        setSongs([]);
        setSnackbarError('Could not get songs. Please try again later');
      });
  }, [refresh, setSongs, searchTerm, currentPageNumber]);

  return (
    <>
      <SongUploadModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSongToEdit(undefined);
          setRefresh(x => !x);
        }}
        songToEdit={songToEdit}
      />
      <Card sx={{ padding: 2, margin: 0 }}>
        <Typography variant='h5' sx={{ paddingTop: 2, display: 'inline-block' }}>
          My Songs
        </Typography>
        <IconButton onClick={() => setModalOpen(true)} sx={{ display: 'inline-block', float: 'right', marginTop: 1 }}>
          <AddIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <SearchBar
          placeholder='Search for song name or artist'
          searchTerm={searchTerm}
          onSearchChange={value => {
            setSearchTerm(value);
            setCurrentPageNumber(0);
            setLoading(true);
          }}
          loading={loading}
        />
        {initialLoading ? (
          <Container style={{ width: '100%', height: 300 }}>
            <CircularProgress style={{ position: 'relative', top: '50%', left: '50%' }} />
          </Container>
        ) : songs.length === 0 ? (
          <NoResultsScreen entityName='song' />
        ) : (
          <>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              {songs.map((song, index) => (
                <Grid key={'song' + index.toString()} item xs={6}>
                  <SongCard
                    song={song}
                    onEdit={() => {
                      setSongToEdit(song);
                      setModalOpen(true);
                    }}
                    setRefresh={setRefresh}
                  />
                </Grid>
              ))}
            </Grid>
            <Container
              style={{ marginTop: 20, width: '100%', justifyContent: 'center', display: 'flex', textAlign: 'center' }}>
              <Pagination
                count={totalPageNumber}
                page={currentPageNumber}
                onChange={(_, page) => setCurrentPageNumber(page)}
                size={isMobile ? 'small' : 'medium'}
              />
            </Container>
          </>
        )}
      </Card>
    </>
  );
};

export default SongCards;
