const cookieName = 'sheet-music-pdf-preferences';

export const GetPdfPreferences = (): PdfPreferences => {
  const prefString = getCookie(cookieName);

  if (prefString === undefined) {
    return defaultPreferences;
  }

  return JSON.parse(prefString) as PdfPreferences;
};

export const SetPdfPreferences = (preferencesToSet: PdfPreferences): void => {
  // Set the cookie as far in advance as possible (currently 400 days)
  setCookie(cookieName, JSON.stringify(preferencesToSet), 400);
};

export interface PdfPreferences {
  fontSize: number;
  hideCapo?: boolean;
  setListToMasterTranspose?: Record<string, number>;
  disableSetListProgressSharing?: boolean;
}

const defaultPreferences: PdfPreferences = {
  fontSize: 12,
};

/*
 * Cookie Management
 */

function setCookie(cname: string, cvalue: string, exdays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname: string): string | undefined {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}
